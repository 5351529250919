import { Howl } from "howler";

const sounds = {
    sound: new Howl({ src: [require("../assets/sound.mp3")], html5: true }),
    alertBetacut: new Howl({ src: [require("../assets/alert-betacut.mp3")], html5: true }),
    gtaSound: new Howl({ src: [require("../assets/gta-sound.mp3")], html5: true }),
    bell: new Howl({ src: [require("../assets/bell.mp3")], html5: true }),
    livechat: new Howl({ src: [require("../assets/livechat.mp3")], html5: true }),
    phoneRing: new Howl({ src: [require("../assets/phone-ring.mp3")], html5: true })
};

let currentSound = null;
let lastCallTime = 0;

const NotificationSound = (notification) => {
    const localStorageSound = localStorage.getItem("volume") ? Number(localStorage.getItem("volume")) : 50;
    const adjustedVolume = Math.max(0, Math.min(100, localStorageSound)) / 100;
    sounds[notification].volume(adjustedVolume);

    return () => {

        const currentTime = Date.now();
        
        // Verifica se já se passaram pelo menos 500ms desde a última chamada
        if (currentTime - lastCallTime >= 500) {
            // Pausa o áudio atual, se existir
            if (currentSound && typeof currentSound.stop === "function") {
                currentSound.stop();
            }

            // Reproduz o novo áudio
            currentSound = sounds[notification].play();

            // Atualiza o tempo da última chamada
            lastCallTime = currentTime;
        } else {
            // console.info("Notification ignored: too soon.");
        }
    };
};

export { NotificationSound };
