import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import api from '../../services/api';
import isColorDark from '../../utils/isColorDark';
import { i18n } from '../../translate/i18n';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutoCompleteTags({ type, name, selected, onSelect }) {
    const [open, setOpen] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const fetchTags = async () => {
        try {
            setLoading(true);
            const { data } = await api.get('/tags', { params: { type } });
            setTags(data);
        } catch (error) {
            console.error('Erro ao buscar tags:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = () => {
        setOpen(true);
        fetchTags();
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (selected?.length > 0) {
            fetchTags();
        }
    }, [selected]);

    const handleChange = (event, value) => {
        // Extraímos os IDs das tags selecionadas
        const selectedIds = value.map((tag) => tag.id);

        // Passamos os IDs para a função onSelect
        if (onSelect) {
            onSelect(selectedIds);
        }
    };

    return (
        <Autocomplete
            size="small"
            name={name}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={tags}
            loading={loading}
            multiple
            limitTags={3}
            disableCloseOnSelect
            onChange={handleChange}
            value={tags?.filter(tag => selected?.includes(tag.id)) || []}
            noOptionsText={loading ? <CircularProgress size={24} /> : i18n.t('contactModal.form.tagsNotFound')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={i18n.t('contactModal.form.tags')}
                    placeholder={i18n.t('contactModal.form.selectTags')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ height: '50px' }}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        sx={{
                            borderRadius: '7px',
                            backgroundColor: option.color,
                            color: isColorDark(option.color) ? '#FFFFFF' : '#000000',
                            '& .MuiChip-deleteIcon': {
                                color: isColorDark(option.color) ? '#FFFFFF' : '#000000',
                            }
                        }}
                        key={index}
                        variant="outlined"
                        label={option.name}
                        size="small"
                        {...getTagProps({ index })}
                    />
                ))
            }
        />
    );
}
