import React from "react";

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Card, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles()((theme) => {
  return {
    ticketHeader: {
      display: "flex",
      justifyContent: 'space-between',
      borderRadius: '0px !important',
      maxHeight: '49px',
      flex: "none",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  }
});

const TicketHeader = ({ loading, children }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const handleBack = () => {
    history.push("/tickets");
  };

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card sx={{
          borderRadius: '0px !important'
        }}
          square
          className={classes.ticketHeader}
        >
          <IconButton sx={{ borderRadius: '5px 2px 2px 5px' }} onClick={handleBack} aria-label="delete">
            <ChevronLeftIcon />
          </IconButton>
          {children}
        </Card>
      )}
    </>
  );
};

export default TicketHeader;
