import SearchIcon from '@mui/icons-material/Search';
import { Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from "react";
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import api from "../../../services/api";
import ToastError from "../../../toast/error/toastError";
import { i18n } from "../../../translate/i18n";
import CompaniesList from '../../Company/Companies';
import Companies from '../components/Companies';
import Users from '../components/users';
import companiesSvg from './companies.svg';
import connectionsSvg from './connection.svg';
import socket from '../../../hooks/useSocket';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(8),
  },
  gridItem: {
    marginBottom: theme.spacing(3),
  },
}));

const Company = () => {
  const classes = useStyles();
  const { themeStretch } = useSettings();
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState({});

  useEffect(() => {
    const fetchCompanyOverview = async () => {
      try {
        setLoading(true);
        const { data } = await api.get("/companies-overview/");
        setOverview(data);
      } catch (err) {
        ToastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchCompanyOverview();
  }, []);

  return (
    <Page title={i18n.t("companies.pages.overview.name")}>
      <Container maxWidth="xl" className={classes.mainContainer}>
        <Grid container spacing={3}>
          {!loading && (
            <>
              <Grid item xs={12} md={3} className={classes.gridItem}>
                <Companies
                  title={i18n.t("companies.pages.overview.companies")}
                  total={overview.getCompanies || 0}
                  icon={<img src={companiesSvg} alt="Companies Icon" />}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.gridItem}>
                <Users
                  title={i18n.t("companies.pages.overview.users")}
                  total={overview.getUsers || 0}
                  icon={<SearchIcon />}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.gridItem}>
                <Users
                  title={i18n.t("companies.pages.overview.connections")}
                  total={overview.connections || 0}
                  icon={<img src={connectionsSvg} alt="Connections Icon" />}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <CompaniesList />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

export default Company;
