import { Box, Tooltip, Stack } from '@mui/material';
import { fileData, fileFormat, fileThumb } from './utils';
import DownloadButton from './DownloadButton';
import ModalImage from "react-modal-image";
import { makeStyles } from 'tss-react/mui';

// ----------------------------------------------------------------------



const useStyles = makeStyles()((theme) => {
    return {
        messageMedia: {
            objectFit: "cover",
            width: 60,
            height: 60,
            borderRadius: 2,
        },
    }
});

export default function FileThumbnail({
    file,
    tooltip,
    imageView,
    onDownload,
    sx,
    imgSx,
}) {
    const { name = '', path = '', preview = '' } = fileData(file);
    const { classes } = useStyles();
    const format = fileFormat(path || preview);
    const renderContent =
        format === 'image' && imageView ? (

            <ModalImage
                className={classes.messageMedia}
                smallSrcSet={preview}
                medium={preview}
                large={preview}
                alt="image"
            />

            //   <Box
            //       component="img"
            //       src={preview}
            //       sx={{
            //           width: '400px',
            //           height: '400px',
            //           flexShrink: 0,
            //           objectFit: 'cover',
            //           ...imgSx,
            //       }}
            //   />
        ) : (
            fileThumb(format)
        );

    if (tooltip) {
        return (
            <Tooltip title={name}>
                <Stack
                    flexShrink={0}
                    component="span"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: 'fit-content',
                        height: 'inherit',
                    }}
                >
                    {renderContent}
                    {onDownload && <DownloadButton onDownload={onDownload} />}
                </Stack>
            </Tooltip>
        );
    }

    return (
        <>
            {renderContent}
            {onDownload && <DownloadButton onDownload={onDownload} />}
        </>
    );
}
