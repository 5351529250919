import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import * as Yup from "yup";
import avatarPng from "../../assets/Profile-Avatar.png";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import { i18n } from "../../translate/i18n";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};


function getStyles(name, periodWork, theme) {
	return {
		fontWeight:
			periodWork.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}


const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: "8px 0px 0px 0px",
			minWidth: 155,
		},
		formControlAdminSuper: {
			width: "100%"
		}
	}
});

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	email: Yup.string().email("Email Invalido").required("Obrigatório"),
});

const UserSchemaPassword = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	oldPassword: Yup.string().min(5, "Muito Curto(a)!").max(50, "Muito Longo(a)!")
		.required('Required'),
	password: Yup.string().min(5, "Muito Curto(a)!").max(50, "Muito Longo(a)!")
		.required('Required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], "As senhas não são iguais!")
		.required('Required'),
	email: Yup.string().email("Email Invalido").required("Obrigatório"),
});

const EditCompanyUsersModal = ({ open, onClose, userId }) => {


	const { classes } = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);

	const initialState = {
		name: "",
		email: "",
		password: "",
		media: "",
	};

	const [user, setUser] = useState(initialState);
	const [userImage, setUserImage] = useState([]);

	useEffect(() => {
		const fetchUser = async () => {
			setUserImage("");
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => { return { ...prevState, ...data } });
				setUserImage(data.media);
			} catch (err) {
				ToastError(err);
			}
		};
		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {

		const userData = new FormData();
		userData.append("name", values.name);
		userData.append("email", values.email);
		userData.append("password", values.password);
		userData.append("id", values.id);
		if (userImage?.length > 0 && userImage[0] != 'h') { userData.append("media", userImage[0]) }

		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}

			ToastSuccess(i18n.t("userModal.success"));

			handleClose();
		} catch (err) {
			ToastError(err);
		}
	};

	function checkImg() {
		let imagem = ""
		if (userImage) {
			if (userImage?.length && userImage[0]?.name) {
				imagem = URL.createObjectURL(userImage[0]);
			} else {
				imagem = userImage
			}
		} else { imagem = avatarPng; }
		return imagem;
	}

	const encodeImageFileAsURL = (e) => {
		if (!e.target.files) return;
		let selectedMedias = Array.from(e.target.files);
		setUserImage(selectedMedias);
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<DialogTitle id="form-dialog-title">
						{userId
							? `${i18n.t("userModal.title.edit")}`
							: `${i18n.t("userModal.title.add")}`}
					</DialogTitle>
				</div>
				<Formik
					initialValues={user}
					enableReinitialize="true"
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						const array = {
							"name": values.name,
							"email": values.email,
							"password": values.password
						}
						setTimeout(() => {
							handleSaveUser(array);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div>
									<div style={{ marginBottom: '25px' }}>
										<div>
											<div className={classes.multFieldLine}>
												<img style={{
													borderRadius: '100px',
													display: 'block',
													marginLeft: 'auto',
													marginRight: 'auto',
													width: '40%'
												}} alt="user" src={checkImg()} />
											</div>
										</div>
										<div style={{
											marginTop: '10px',
											alignItems: 'center',
											display: 'flex',
											justifyContent: 'center'
										}}>
											<Button variant="contained" component="label">
												{i18n.t("userType.translate.sendPhoto")}
												<input hidden onChange={encodeImageFileAsURL} id="userImage" accept="image/*" type="file" />
											</Button>
										</div>
									</div>
									<div>
										<div className={classes.multFieldLine}>
											<Field
												style={{ display: 'none' }}
												as={TextField}
												type="hidden"
												name="media"
											/>
											<Field
												as={TextField}
												label={i18n.t("userModal.form.name")}
												autoFocus
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
											<Field
												as={TextField}
												label={i18n.t("userModal.form.email")}
												name="email"
												error={touched.email && Boolean(errors.email)}
												helperText={touched.email && errors.email}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
										</div>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.password")}
											type="password"
											name="password"
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</div>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div >
	);
};

export default EditCompanyUsersModal;
