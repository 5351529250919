import { Dialog } from "@mui/material";

const DialogBlur = ({ children, ...props }) => {
    return (
        <Dialog {...props} style={{ backdropFilter: "blur(3px)" }}>
            {children}
        </Dialog>
    );
};

export default DialogBlur;