import { Add, Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Checkbox, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import DeleteItemButton from '../DeleteItemButton';

const KanbanCardTasksSection = ({ card, assigneds }) => {

    const [tasks, setTasks] = useState([]);
    const [previousTasks, setPreviousTasks] = useState([]);
    const [loading, setLoading] = useState({});

    const handleAddChecklist = async () => {
        const { data } = await api.post(`/kanban/card/task/${card.id}`, { title: `Checklist ${tasks.length + 1}` });
        setTasks([...tasks, { ...data }]);
    };

    const handleAddItem = async (taskId) => {
        setTasks(tasks.map(task =>
            task.id === taskId
                ? { ...task, checklists: [...task.checklists, { id: Date.now(), description: '', isCompleted: false, saved: false, database: false }] }
                : task
        ));
    };

    useEffect(() => {
        setTasks(card.tasks);
        setPreviousTasks(card.tasks);
    }, [card.tasks]);


    const handleItemChange = async (taskId, checklistId, field, value) => {
        try {
            const task = tasks.find(task => task.id === taskId);
            if (!task) return;

            const previousTask = previousTasks.find(task => task.id === taskId);
            if (!previousTask) return;

            // Verifica se é o campo de título da tarefa e se houve mudança
            if (field === 'title') {
                const currentTitle = task.title || '';
                const previousTitle = previousTask.title || '';
                if (currentTitle === value || previousTitle === value) return;
            }

            // Verifica o item do checklist
            const checklistItem = task.checklists.find(item => item.id === checklistId);
            const previousChecklistItem = previousTask.checklists.find(item => item.id === checklistId);
            if (!checklistItem || !previousChecklistItem) return;

            // Verifica mudanças na descrição
            if (field === 'description') {
                const currentDescription = checklistItem.description || '';
                const previousDescription = previousChecklistItem.description || '';
                if (currentDescription === value || previousDescription === value) return;
            }

            // Verifica mudanças no estado de conclusão
            if (field === 'isCompleted') {
                const currentStatus = checklistItem.isCompleted;
                const previousStatus = previousChecklistItem.isCompleted;
                if (currentStatus === value || previousStatus === value) return;
            }

            // Define como carregando
            setLoading(prev => ({ ...prev, [`${taskId}-${checklistId}`]: true }));

            // Atualiza as tasks com o novo valor
            const updatedTasks = tasks.map((task) => {
                if (task.id === taskId) {
                    if (field === 'title') {
                        return { ...task, title: value };
                    }
                    const updatedChecklists = task.checklists.map((item) => {
                        if (item.id === checklistId) {
                            return { ...item, [field]: value, saved: field === 'description' ? false : item.saved };
                        }
                        return item;
                    });
                    return { ...task, checklists: updatedChecklists };
                }
                return task;
            });

            // Atualiza o estado
            setTasks(updatedTasks);
            setPreviousTasks(updatedTasks)
        } catch (err) {
            console.error('Erro ao atualizar o item:', err);
        } finally {
            setLoading(prev => ({ ...prev, [`${taskId}-${checklistId}`]: false }));
        }
    };


    const handleDeleteItem = (taskId, checklistId) => {
        setTasks(tasks.map(task =>
            task.id === taskId
                ? { ...task, checklists: task.checklists.filter(item => item.id !== checklistId) }
                : task
        ));
    };

    const handleDeleteChecklist = (taskId) => {
        setTasks(tasks.filter(task => task.id !== taskId));
    };

    const handleSave = async (taskId, checklistId, isTitle) => {
        setLoading(prev => ({ ...prev, [`${taskId}-${checklistId}`]: true }));
        try {
            const updatedTasks = await Promise.all(
                tasks.map(async (task) => {
                    if (task.id === taskId) {
                        if (isTitle) {
                            await api.put(`/kanban/card/task/${task.id}`, { title: task.title });
                            return { ...task, originalTitle: task.title, titleChanged: false };
                        }
                        const updatedChecklists = await Promise.all(
                            task.checklists.map(async (item) => {
                                if (item.id === checklistId) {
                                    if (!item?.database) {
                                        const { data } = await api.post(`/kanban/card/task/checklist/${task.id}`, { description: item.description });
                                        return { ...item, id: data.id, saved: true, database: false };
                                    } else {
                                        await api.post(`/kanban/card/task/checklist/${item.id}`, { description: item.description });
                                        return { ...item, saved: true };
                                    }
                                }
                                return item;
                            })
                        );
                        return { ...task, checklists: updatedChecklists };
                    }
                    return task;
                })
            );
            setTasks(updatedTasks);
        } catch (err) {
            console.error('Erro ao salvar:', err);
        } finally {
            setLoading(prev => ({ ...prev, [`${taskId}-${checklistId}`]: false }));
        }
    };

    return (
        <Box
            sx={{
                height: 'calc(100vh - 135px)', // Ajuste a altura máxima com base no cálculo
                maxHeight: '100%', // Garante que não ultrapasse o tamanho máximo do componente pai
                overflowY: 'auto', // Adiciona o scroll vertical se necessário
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: '100%'
            }}
        >
            <Box
                sx={{
                    flex: 1, // Faz com que o conteúdo expanda para ocupar a altura total
                    overflowY: 'auto',
                }}
            >
                {tasks.map((task, idx) => (
                    <Box key={task.id} sx={{
                        p: 3,
                        bgcolor: (theme) => theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9',
                        borderRadius: '5px',
                        border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? '#2f3641' : '#f9f9f9'}`,
                        mb: 2,
                        '&:hover': { boxShadow: 3 },
                    }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <StyledTitle
                                size="small"
                                value={task.title}
                                onChange={(e) => handleItemChange(task.id, task.id, 'title', e.target.value)}
                                onBlur={() => handleSave(task.id, task.id, true)} // Salva ao clicar fora
                                placeholder={`Checklist ${idx + 1}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {/*task.titleChanged && !loading[`${task.id}-${task.id}`] && (
                                            <IconButton onClick={() => handleSave(task.id, task.id, true)}>
                                                <SaveIcon />
                                            </IconButton>
                                        )*/}
                                            {loading[`${task.id}-${task.id}`] && (
                                                <CircularProgress size={24} color="primary" />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <IconButton size="small" onClick={() => handleDeleteChecklist(task.id)}>
                                <Delete fontSize="small" />
                            </IconButton>
                        </Box>

                        {task.checklists.map((item) => (
                            <Box key={item.id} display="flex" alignItems="center" mb={1} sx={{ padding: '4px 0' }}>
                                <Checkbox
                                    checked={item.isCompleted}
                                    onChange={(e) => handleItemChange(task.id, item.id, 'isCompleted', e.target.checked)}
                                    sx={{
                                        color: item.isCompleted ? 'primary.main' : 'grey.500',
                                        '&.Mui-checked': { color: 'primary.main' },
                                    }}
                                />
                                <StyledInput
                                    size="small"
                                    value={item.description}
                                    placeholder="Novo item"
                                    multiline
                                    isCompleted={!!item?.isCompleted}
                                    maxRows={6}
                                    onChange={(e) => handleItemChange(task.id, item.id, 'description', e.target.value)}
                                    onBlur={() => handleSave(task.id, item.id, false)} // Salva ao clicar fora
                                    InputProps={{
                                        style: {
                                            padding: "2px 10px"
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {/*item.description && !item.saved && !loading[`${task.id}-${item.id}`] && (
                                                <IconButton onClick={() => handleSave(task.id, item.id, false)}>
                                                    <SaveIcon />
                                                </IconButton>
                                            )*/}
                                                {loading[`${task.id}-${item.id}`] && (
                                                    <CircularProgress size={24} color="primary" />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {/*
                            <IconButton size="small" onClick={() => handleDeleteItem(task.id, item.id)}>
                                <PersonIcon fontSize="small" />
                            </IconButton>
                            */}
                                <DeleteItemButton taskId={task.id} itemId={item.id} handleDeleteItem={console.info} />
                            </Box>
                        ))}

                        <Button size="small" variant="outlined" onClick={() => handleAddItem(task.id)} sx={{ mt: 1 }}>
                            Adicionar item
                        </Button>
                    </Box>
                ))}

                <Button size="small" fullWidth onClick={handleAddChecklist} sx={{ bgcolor: 'primary.main', color: 'white', '&:hover': { bgcolor: 'primary.dark' } }} startIcon={<Add />}>
                    Adicionar Checklist
                </Button>
            </Box>
        </Box>
    );
};

const StyledTitle = styled(TextField)(({ theme }) => ({
    flex: 1,
    fontSize: '1rem',
    fontWeight: 'bold',
    '& .MuiInputBase-root': {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
        backgroundColor: theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        '& fieldset': { borderColor: 'transparent' },
        '&:hover fieldset': { borderColor: theme.palette.primary.main },
        '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
    },
}));

const StyledInput = styled(TextField)(({ theme, isCompleted }) => ({
    flex: 1,
    '& .MuiInputBase-root': {
        fontSize: '0.9rem',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
        backgroundColor: theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9',
        // Aplica a cor condicional com base no isCompleted
        color: isCompleted ? '#5f6063' : theme.palette.text.primary,
        textDecoration: isCompleted ? 'line-through' : 'none', // Risca o texto se isCompleted for true
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        '& fieldset': { borderColor: 'transparent' },
        '&:hover fieldset': { borderColor: theme.palette.primary.main },
        '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
    },
}));


export default KanbanCardTasksSection;
