import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import MarkdownWrapper from '../MarkdownWrapper';

const CustomListItemText = ({ title, description }) => (
    <Box mb={2}> {/* Define o Box externo para espaçamento */}
        <Box display="flex" flexDirection="column">
            {/* Linha do título */}
            <Typography variant="body2" fontWeight="bold" whiteSpace="pre-wrap">
                {title}
            </Typography>
            {description && (
                <Divider sx={{ margin: '10px 0px' }}></Divider>
            )}
            {description && (
                <MarkdownWrapper>
                    {description}
                </MarkdownWrapper>
            )}
        </Box>
    </Box>
);

export default CustomListItemText;
