import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { Avatar, CircularProgress, Divider } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const UserAutoComplete = ({ labels, placeholder, search, value, setValue, loading }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (anchorEl) { anchorEl.focus(); }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? 'github-label' : undefined;

    const handleSelectionChange = (newValue) => {
        const selectedLabel = labels.find(label => label.id === newValue);
        if (selectedLabel) {
            const newObj = [...value, selectedLabel]
            setValue(newObj);
        }
    };

    const handleRemoveSelect = (id) => {
        const newObj = value.filter(label => label.id !== id)
        setValue(newObj);
    };

    return (
        <React.Fragment>
            <Box sx={{ width: '100%', fontSize: 13 }}>
                <Box sx={{
                    padding: '5px',
                    borderRadius: '10px',
                    ...(loading ? {
                        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2a2f3a' : '#f1f3f5',
                        borderColor: (theme) => theme.palette.mode === 'dark' ? '#5c6370' : '#c2c7cc',
                    } : {
                        cursor: 'pointer',
                        transition: 'background-color 0.3s, border-color 0.3s',
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2a2f3a' : '#f1f3f5',
                            borderColor: (theme) => theme.palette.mode === 'dark' ? '#5c6370' : '#c2c7cc',
                        },
                    }),
                    border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? '#414955' : '#d8dce0'}`,
                }}>
                    <Box
                        display="flex"
                        disabled={loading}
                        onClick={(e) => { !loading && handleClick(e) }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box sx={{ alignItems: 'center', display: 'flex' }}>
                            <Label>{placeholder}</Label>
                            <Divider sx={{ margin: '0px 10px 0px 10px' }} orientation="vertical" flexItem />
                        </Box>
                        {loading ? <CircularProgress size={20} /> : <AddIcon />}
                    </Box>
                    {(value?.length > 0) && (
                        <Box sx={{ padding: '0px 10px' }}>
                            <Divider flexItem sx={{ margin: '5px 0' }} />
                            {value?.map((label, index) => (
                                <StyledChip
                                    sx={{ marginRight: '5px', borderRadius: '5px' }}
                                    size="small"
                                    key={index}
                                    avatar={<Avatar alt={label.name} src={label.media} />}
                                    label={label.name}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Box
                            sx={(t) => ({
                                borderBottom: `1px solid ${'#30363d'}`,
                                padding: '8px 10px',
                                fontWeight: 600,
                                ...t.applyStyles('light', {
                                    borderBottom: `1px solid ${'#eaecef'}`,
                                }),
                            })}
                        >
                            <Typography variant="body2" gutterBottom>{search}</Typography>
                        </Box>
                        <Autocomplete
                            open
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={value}
                            onChange={(event, newValue) => {
                                handleSelectionChange(newValue);
                            }}
                            disableCloseOnSelect
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderOption={(props, option) => {
                                const selected = value.some(v => v.id === option.id);

                                return (
                                    <Box
                                        component="li"
                                        {...props}
                                        sx={{
                                            margin: '5px 5px !important',
                                            display: 'flex !important',
                                            alignItems: 'center !important',
                                            padding: '3px !important',
                                            borderRadius: '3px !important',
                                            ...(selected && {
                                                backgroundColor: 'rgb(0 0 0 / 10%) !important',
                                            })
                                        }}
                                        onClick={(e) => {
                                            if (selected) { handleRemoveSelect(option.id); e.stopPropagation(); }
                                            if (!selected) { handleSelectionChange(option.id); e.stopPropagation(); }
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                flexShrink: 0,
                                                mr: 1,
                                                mt: '2px'
                                            }}
                                            src={option.media}
                                            alt={option.name}
                                        />
                                        <Box sx={{ flexGrow: 1 }}>
                                            {option.name}
                                            <br />
                                            <span>{option.profile}</span>
                                        </Box>
                                        {selected ? <CloseIcon /> : null}
                                    </Box>
                                );
                            }}
                            options={labels}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Buscar..."
                                />
                            )}
                            slots={{
                                popper: PopperComponent,
                            }}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    );
}

export default UserAutoComplete;

const Label = styled(Typography)(({ theme }) => ({
    marginLeft: '10px',
    fontSize: '0.75rem',
    fontWeight: 600,
    color: theme.palette.text.secondary
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
};

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: '#fff',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${' #eaecef'}`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
                backgroundColor: theme.palette.action.hover,
            },
            ...theme.applyStyles('dark', {
                borderBottom: `1px solid  ${'#30363d'}`,
            }),
        },
        ...theme.applyStyles('dark', {
            backgroundColor: '#1c2128',
        }),
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${'#e1e4e8'}`,
    boxShadow: `0 8px 24px ${'rgba(149, 157, 165, 0.2)'}`,
    color: '#24292e',
    backgroundColor: '#fff',
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    ...theme.applyStyles('dark', {
        border: `1px solid ${'#30363d'}`,
        boxShadow: `0 8px 24px ${'rgb(1, 4, 9)'}`,
        color: '#c9d1d9',
        backgroundColor: '#1c2128',
    }),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: '10px',
    width: '100%',
    borderBottom: `1px solid ${'#30363d'}`,
    '& input': {
        borderRadius: 4,
        backgroundColor: '#fff',
        border: `1px solid ${'#30363d'}`,
        padding: '5px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${'rgba(3, 102, 214, 0.3)'}`,
            borderColor: '#0366d6',
            ...theme.applyStyles('dark', {
                boxShadow: `0px 0px 0px 3px ${'rgb(12, 45, 107)'}`,
                borderColor: '#388bfd',
            }),
        },
        ...theme.applyStyles('dark', {
            backgroundColor: '#0d1117',
            border: `1px solid ${'#eaecef'}`,
        }),
    },
    ...theme.applyStyles('dark', {
        borderBottom: `1px solid ${'#eaecef'}`,
    }),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    marginBottom: '5px',
    '& .MuiChip-deleteIcon': {
        color: theme.palette.grey[500],
    },
}));
