import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const labels = {
    0: 'Horrivel',
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Mediano',
    4: 'Bom',
    5: 'Excelente',
};

export default function RatingStars({ value, props, isText = false }) {

    return (
        <Box>
            <Rating
                {...props}
                value={value}
                readOnly
                size="small"
                precision={0.5}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {isText && <Box sx={{ ml: 2 }}>{labels[value]}</Box>}
        </Box>
    );
}
