import React, { useEffect, useReducer, useState } from "react";

import useSettings from '../../../hooks/useSettings';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    Card,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import CompanyModal from "../../../components/CompanyModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import TableRowSkeleton from "../../../components/TableRowSkeleton";
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";

import { makeStyles } from 'tss-react/mui';
import socket from "../../../hooks/useSocket";
import ToastError from "../../../toast/error/toastError";
import ToastSuccess from "../../../toast/success/toastSuccess";


const reducer = (state, action) => {

  if (action.type === "LOAD_COMPANIES") {
    const companies = action.payload;
    const newCompanys = [];

    companies.forEach((company) => {
      const companyIndex = state.findIndex((u) => u.id === company.id);
      if (companyIndex !== -1) {
        state[companyIndex] = company;
      } else {
        newCompanys.push(company);
      }
    });

    return [...state, ...newCompanys];
  }

  if (action.type === "UPDATE_COMPANIES") {
    const companies = action.payload;
    const companyIndex = state.findIndex((u) => u.id === companies.id);

    if (companyIndex !== -1) {
      state[companyIndex] = companies;
      return [...state];
    } else {
      return [companies, ...state];
    }
  }

  if (action.type === "DELETE_COMPANIES") {
    const companyId = action.payload;

    const companyIndex = state.findIndex((u) => u.id === companyId);
    if (companyIndex !== -1) {
      state.splice(companyIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const Company = () => {

  const { classes } = useStyles();

  const [company, setCompany] = useState({ nomeEmpresa: "", cnpj: "", maxWhatsapps: "", MaxUsers: "" });
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [deletingCompany, setDeletingCompany] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [companies, dispatch] = useReducer(reducer, []);
  const { themeStretch } = useSettings();
  const [searchParam, setSearchParam] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({})
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  /*const handleChangeInput = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };*/
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCompany = async () => {
        try {
          const { data } = await api.get("/company/", {
            params: { searchParam, pageNumber },
          });

          dispatch({ type: "LOAD_COMPANIES", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          ToastError(err);
        }
      };
      fetchCompany();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {

    

    
socket.on("company", (data) => {

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_COMPANIES", payload: data.company });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_COMPANIES", payload: +data.companyId });
      }
    });

    return () => {
      socket.off("company");
    };
  }, []);




  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };  // area de paginação 


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;

  const filteredUsers = applySortFilter(companies, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);



  ///////////////////


  //fazer pesquisa 
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };
  //Abrir modal para cadastrar empresa
  const handleOpenCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyModalOpen(true);
  };

  //fechar modal empresa 
  const handleCloseCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyModalOpen(false);
  };
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  const handleEditCompany = (id) => {
    setSelectedCompany(id)
    setCompanyModalOpen(true);
  };

  const handleDeleteCompany = async (companyId) => {

    try {
      if (!companyId) return;
      await api.delete(`/company/${companyId}`);
      ToastSuccess("Empresa Excluida com sucesso");
    } catch (err) {
      ToastError(err);
    }
    setDeletingCompany(null);
    setSearchParam("");
    setPageNumber(1);
  };
  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            deletingCompany &&
            `${i18n.t("companies.confirmations.delete")} ${deletingCompany.name}?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteCompany(deletingCompany.id)}
        >
          {i18n.t("companies.deleteInfo")}
        </ConfirmationModal>
        <CompanyModal
          open={companyModalOpen}
          onClose={handleCloseCompanyModal}
          aria-labelledby="form-dialog-title"
          companyId={selectedCompany}
        />

        <Page title={i18n.t("companies.pages.report")}>
        <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading={i18n.t("companies.pages.report")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenCompanyModal}
                  >
                    {i18n.t("companies.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }} style={{ marginTop: '40px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("companies.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("companies.table.cnpj")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("companies.table.connections")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("companies.table.users")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("companies.table.action")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, name, cnpj, maxWhatsapps, maxUsers } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="center">{name}</TableCell>
                            <TableCell align="center">{cnpj}</TableCell>
                            <TableCell align="center">{maxWhatsapps}</TableCell>
                            <TableCell align="center">{maxUsers}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditCompany(id)}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setConfirmModalOpen(true);
                                  setDeletingCompany(row);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {loading && <TableRowSkeleton columns={5} />}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>

  )
}

export default Company;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

