
import { Box, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from "clsx";

const useStyles = makeStyles()((theme) => {
    return {
        mainWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        },

        newMessageBox: {
            width: "100%",
            display: "flex",
            padding: "7px",
            alignItems: "center",
        },

        messageInputWrapper: {
            padding: 6,
            marginRight: 7,

            display: "flex",
            borderRadius: 20,
            flex: 1,
            position: "relative",
        },

        messageInput: {
            paddingLeft: 10,
            flex: 1,
            border: "none",
        },

        sendMessageIcons: {
            color: "grey",
        },

        uploadInput: {
            display: "none",
        },

        viewMediaInputWrapper: {
            display: "flex",
            padding: "10px 13px",
            position: "relative",
            justifyContent: "space-between",
            alignItems: "center",

            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        },

        emojiBox: {
            position: "absolute",
            bottom: 63,
            width: 40,
            borderTop: "1px solid #e8e8e8",
        },

        circleLoading: {
            opacity: "70%",
            position: "absolute",
            top: "20%",
            left: "50%",
            marginLeft: -12,
        },

        audioLoading: {
            opacity: "70%",
        },

        recorderWrapper: {
            display: "flex",
            alignItems: "center",
            alignContent: "middle",
        },

        speedDial: {
            position: 'absolute',
            bottom: 2,
            left: '70px',
            '@media(max-width: 650px)': {
                left: '0px',
            },
            '& .MuiFab-primary':
            {
                boxShadow: 'none'
            }
        },

        cancelAudioIcon: {
            color: "red",
        },

        sendAudioIcon: {
            color: "green",
        },

        replyginMsgWrapper: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 8,
            paddingLeft: 73,
            paddingRight: 7,
        },

        replyginMsgContainer: {
            flex: 1,
            marginRight: 5,
            overflowY: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "7.5px",
            display: "flex",
            position: "relative",
        },

        replyginMsgBody: {
            padding: 10,
            height: "auto",
            display: "block",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
        },

        replyginContactMsgSideColor: {
            flex: "none",
            width: "4px",
            backgroundColor: "#35cd96",
        },

        replyginSelfMsgSideColor: {
            flex: "none",
            width: "4px",
            backgroundColor: "#6bcbef",
        },

        messageContactName: {
            display: "flex",
            fontWeight: 500,
        },

        messageQuickAnswersWrapper: {
            margin: 0,
            position: "absolute",
            bottom: "50px",
            padding: "2px",
            border: "1px solid #CCC",
            left: 0,
            width: "100%",
            "& li": {
                listStyle: "none",
                "& a": {
                    display: "block",
                    padding: "8px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxHeight: "32px",
                    "&:hover": {
                        background: "#F1F1F1",
                        cursor: "pointer",
                    },
                },
            },
        },
    }
});


const RenderReplyingMessage = ({ message, setReplyingMessage, loading, ticketStatus }) => {

    const { classes } = useStyles();

    return (
        <Box className={classes.replyginMsgWrapper}>
            <Box className={classes.replyginMsgContainer}>
                <span
                    className={clsx(classes.replyginContactMsgSideColor, {
                        [classes.replyginSelfMsgSideColor]: !message.fromMe,
                    })}
                />
                <Box className={classes.replyginMsgBody}>
                    {!message.fromMe && (
                        <span className={classes.messageContactName}>
                            {message.contact?.name}
                        </span>
                    )}
                    {message.body}
                </Box>
            </Box>
            <IconButton
                aria-label="showRecorder"
                component="span"
                disabled={loading || (ticketStatus !== "open" && ticketStatus !== "archived")}
                onClick={() => setReplyingMessage(null)}
            >
                <ClearIcon className={classes.sendMessageIcons} />
            </IconButton>
        </Box>
    );
};

export default RenderReplyingMessage;