import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Box,
  Card,
  Input,
  Stack,
  Link,
  Avatar,
  Alert,
  Container,
  Typography,
  InputAdornment,
  IconButton
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import useResponsive from '../../hooks/useResponsive';
import { i18n } from "../../translate/i18n";
import Page from '../../components/Page';
import iconPage from '../../assets/icons/Messages-amico.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Image from '../../components/Image';
import OutlinedInput from '@mui/material/OutlinedInput';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor: "transparent",
      width: "150px", // Fix IE 11 issue.
      height: "150px", // Fix IE 11 issue.
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logo: {
      width: "100%", // Fix IE 11 issue.
    },
  }
});

const Recovery = () => {

  const { classes } = useStyles();
  const [user, setUser] = useState({ email: "", code: "" });
  const [loading, setLoading] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleLogin = async (values) => {

    if (!values.email) return;

    if (isCode && !isError) {
      try {
        setLoading(true)
        await api.post("recovery-user/", { email: values.email, code: values.code });
        setLoading(false)
        setIsError(false)
        window.location = `/recovery-user/${values.email}/${values.code}`;
      } catch (err) {
        setIsError(true)
        ToastError(err);
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        await api.post(`/recovery/`, values);
        setLoading(false)
        setUser(pre => ({ ...pre, code: "" }))
        setIsCode(true)
        setIsError(false)
      } catch (err) {
        console.info(err)
        ToastError(err);
        setLoading(false)
      }
    }
  };


  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <Page title={i18n.t("recovery.title")}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {i18n.t("recovery.formTitle")}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}> {i18n.t("recovery.details")}</Typography>
              </Box>
            </Stack>
            {isCode && !isError && <>
              <p style={{ padding: '10px 0px' }}>{i18n.t("recovery.emailSend")}</p>
            </>}
            <form className={classes.form} autoComplete="off" noValidate onSubmit={handlSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                disabled={isCode && !isError}
                fullWidth
                id="email"
                label={i18n.t("recovery.form.email")}
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                autoComplete="email"
                autoFocus
              />
              {isCode && !isError && <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label={i18n.t("recovery.form.code")}
                  name="code"
                  value={user.code}
                  onChange={handleChangeInput}
                  autoComplete="code"
                />
              </>}
              <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                loadingPosition="start"
                variant="outlined"
              >
                {i18n.t(`recovery.buttons.${isCode ? isError ? 'newCode' : 'next' : 'submit'}`)}
              </LoadingButton>
              <Grid container>
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    component={RouterLink}
                    to="/login"
                  >
                    {i18n.t("login.buttons.login")}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page >
  );
};

export default Recovery;
