import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Chip } from '@mui/material';

function isColorDark(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
}

// Função para renderizar a label da opção
const renderOptionLabel = (option) => option.name;

export default function AutoCompleteStyled({
  label,
  options: initialOptions,
  multiple,
  loading,
  values,
  onSelect,
  fetch,
  error,
  ...props
}) {
  const [options, setOptions] = React.useState(initialOptions);
  const [searchParam, setSearchParam] = React.useState('');

  // Atualizar as opções sempre que o valor de initialOptions mudar
  React.useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  // Sincronizar values com as options
  React.useEffect(() => {
    if (values && values.length > 0) {
      const validValues = values.filter(value =>
        options.some(option => option.name === value.name)
      );

      if (validValues.length !== values.length) {
        onSelect(validValues);
      }
    }
  }, [options, values, onSelect]);

  // Filtrar opções com base no searchParam
  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchParam.toLowerCase()) ||
    (option?.number && +option.number.includes(+searchParam))
  );

  return (
    <Autocomplete
      {...props}
      value={values}
      multiple={multiple}
      size="small"
      limitTags={2}
      onChange={(event, newValue) => {
        onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setSearchParam(newInputValue); // Atualiza o estado de searchParam
        if (newInputValue && fetch) {
          fetch(newInputValue); // Chama a função fetch se existir
        }
      }}
      clearOnBlur
      autoHighlight
      freeSolo
      id="multiple-limit-tags"
      options={filteredOptions} // Usar opções filtradas
      loading={loading}
      getOptionLabel={renderOptionLabel}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              size="small"
              style={option?.color
                ? {
                  backgroundColor: option.color,
                  borderRadius: '5px',
                  color: !isColorDark(option.color) ? '#FFFFFF' : '#000000'
                }
                : { borderRadius: '5px' }}
              key={key}
              label={option.name}
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          label={label}
          variant="outlined"
          onChange={e => setSearchParam(e.target.value)} // Atualiza o searchParam ao digitar
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      sx={{ width: '100%' }}
    />
  );
}
