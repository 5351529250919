import React, { useState } from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import ScheduleMessageModal from '../ScheduleMessageModal';

const CalendarMessageCard = ({ calendarId, message, mediaComponent, ack }) => {
  
  const [openSchedules, setOpenSchedules] = useState(false);

  if (!message?.calendarId) return null;

  return (
    <>
      {/*<ScheduleMessageModal
        inMessage
        scheduleMessageId={message.scheduleMessage.id}
        contactId={message.contactId}
        open={openSchedules}
        onClose={() => setOpenSchedules(false)}
        onConfirm={e => console.info(e)}
      />*/}
      <Card
        sx={{
          maxWidth: 360, // Diminuído para um card menor
          margin: '0 auto',
          borderRadius: '10px',
          boxShadow: 3,
          overflow: 'hidden',
        }}
      >
        <CardActions
          sx={{
            justifyContent: 'space-between',
            padding: '5px 10px',
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3',
          }}
        >
          <Typography variant="body2">Evento de calendário</Typography>
          {/*<Box>
            {message.scheduleMessage.recurrence !== 'ONCE' && (
              <Tooltip title="Editar">
                <IconButton aria-label="edit" size="small" onClick={() => setOpenSchedules(true)}>
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>*/}
        </CardActions>
        <CardContent sx={{ padding: '10px !important' }}>
          <Box>
            {mediaComponent && (
              <Box sx={{ marginBottom: '10px' }}>
                {mediaComponent}
              </Box>
            )}
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>
              {message.body}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            {ack}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CalendarMessageCard;
