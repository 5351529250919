import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

import { i18n } from "../../../translate/i18n";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 2, 3),
}));

// ----------------------------------------------------------------------

Companies.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    total: PropTypes.number,
};

export default function Companies({ title, total, icon }) {
    return (
        <RootStyle>
            <div>
                <Typography variant="h3">{fShortenNumber(total)}</Typography>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {title}
                </Typography>
            </div>
            <Box
                sx={{
                    width: 120,
                    height: 120,
                    lineHeight: 0,
                    borderRadius: '50%',
                    bgcolor: 'background.neutral',
                }}
            >
                {icon}
            </Box>
        </RootStyle>
    );
}
