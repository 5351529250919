import React, { useState, useEffect, useContext, useReducer, useRef } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Box,
} from "@mui/material";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import ToastError from "../../toast/error/toastError";




const reducer = (state, action) => {
	if (action.type === "LOAD_CONTACTS") {
		const contacts = action.payload;
		const newContacts = [];

		contacts.forEach((contact) => {
			const contactIndex = state.findIndex((c) => c.id === contact.id);
			if (contactIndex !== -1) {
				state[contactIndex] = contact;
			} else {
				newContacts.push(contact);
			}
		});

		return [...state, ...newContacts];
	}

	if (action.type === "UPDATE_CONTACTS") {
		const contact = action.payload;
		const contactIndex = state.findIndex((c) => c.id === contact.id);

		if (contactIndex !== -1) {
			state[contactIndex] = contact;
			return [...state];
		} else {
			return [contact, ...state];
		}
	}

	if (action.type === "DELETE_CONTACT") {
		const contactId = action.payload;

		const contactIndex = state.findIndex((c) => c.id === contactId);
		if (contactIndex !== -1) {
			state.splice(contactIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};


const MessagesReportModal = ({ title, open, onClose, onConfirm }) => {

	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const [contacts, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState([]);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		setSearchParam("");
		setChecked([]);
	}, [open]);

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [searchParam]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("/contactsVcard/", {
						params: { searchParam, pageNumber },
					});
					dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					console.error({ err });
					ToastError(err);
				}
			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, pageNumber]);

	const handleSearch = (event) => {
		setSearchParam(event.target.value.toLowerCase());
	};

	const loadMore = () => {
		setPageNumber((prevState) => prevState + 1);
	};

	const handleScroll = (e) => {
		if (!hasMore || loading) return;
		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const renderSkeleton = () => {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ margin: 1 }}>
					<Skeleton variant="circular">
						<Avatar />
					</Skeleton>

				</Box>
				<Box sx={{ width: '100%' }}>
					<Skeleton width="100%">
						<Typography>.</Typography>
					</Skeleton>
				</Box>
			</Box>
		)
	}

	const sendVcard = () => {
		if (checked.length < 1) return;
		onClose(false);
		onConfirm(JSON.stringify(checked));
	}
	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="confirm-dialog">
				<div>
					<p style={{ marginBottom: 25, marinTop: 25 }}>{i18n.t("vcard.title")}</p>
					<TextField
						value={searchParam}
						onChange={handleSearch}
						fullWidth
						label={i18n.t("vcard.searchContacts")}
						id="search-value"
						size="small"
					/>
				</div>
			</DialogTitle>
			<DialogContent sx={{ width: '400px', maxHeight: '400px' }} onScroll={handleScroll} dividers>
				<Typography>
					<div>

						<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
							{contacts && contacts.map((contact) => {
								const labelId = `checkbox-list-label-${contact.id}`;
								return (
									<ListItem
										key={contact.id}
										disablePadding
									>
										<ListItemButton role={undefined} onClick={handleToggle(contact.id)} dense>
											<ListItemIcon>
												<Checkbox
													edge="start"
													checked={checked.indexOf(contact.id) !== -1}
													tabIndex={-1}
													disableRipple
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</ListItemIcon>
											<ListItemAvatar>
												<Avatar alt={contact.name} src={contact?.profilePicUrl} />
											</ListItemAvatar>
											<ListItemText id={labelId} primary={`${contact.name}`} secondary={contact.number} />
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
						{loading && renderSkeleton()}
					</div>
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="error"
				>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => { sendVcard() }}
					color="primary"
				>
					{i18n.t("confirmationModal.buttons.send")}
				</Button>
			</DialogActions>
		</Dialog >
	);
};

export default MessagesReportModal;
