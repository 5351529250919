import { Button, Container, Typography } from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as UniChatDark } from "../../assets/icons/UniChatDark.svg";

import orangeIcon from "../../assets/icons/disconnect.ico";
import icon from "../../assets/icons/favicon.ico";


import "./loading.css";

const useStyles = makeStyles()((theme) => {
    return {
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
        },
    }
});

const BackdropLoading = () => {
    const { classes } = useStyles();
    const history = useHistory();

    const favicon = document.querySelector("link[rel='icon']") || document.createElement('link');
    favicon.href = orangeIcon;
    document.head.appendChild(favicon);
    document.title = "Desconectado";

    const handleReload = () => {

        favicon.href = icon;
        document.head.appendChild(favicon);
        history.push('/')

    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={true}>
                <div id="app-preloader">
                    <div className="preloader-logo" style={{
                        width: '45%',
                        height: '45%'
                    }}>
                        <UniChatDark style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div className="preloader-title"></div>
                    <div>
                        <Container maxWidth={false} sx={{ textAlign: 'center', marginTop: '50px' }}>
                            <Typography variant="h4" gutterBottom>
                                Você foi desconectado
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Sua conta foi acessada de outro dispositivo.
                            </Typography>
                            <Button variant="contained" color="primary" onClick={handleReload}>
                                Recarregar
                            </Button>
                        </Container>
                    </div>
                </div>
            </Backdrop>
        </>

    );
};

export default BackdropLoading;
