import { Box, Paper } from "@mui/material";
import clsx from "clsx";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import ContactDrawer from "../ContactDrawer";
import DragAndDropFiles from "../DragAndDropFiles";
import MessageInput from "../MessageInput/";
import MessagesList from "../MessagesList";
import TagsSelect from "../TagsSelect";
import TicketActionButtons from "../TicketActionButtons";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import apiUnitech from "../../services/apiUnidesk";
import findExtraInfoValue from "../../utils/findExtraInfoValue";

const drawerWidth = 260;

function extrairUUIDDaURL() {
  const url = window.location.href;
  const regex = /\/tickets\/([a-f\d-]+)$/i;
  const match = url.match(regex);

  if (match) {
    return match[1];
  } else {
    return null; // Retorna null se o UUID não for encontrado na URL.
  }
}

const getTicketStorage = () => {
  const ticket_id = localStorage.getItem("ticket_accept");
  localStorage.removeItem("ticket_accept");
  return ticket_id;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    '@media (max-width: 600px)': {
      borderRadius: '5px',
    },
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  ticketInfoContainer: {
    maxWidth: "100%",
    flexBasis: "100%",
    display: "flex",
    justifyContent: 'space-between !important',
    alignItems: 'center',
    alignContent: 'center',
    '@media (max-width: 600px)': {
      maxWidth: "95%",
      flexBasis: "95%",
    },
  },
  mainWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainWrapperShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const ticketId = extrairUUIDDaURL();
  const history = useHistory();
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [gestorData, setGestorData] = useState(null);
  const [ticket, setTicket] = useState({});
  const [tags, setTags] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    let isCancelled = false;

    const reloadContentTicket = async () => {
      try {
        const { data } = await api.get(`/tickets/${ticket.id}`);
        if (isCancelled) return;

        if (data.status !== 'pending') {
          setLoading(true);
          setTicket(data);
          setTimeout(() => {
            if (!isCancelled) setLoading(false);
          }, 2000);
        } else {
          intervalRef.current = setTimeout(reloadContentTicket, 2000);
        }
      } catch (err) {
        if (!isCancelled) {
          console.error(err);
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }

    if (ticket?.id && ticket.status === 'pending') {
      reloadContentTicket();
    }

    return () => {
      isCancelled = true;
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
    };
  }, [ticket]);



  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        const { data } = await api.get(`/tickets/u/${ticketId}`);
        const { queueId } = data;
        const { queues, profile } = user;

        const queueAllowed = queues.find(q => q.id === queueId)
        if (!queueAllowed && !['user', 'admin', 'supervisor'].includes(profile)) {
          ToastError('Acesso não permitido');
          console.debug("Puxando pagina ticket na linha: 142")
          history.push("/tickets");
          return;
        }

        setContact(data.contact);
        setTicket(data);

      } catch (err) {
        ToastError(err);
        console.debug("Puxando pagina ticket na linha: 153")
        history.push("/tickets");
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, user, history]);

  useEffect(() => {
    if (+user?.companyId === 2) {
      setGestorData(null);
      handleGestorData(contact);
    }
  }, [contact, user])

  const handleGestorData = async (ticketContact) => {
    if (ticketContact?.contract) {
      try {
        const { data: gestor } = await apiUnitech.get(`/api/gerenciamentounitech/${ticketContact.contract}?api_key=gestor_unicheff_ee046272_6f38_4f51_9d12_ef8152a228e1`)
        setGestorData(gestor);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!user?.companyId) return;

    const userCompany = user.companyId;


    const handleSocketConnect = () => socket.emit("joinChatBox", `${ticket.id}`);

    const handleTicketUpdate = async (data) => {

      if (!ticket) return;

      if (data.action === "update" && (data?.ticket?.uuid === extrairUUIDDaURL())) {
        setTicket(data.ticket);
      }

      if (data.action === "delete" && (data?.ticket?.uuid === extrairUUIDDaURL())) {
        const ticket_accept_id = getTicketStorage();
        console.debug(`atual: ${ticket_accept_id}, socket: ${data?.ticketId}`);
        console.debug(`uuid:${data?.ticket?.uuid}, url: ${extrairUUIDDaURL()}`);
        if (ticket_accept_id && (+ticket_accept_id !== +data?.ticketId)) {
          console.debug("Puxando pagina ticket na linha: 177")
          history.push("/tickets");
        }
      }
    };

    const handleContactUpdate = (data) => {
      if (data.action === "update") {
        setContact((prevState) => (prevState.id === data.contact?.id) ? { ...prevState, ...data.contact } : prevState);
      }
    };

    socket.on("connect", handleSocketConnect);
    socket.on(`ticket-${userCompany}`, handleTicketUpdate);

    socket.on("contact", handleContactUpdate);

    return () => {
      socket.off(`ticket-${userCompany}`);
      socket.off("contact");
    };

  }, [ticketId, ticket, history, user]);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const setSelectedTagsIds = async (tagsId) => {
    try {
      await api.put(`/tickets/${ticket.id}`, { tags: JSON.stringify(tagsId) });
      setTags(tagsId);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const selectedTagsIds = Array.isArray(ticket?.contact?.tags) ? ticket.contact.tags.map(tag => tag.id) : [];
    setTags(selectedTagsIds);
  }, [ticket]);

  return (
    <DragAndDropFiles className={classes.root} id="drawer-container" style={{ height: 'calc(100vh - 80px)', width: '100%' }}>
      <Paper variant="outlined" elevation={0} className={clsx(classes.mainWrapper, { [classes.mainWrapperShift]: drawerOpen })}>
        <TicketHeader loading={loading}>
          <Box className={classes.ticketInfoContainer}>
            <TicketInfo contact={contact} ticket={ticket} gestor={gestorData} onClick={handleDrawerOpen} />
            <TicketActionButtons ticket={ticket} contact={contact} gestor={gestorData} />
          </Box>
        </TicketHeader>
        {/*<TagsSelect selectedTagsIds={tags} onChange={selectedIds => setSelectedTagsIds(selectedIds)} />*/}
        <ReplyMessageProvider>
          <MessagesList ticket={ticket} ticketId={ticket.id} isGroup={ticket.isGroup} />
          <MessageInput id="inputMessage" ticketId={ticket.id} ticketStatus={ticket.status} style={{ marginLeft: '50px' }} />
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} contact={contact} loading={loading} />
    </DragAndDropFiles>
  );
};

export default Ticket;
