import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Box,
} from "@mui/material";
import { i18n } from "../../translate/i18n";

const SelectContractModal = ({ open, onClose, onConfirm }) => {
	const [contract, setContract] = useState("");
	const [loading, setLoading] = useState(false);

	const handleConfirm = () => {
		setLoading(true);
		setTimeout(() => {
			onConfirm(contract);
			setLoading(false);
			onClose(false);
			setContract("");
		}, 1000);
	};

	const handleClose = () => {
		onClose(false);
		setContract("");
	};

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			aria-labelledby="select-contract-dialog"
			fullWidth
			PaperProps={{
				sx: {
					width: "100%",
					maxWidth: "400px !important",
				},
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="select-contract-dialog" sx={{ textAlign: 'center' }}>
				Selecione o contrato do cliente
			</DialogTitle>
			<DialogContent dividers>
				<Typography variant="body1" sx={{ mb: 2 }}>
					As funções do gestor só funcionam se o contato estiver vinculado a um número de contrato válido.
				</Typography>
				<TextField
					label="N° Contrato"
					value={contract}
					onChange={(e) => setContract(e.target.value)}
					fullWidth
					variant="outlined"
					sx={{ mb: 2 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => handleClose()}
					color="error"
					disabled={loading}
				>
					{i18n.t(`confirmationModal.buttons.cancel`)}
				</Button>
				<Button
					variant="contained"
					onClick={handleConfirm}
					color="primary"
					disabled={loading || !contract.trim()}
					endIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
				>
					{i18n.t("confirmationModal.buttons.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SelectContractModal;
