import { Howl } from "howler";
import notificationMp3 from "../assets/notification.mp3";

const MonitorSound = () => {
    const localStorageSound = 100;

    // Valida e ajusta o volume para estar entre 0 e 100
    const adjustedVolume = Math.max(0, Math.min(100, localStorageSound)) / 100;

    // Cria uma instância do Howl para reproduzir o som
    const sound = new Howl({
        src: [notificationMp3],
        volume: adjustedVolume,
    });

    // Reproduz o som
    sound.play();

    // Limpa a instância do som
    sound.once("end", () => {
        sound.unload();
    });
};

export default MonitorSound;
