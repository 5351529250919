import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import getUserIp from "../../services/getUserIp";
import ToastError from "../../toast/error/toastError";
import UserSession from "../UserSession";
import socket from "../useSocket";

const getUserData = async () => {

	const userLocaleData = await getUserIp();

	const { city, region, ip } = userLocaleData;

	const deviceUUID = UserSession.getUUID();

	return { lastIp: ip, lastLocation: city, lastRegion: region, lastDeviceUUID: deviceUUID };

};

const useAuth = () => {
	const history = useHistory();

	// State definitions
	const [isAuth, setIsAuth] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});
	const [status, setStatus] = useState(0);

	// Intercept request to add Authorization header if token exists
	api.interceptors.request.use(
		config => {
			const token = localStorage.getItem("token");
			if (token) {
				let parsedToken; try { parsedToken = JSON.parse(token) } catch (error) { parsedToken = token };
				config.headers["Authorization"] = `Bearer ${parsedToken}`;
				setIsAuth(true);
			}
			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);

	// Intercept response to handle token refresh and authorization errors
	api.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;

			// On 403 error, attempt to refresh the token
			if (error?.response?.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;
				const { lastIp, lastLocation, lastRegion, lastDeviceUUID } = await getUserData();
				const { data } = await api.post("/auth/refresh_token", { locale: { lastIp, lastLocation, lastRegion, lastDeviceUUID } });
				if (data) {
					localStorage.setItem("token", JSON.stringify(data.token));
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
				}
				return api(originalRequest);
			}

			// On 401 error, remove token and logout user
			if (error?.response?.status === 401) {
				localStorage.removeItem("token");
				api.defaults.headers.Authorization = undefined;
				setIsAuth(false);
			}

			return Promise.reject(error);
		}
	);

	// On mount, refresh token if one exists
	useEffect(() => {
		const token = localStorage.getItem("token");
		(async () => {
			if (token) {
				try {
					const { lastIp, lastLocation, lastRegion, lastDeviceUUID } = await getUserData();
					const { data } = await api.post("/auth/refresh_token", { locale: { lastIp, lastLocation, lastRegion, lastDeviceUUID } });
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					setIsAuth(true);
					setUser(data.user);
					setStatus(1)
				} catch (err) {
					ToastError(err);
				}
			}
			setLoading(false);
		})();
	}, []);

	// Websocket to listen for user updates
	useEffect(() => {

		if (!user?.companyId) return;

		socket.on("user", data => {
			if (data.action === "update" && data.user.id === user.id) {
				if (data.user.status !== status) {
					setStatus(data.user.status)
				} else {
					setUser(data.user);
				}
			}
		});

		// Cleanup: disconnect on unmount
		return () => {
			socket.off("user");
		};
	}, [user]);

	// Redirect super admin users to system overview page
	if (user?.profile == 'adminSuper') {
		history.push("/companies/system-overview");
	}

	// Handle user login
	const handleLogin = async userData => {
		setLoading(true);
		try {
			const { lastIp, lastLocation, lastRegion, lastDeviceUUID } = await getUserData();
			userData = {
				...userData,
				lastIp,
				lastLocation,
				lastRegion,
				lastDeviceUUID
			}
			const { data } = await api.post("/auth/login", userData);

			const { user: { companyId, id } } = data;

			localStorage.setItem("token", JSON.stringify(data.token));
			localStorage.setItem("companyId", companyId);
			localStorage.setItem("userId", id);

			api.defaults.headers.Authorization = `Bearer ${data.token}`;
			setUser(data.user);
			setStatus(1);
			setIsAuth(true);
		} catch (err) {
			ToastError(err);
			history.push("/login");
		} finally {
			setLoading(false);
		}
	};

	// Handle user logout
	const handleLogout = async () => {
		setLoading(true);
		try {
			await api.delete("/auth/logout");
			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");
			localStorage.removeItem("companyId");
			localStorage.removeItem("userId");
			api.defaults.headers.Authorization = undefined;
			history.push("/login");
		} catch (err) {
			ToastError(err);
		} finally {
			setLoading(false);
			socket.disconnect();
		}

	};

	const handleLogoutDisconnect = async () => {
		setLoading(true);
		try {
			await api.delete("/auth/logout");
			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");
			localStorage.removeItem("companyId");
			localStorage.removeItem("userId");
			api.defaults.headers.Authorization = undefined;

			history.push("/disconnect");

		} catch (err) {
			ToastError(err);
		} finally {
			setLoading(false);
			socket.disconnect();
		}
	};

	const handleStatus = (currentStatus) => setStatus(currentStatus);

	const handleUpdateUser = (data) => setUser(data);

	return { isAuth, user, loading, handleLogin, handleLogout, handleLogoutDisconnect, status, handleStatus, handleUpdateUser };
};

export default useAuth;
