import * as React from 'react';

import { makeStyles } from 'tss-react/mui';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AttachFileIcon from '@mui/icons-material/AttachFile';


const useStyles = makeStyles()((theme) => {
    return {
        chatContainer: {
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1000,
        },
        chatWindow: {
            position: 'relative',
            width: 350,
            height: 500,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
        },
        chatHeader: {
            backgroundColor: '#FAFAFA',
            padding: theme.spacing(1, 2),
            color: 'black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
        },
        chatBody: {
            padding: theme.spacing(2),
            overflowY: 'auto',
            flex: 1,
        },
        messageBox: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 2),
            borderRadius: 20,
        },
        inputBox: {
            borderTop: '1px solid #E0E0E0',
            padding: theme.spacing(1, 0),
            display: 'flex',
            alignItems: 'center',
        },
        userMessageBox: {
            marginLeft: 'auto', // Empurre para a direita
            backgroundColor: '#E8E8E8',
            color: 'black',
            borderRadius: 5,
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            maxWidth: '70%',
        },
        supportMessageBox: {
            display: 'flex',
            alignSelf: 'flex-start',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        supportMessage: {
            backgroundColor: '#1976D2',
            color: 'white',
            borderRadius: 5,
            padding: theme.spacing(1, 2),
            maxWidth: '70%',
        },
        supportAvatar: {
            marginRight: theme.spacing(1.5),
        },
        inputFile: {
            display: 'none',
        },
        emojiPicker: {
            position: 'absolute',
            bottom: '60px',
            right: '10px',
            zIndex: 1500,
            maxHeight: '250px',
            overflowY: 'auto',
        },
        iconButton: {
            padding: '0px',
            margin: theme.spacing(0, 1),
        }
    }
});

export default function WebChatInput({ openEmoji, message, onUpload, onSendMessage, updateMessage }) {

    const { classes } = useStyles();

    return (
        <Paper
            component="div"
            sx={{ display: 'flex', alignItems: 'center', width: 400 }}
        >
            <input
                accept="image/*,application/pdf"
                className={classes.inputFile}
                id="contained-button-file"
                type="file"
                onChange={onUpload}
            />
            <label htmlFor="contained-button-file">
                <IconButton component="span" className={classes.iconButton}>
                    <AttachFileIcon />
                </IconButton>
            </label>
            <IconButton onClick={() => openEmoji()} sx={{ p: '10px' }} aria-label="menu">
                <EmojiEmotionsIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Mensagem..."
                value={message}
                inputProps={{ 'aria-label': 'mensagem...' }}
                onChange={(e) => updateMessage(e)}
                onKeyPress={(e) => e.key == 'Enter' && onSendMessage()}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" onClick={() => onSendMessage()} sx={{ p: '10px' }} aria-label="directions">
                <SendIcon />
            </IconButton>
        </Paper>
    );
}