import React from "react";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		MainHeaderButtonsWrapper: {
			flex: "none",
			marginLeft: "auto",
			"& > *": {
				margin: theme.spacing(1),
			},
		},
	}
});

const MainHeaderButtonsWrapper = ({ children }) => {
	const { classes } = useStyles();

	return <div className={classes.MainHeaderButtonsWrapper}>{children}</div>;
};

export default MainHeaderButtonsWrapper;
