import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ToastError from "../../toast/error/toastError";

const QueueSelect = ({ selectedQueueIds, onChange }) => {
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/queue");
                setQueues(data);
            } catch (err) {
                ToastError(err);
            }
        })();
    }, []);

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div style={{ marginTop: 6 }}>
            <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel id="profile-selection-input-label">
                    {i18n.t("ticketsQueueSelect.placeholder")}
                </InputLabel>
                <Select
                    label={i18n.t("queueSelect.inputLabel")}
                    multiple
                    labelwidth={60}
                    value={selectedQueueIds}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected?.length > 0 &&
                                selected.map((id) => {
                                    const queue = queues.find((q) => q.id === id);
                                    return queue ? (
                                        <Chip
                                            key={id}
                                            style={{ margin: "2px", backgroundColor: queue.color }}
                                            variant="outlined"
                                            label={queue.name}
                                        />
                                    ) : null;
                                })}
                        </div>
                    )}
                >
                    {queues.map((queue) => (
                        <MenuItem key={queue.id} value={queue.id}>
                            {queue.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default QueueSelect;
