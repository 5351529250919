import React from "react";

import { makeStyles } from 'tss-react/mui';
import { Avatar, Card, CardHeader } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';


const useStyles = makeStyles()((theme) => {
	return {
		ticketHeader: {
			display: "flex",
			borderRadius: '0px !important',
			maxHeight: '48px',
			flex: "none",
			marginLeft: 10,
			borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		},
	}
});

const TicketHeaderSkeleton = () => {
	const { classes } = useStyles();

	return (
		<Card square className={classes.ticketHeader}>
			<CardHeader
				style={{ padding: 10 }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={
					<Skeleton animation="wave" variant="circle">
						<Avatar alt="contact_image" />
					</Skeleton>
				}
				title={<Skeleton animation="wave" width={80} />}
				subheader={<Skeleton animation="wave" width={140} />}
			/>
		</Card>
	);
};

export default TicketHeaderSkeleton;
