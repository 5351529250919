import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import CircularProgress from "@mui/material/CircularProgress";

import { green } from "@mui/material/colors";

import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import whatsBackgroundDark from "../../assets/wa-background-dark.png";
import whatsBackgroundLight from "../../assets/wa-background.png";
import useSettings from '../../hooks/useSettings';
import MarkdownWrapper from "../MarkdownWrapper";
import NewEmojiPicker from "../NewEmojiPicker";

const useStyles = makeStyles()((theme) => {
	return {
		messagesList: {
			backgroundSize: '370px',
			display: "flex",
			justifyContent: "center",
			flexGrow: 1,
			padding: "20px 20px 20px 20px",
			overflowY: "scroll",
			'@media (max-width: 600px)': {
				paddingBottom: "90px",
			},
			...theme.scrollbarStyles,
		},
		textContentItem: {
			overflowWrap: "break-word",
			padding: "3px 80px 6px 6px",
		},
		messageRight: {
			fontSize: '13px',
			marginLeft: 20,
			marginTop: 2,
			minWidth: 100,
			maxWidth: 510,
			height: "auto",
			display: "block",
			position: "relative",
			whiteSpace: "pre-wrap",
			alignSelf: "flex-end",
			borderRadius: 8,
			paddingLeft: 5,
			paddingRight: 5,
			paddingTop: 5,
			paddingBottom: 0,
		},
		timestamp: {
			fontSize: 11,
			position: "absolute",
			bottom: 0,
			right: 5,
			color: "#999",
		},
	}
});

const EditMessageModal = ({ open, onClose, loading, onSave, message }) => {

	const { classes } = useStyles();
	const { themeMode } = useSettings();
	const [editedMessage, setEditedMessage] = useState(null);

	useEffect(() => {
		if (open) {
			setEditedMessage(message?.body);
		}
	}, [open, message]);

	const handleSave = () => {
		onSave(editedMessage)
	};

	const checkTheme = () => {
		var retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
		switch (themeMode) {
			case 'dark':
				retorno = { backgroundImage: `url("${whatsBackgroundDark}")` };
				break;
			case 'light':
				retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
				break;
		}
		return retorno
	}

	if (!open) return null;

	function setInputValue(value) {
		setEditedMessage(editedMessage ? editedMessage + value : value);
	}


	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="edit-message-dialog"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="edit-message-dialog" sx={{ padding: '10px' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<IconButton onClick={() => onClose(false)}> <CloseIcon /></IconButton>
					<p>Editar Mensagem</p>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ padding: '0px' }}>
				<Box>
					<Box className={classes.messagesList} style={checkTheme()}>
						<Box component="div" className={`${classes.messageRight}`} sx={{ fontStyle: 'italic', backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3' }}>
							<Box className={classes.textContentItem}>
								<Box component="div" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#212B36' }}>
									<MarkdownWrapper>{message?.body}</MarkdownWrapper>
								</Box>
								<span className={classes.timestamp}>
									{format(parseISO(message?.updatedAt), "HH:mm")}
								</span>
							</Box>
						</Box>
					</Box>
					<Paper
						component="form"
						sx={{
							p: '2px 4px',
							display: 'flex',
							alignItems: 'center',
							borderRadius: '0px',
							backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#111b21' : '#f0f2f5'
						}}
					>
						<Box sx={{
							display: 'flex',
							width: '100%',
							backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#212b36' : '#ffffff',
							margin: '10px 0px 10px 20px',
							borderRadius: '10px'
						}}>
							<InputBase
								sx={{ padding: '15px 0px 15px 15px', flex: 1 }}
								multiline
								disabled={loading}
								maxRows={6}
								placeholder="Search Google Maps"
								value={editedMessage}
								onChange={e => setEditedMessage(e.target.value)}
								inputProps={{ 'aria-label': 'search google maps' }}
							/>
							<NewEmojiPicker disabled={loading} id="emojiPicker" value={''} setValue={setInputValue} />
						</Box>
						<IconButton disabled={loading} color="primary" onClick={() => handleSave()} aria-label="directions">
							{loading ? (
								<CircularProgress
									size={24}
									sx={{
										color: green[500],
										position: "absolute",
										top: "50%",
										left: "50%",
										marginTop: -12,
										marginLeft: -12
									}}
								/>)
								:
								(
									<CheckCircleIcon sx={{
										width: '35px',
										height: '35px'
									}} />
								)
							}


						</IconButton>
					</Paper>
				</Box>
			</DialogContent >
		</Dialog >
	);
};

export default EditMessageModal;
