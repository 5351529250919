import React, { useContext, useEffect, useReducer, useState } from "react";

import { makeStyles } from 'tss-react/mui';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import { Avatar } from "@mui/material";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AvatarPresence from "../../components/AvatarPresence";

import socket from "../../hooks/useSocket";
import ToastError from "../../toast/error/toastError";


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



const reducer = (state, action) => {
  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const profilesNames = {
  admin: i18n.t("userType.translate.admin"),
  adminSuper: i18n.t("userType.translate.admin"),
  supervisor: i18n.t("userType.translate.supervisor"),
  user: i18n.t("userType.translate.user"),
}

const Users = () => {
  const { classes } = useStyles();
  const { themeStretch } = useSettings();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [users, dispatch] = useReducer(reducer, []);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [count, setCount] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const { user } = useContext(AuthContext);
  const companyId = user.companyId;


  const [filter, setChangeFilter] = useState('ative');

  const handleChangeFilter = (event) => {
    setChangeFilter(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, filter]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {

          let isActive = filter === 'ative' ? true : filter === 'inative' ? false : false;
          let getAllUsers = filter === 'viewAll';

          const { data } = await api.get("/users-list/", {
            params: { searchParam, limit: rowsPerPage, pageNumber: page + 1, isActive, getAllUsers },
          });
          dispatch({ type: "LOAD_USERS", payload: data.users });
          setCount(data.count)
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          console.info(err)
          ToastError(err);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, rowsPerPage, filter, page]);

  async function loadUsers() {
    try {
      let isActive = filter === 'ative' ? true : filter === 'inative' ? false : false;
      let getAllUsers = filter === 'viewAll';
      const { data } = await api.get("/users-list/", {
        params: { searchParam, limit: rowsPerPage, pageNumber: page + 1, isActive, getAllUsers },
      });
      dispatch({ type: "LOAD_USERS", payload: data.users });
      setCount(data.count)
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      console.info(err)
      ToastError(err);
    }
  }
  useEffect(() => {



    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        if (!(companyId === data.user.companyId)) return;
        dispatch({ type: "UPDATE_USERS", payload: data.user });
        loadUsers();
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    });

    return () => {
      socket.off("user");
    };
  }, []);

  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditUser = (user, admin) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      ToastSuccess(i18n.t("users.toasts.deleted"));
    } catch (err) {
      console.info(err)
      ToastError(err);
    }
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);

  const blockContact = async (userId, isActive) => {
    try {
      const userData = { isActive: !isActive };
      await api.put(`/users/${userId}`, userData);
    } catch (err) {
      console.info(err)
      ToastError(err);
    }
  }

  function renderColumn(UserToColumn) {
    const { companyId, email, id, name, profile, media, driveUrl, isActive, status } = UserToColumn;
    const isItemSelected = selected.indexOf(name) !== -1;

    return (
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
      >
        <TableCell align="left" style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <AvatarPresence
              id="Status"
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              status={status}
            >
              <Avatar src={driveUrl ?? media} alt={name} />
            </AvatarPresence>
          </div>
          <div style={{ margin: '0px 0px 0px 30px' }}>{name}</div>
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">
          {profilesNames[profile]}
        </TableCell>
        <TableCell align="center">
          <IconButton
            size="small"
            onClick={() => handleEditUser(UserToColumn)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={(e) => {
              setConfirmModalOpen(true);
              setDeletingUser(UserToColumn);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
          {user.profile !== 'adminSuper' &&
            <Tooltip title={isActive ? 'Desativar usuário' : 'Ativar usuário'} placement="right">
              <IconButton
                size="small"
                onClick={(e) => { blockContact(id, isActive) }}
              >
                {isActive ? <AccountCircleIcon sx={{ color: 'green' }} /> : <NoAccountsIcon sx={{ color: 'red' }} />}
              </IconButton>
            </Tooltip>
          }
        </TableCell>
      </TableRow>
    )
  }

  function renderTable() {
    var rows = [];
    if (user.profile != "adminSuper") {
      users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
        (user.companyId == companyId && user.profile != "adminSuper" && rows.push(renderColumn(user)))
      ))
    } else {
      users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
        rows.push(renderColumn(user))
      ))
    }
    return rows;
  }
  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            deletingUser &&
            `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser.name
            }?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteUser(deletingUser.id)}
        >
          {i18n.t("users.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <UserModal
          open={userModalOpen}
          onClose={handleCloseUserModal}
          aria-labelledby="form-dialog-title"
          userId={selectedUser && selectedUser.id}
        />
        <Page title={i18n.t("mainDrawer.listItems.users")}>
          <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading={i18n.t("mainDrawer.listItems.users")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={handleOpenUserModal}
                  >
                    {i18n.t("users.buttons.add")}
                  </Button>
                </>
              }
            />

            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size="small"
                  style={{ padding: '20px' }}
                  placeholder={i18n.t("userType.translate.searchUsers")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{
                  marginRight: '20px',
                  minWidth: '125px',
                }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Filtrar</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filter}
                      label="Filtrar"
                      onChange={handleChangeFilter}
                    >
                      <MenuItem value="ative">Ativos</MenuItem>
                      <MenuItem value="inative">Inativos</MenuItem>
                      <MenuItem value="viewAll">Todos</MenuItem>

                    </Select>
                  </FormControl>
                </Box>
              </div>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">{i18n.t("userType.translate.name")}</TableCell>
                        <TableCell align="left">{i18n.t("userType.translate.email")}</TableCell>
                        <TableCell align="left">{i18n.t("userType.translate.type")}</TableCell>
                        <TableCell align="center">{i18n.t("userType.translate.action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {
                          renderTable()
                        }
                      </>
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      {loading && <TableRowSkeleton avatar columns={2} />}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")}: ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default Users;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
