import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Chip, Divider, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select, Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from 'axios';
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import * as Yup from "yup";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import { i18n } from "../../translate/i18n";
import EstadosBrasil from "../../utils/EstadosBrasil";
import UFsBrasil from "../../utils/UFsBrasil";
import AutoCompleteTags from '../AutoCompleteTags';
import { CEPMask, CpfCnpjMask, RgMask } from "../MaskInputs";

const initialState = {
	name: "",
	number: "",
	contract: "",
	email: "",
	nationality: "Brasileiro (a)",
	nationalId: "",
	document: "",
	date: "",
	marital: "not_identified",
	occupation: "",
	zip: "",
	address: "",
	addressNumber: "",
	complement: "",
	city: "",
	state: "",
	extraInfo: [],
	tagIds: []
};

const useStyles = makeStyles()((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	number: Yup.string().min(8, "Muito Curto(a)!").max(50, "Muito Longo(a)!"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {

	const { classes } = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);
	const [firstFind, setFirstFind] = useState(true);
	const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState({ ...initialState, companyId: loggedInUser.companyId, });
	const [selectedTab, setSelectedTab] = useState(0);
	const [cities, setCities] = useState([]);

	useEffect(() => {
		if (contact?.state) {
			const { cidades } = EstadosBrasil.find(estado => estado.sigla === contact.state);
			setCities(cidades)
		}
	}, [contact.state])

	useEffect(() => {
		const fetchContact = async () => {
			if (open) {
				try {
					if (initialValues) {
						setContact(prevState => ({ ...prevState, ...initialValues }));
					}
					if (!contactId) return;

					setLoading(true);
					const { data } = await api.get(`/contacts/${contactId}`);
					if (!!data.zip) setFirstFind(false);
					setContact({ ...data, ...initialValues });
					if (data.tags.length > 0) {
						setContact((prevState) => ({ ...prevState, 'tagIds': data.tags.map(t => t.id) }));
					}
				} catch (err) {
					ToastError(err);
				} finally {
					setLoading(false);
				}
			}
		};
		fetchContact();
	}, [contactId, open, initialValues]);


	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
				handleClose();
			} else {
				const { data } = await api.post("/contacts", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			ToastSuccess(i18n.t("contactModal.success"));
		} catch (err) {
			ToastError(err);
		}
	};

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const handleFindCEP = async (value) => {
		try {
			if (value && value.length === 9 && firstFind) {
				const { data } = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
				setContact((prevState) => ({
					...prevState,
					zip: value,
					address: data.logradouro,
					city: data.localidade,
					state: data.uf,
				}));
			} else {
				setFirstFind(true);
			}
		} catch (error) {
			console.error(error)
			ToastError("Ocorreu um erro ao buscar o CEP");
		}
	};

	const handleChangeValues = (e, callback) => {
		const { name, value } = e.target;
		setContact((prevState) => ({ ...prevState, [name]: value }));
		callback(e)
	}

	const handleChangeTagSelect = (ids) => {
		setContact((prevState) => ({ ...prevState, 'tagIds': ids }));
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="body"
				sx={{
					"& .MuiDialog-container": {
						"& .MuiPaper-root": {
							width: "100%",
							maxWidth: "700px",
						},
					},
				}}
				fullWidth
				BackdropProps={{
					style: {
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, handleChange, errors, touched, isSubmitting }) => (
						<Form>
							<Field
								style={{ display: 'none' }}
								as={TextField}
								type="hidden"
								name="companyId"
							/>
							<DialogContent dividers>
								{/* Tabs Header */}
								<Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example" centered>
									<Tab label={i18n.t("contactModal.tabs.general")} />
									<Tab label={i18n.t("contactModal.tabs.extraInfo")} />
								</Tabs>

								{/* Conteúdo do Tab 1: Informações Gerais */}
								{selectedTab === 0 && (
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.name")}
												name="name"
												fullWidth
												autoFocus
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												InputLabelProps={{ shrink: true }}
												value={contact.name}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={+loggedInUser?.companyId == 2 ? 8 : 6}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.number")}
												name="number"
												fullWidth
												error={touched.number && Boolean(errors.number)}
												helperText={touched.number && errors.number}
												variant="outlined"
												margin="dense"
												InputLabelProps={{ shrink: true }}
												value={contact.number}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										{+loggedInUser?.companyId == 2 && (
											<Grid item xs={12} sm={4}>
												<Field
													size="small"
													as={TextField}
													label={i18n.t("contactModal.form.contract")}
													name="contract"
													fullWidth
													margin="dense"
													variant="outlined"
													InputLabelProps={{ shrink: true }}
													value={contact.contract}
													onChange={e => handleChangeValues(e, handleChange)}
												/>
											</Grid>
										)}
										<Grid item xs={12} sm={+loggedInUser?.companyId == 2 ? 12 : 6}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.email")}
												name="email"
												error={touched.email && Boolean(errors.email)}
												helperText={touched.email && errors.email}
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.email}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12}>
											<AutoCompleteTags
												selected={contact.tagIds}
												name="tagIds"
												type="contacts"
												onSelect={handleChangeTagSelect}
											/>
										</Grid>
										<Grid item xs={12}>
											<Divider>
												<Chip label="Informações pessoais" color="primary" size="small" sx={{ borderRadius: '7px' }} />
											</Divider>
										</Grid>
										<Grid item xs={12} sm={4}>
											<RgMask
												size="small"
												label={i18n.t('contactModal.form.nationalId')}
												name="nationalId"
												variant="outlined"
												margin="dense"
												fullWidth
												InputLabelProps={{ shrink: true }}
												value={contact.nationalId}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<CpfCnpjMask
												size="small"
												label="CPF/CNPJ"
												name="document"
												variant="outlined"
												margin="dense"
												fullWidth
												InputLabelProps={{ shrink: true }}
												value={contact.document}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Field
												size="small"
												as={TextField}
												label={contact?.document?.length > 14 ? i18n.t('contactModal.form.registrationDate') : i18n.t('contactModal.form.dateBirth')}
												name="date"
												type="date"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={formatDate(contact.date)}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={5}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.occupation")}
												name="occupation"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.occupation}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={3}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.nationality")}
												name="nationality"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.nationality}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<FormControl variant="outlined" margin="dense" fullWidth size="small">
												<InputLabel id="marital-input-select">
													{i18n.t('contactModal.form.marital')}
												</InputLabel>
												<Select
													name="marital"
													value={contact.marital}
													onChange={e => handleChangeValues(e, handleChange)}
													labelId="marital-input-select"
													label={i18n.t('contactModal.form.marital')}
													InputLabelProps={{ shrink: true }}
												>
													<MenuItem value="not_identified">Não itentificado</MenuItem>
													<MenuItem value="unmarried">Solteiro</MenuItem>
													<MenuItem value="married">Casado</MenuItem>
													<MenuItem value="divorced">Divorciado</MenuItem>
													<MenuItem value="widowed">Viúvo</MenuItem>
												</Select>
											</FormControl>
										</Grid>

										<Grid item xs={12} sm={4}>
											<Field
												as={CEPMask}
												onBlur={e => handleFindCEP(e.target.value)}
												onChange={e => handleChangeValues(e, handleChange)}
												size="small"
												label={i18n.t("contactModal.form.zip")}
												name="zip"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.zip}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.address")}
												name="address"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.address}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={2}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.addressNumber")}
												name="addressNumber"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.addressNumber}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.complement")}
												name="complement"
												fullWidth
												margin="dense"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={contact.complement}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={3}>
											<FormControl
												size="small"
												variant="outlined"
												fullWidth
												style={{ marginTop: '8px' }}
											>
												<InputLabel htmlFor="state-input-select">{i18n.t('contactModal.form.state')}</InputLabel>
												<Field
													name="state"
													as={Select}
													value={contact.state}
													onChange={e => setContact(prevState => ({ ...prevState, state: e.target.value, city: null }))}
													labelId="state-input-select"
													label={i18n.t('contactModal.form.state')}
													renderValue={(selected) => selected}
													InputLabelProps={{ shrink: true }}
												>
													<ListSubheader>Sudeste</ListSubheader>
													{UFsBrasil.sudeste.map(uf => (
														<MenuItem key={uf.code} value={uf.code}>
															{`${uf.code} - ${uf.name}`}
														</MenuItem>
													))}

													<ListSubheader>Norte</ListSubheader>
													{UFsBrasil.norte.map(uf => (
														<MenuItem key={uf.code} value={uf.code}>
															{`${uf.code} - ${uf.name}`}
														</MenuItem>
													))}

													<ListSubheader>Nordeste</ListSubheader>
													{UFsBrasil.nordeste.map(uf => (
														<MenuItem key={uf.code} value={uf.code}>
															{`${uf.code} - ${uf.name}`}
														</MenuItem>
													))}

													<ListSubheader>Centro-Oeste</ListSubheader>
													{UFsBrasil.centro_oeste.map(uf => (
														<MenuItem key={uf.code} value={uf.code}>
															{`${uf.code} - ${uf.name}`}
														</MenuItem>
													))}

													<ListSubheader>Sul</ListSubheader>
													{UFsBrasil.sul.map(uf => (
														<MenuItem key={uf.code} value={uf.code}>
															{`${uf.code} - ${uf.name}`}
														</MenuItem>
													))}
												</Field>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={5}>
											<FormControl
												size="small"
												variant="outlined"
												fullWidth
												style={{ marginTop: '8px' }}
											>
												<InputLabel htmlFor="city-input-select">{i18n.t('contactModal.form.city')}</InputLabel>
												<Field
													name="city"
													as={Select}
													value={contact.city}
													onChange={e => setContact(prevState => ({ ...prevState, city: e.target.value }))}
													labelId="city-input-select"
													label={i18n.t('contactModal.form.city')}
													renderValue={(selected) => selected}
													InputLabelProps={{ shrink: true }}
												>
													<ListSubheader>{contact.state}</ListSubheader>
													{cities.map((city, i) => (
														<MenuItem key={i} value={city}>
															{`${city}`}
														</MenuItem>
													))}
												</Field>
											</FormControl>
										</Grid>
									</Grid>
								)}

								{/* Conteúdo do Tab 2: Informações Extras */}
								{selectedTab === 1 && (
									<>
										<Typography
											style={{ marginBottom: 8, marginTop: 12 }}
											variant="subtitle1"
										>
											{i18n.t("contactModal.form.extraInfo")}
										</Typography>

										<FieldArray name="extraInfo">
											{({ push, remove }) => (
												<>
													{values.extraInfo && values.extraInfo.length > 0 &&
														values.extraInfo.map((info, index) => (
															<div className={classes.extraAttr} key={`${index}-info`}>
																<Field
																	size="small"
																	as={TextField}
																	label={i18n.t("contactModal.form.extraName")}
																	name={`extraInfo[${index}].name`}
																	variant="outlined"
																	margin="dense"
																	className={classes.textField}
																/>
																<Field
																	size="small"
																	as={TextField}
																	label={i18n.t("contactModal.form.extraValue")}
																	name={`extraInfo[${index}].value`}
																	variant="outlined"
																	margin="dense"
																	className={classes.textField}
																/>
																<IconButton size="small" onClick={() => remove(index)}>
																	<DeleteOutlineIcon />
																</IconButton>
															</div>
														))}
													<div className={classes.extraAttr}>
														<Button
															style={{ flex: 1, marginTop: 8 }}
															variant="outlined"
															color="primary"
															onClick={() => push({ name: "", value: "" })}
														>
															{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
														</Button>
													</div>
												</>
											)}
										</FieldArray>
									</>
								)}
							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;


const formatDate = (dateString) => {
	if (!dateString) return '';
	const date = new Date(dateString);
	return date.toISOString().split('T')[0];
};