import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';

const StyledMenuComponent = styled(Paper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 10,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export default function StyledMenu({ actions, onClick }) {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleClick = () => setOpen(!open);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <IconButton ref={anchorRef} onClick={handleClick}>
                {open ? <CloseIcon /> : <AddIcon />}
            </IconButton>
            <Popper open={open} sx={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} anchorEl={anchorRef.current} placement="top-end">
                <ClickAwayListener onClickAway={handleClose}>
                    <StyledMenuComponent
                        sx={{ padding: '10px !important' }}
                    >
                        {actions.map(action => (<>
                            <MenuItem
                                sx={{
                                    padding: '7px 15px',
                                    borderRadius: '10px'
                                }}
                                onClick={() => {
                                    onClick(action.action);
                                    handleClose();
                                }}>
                                {action.icon}
                                <Typography variant="body2">
                                    {action.name}
                                </Typography>
                            </MenuItem>
                        </>))}
                    </StyledMenuComponent>
                </ClickAwayListener>
            </Popper>
        </div>
    );
}
