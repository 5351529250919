export default function GetFirstName(str) {
    str = str.trim();

    var regex = /^[^\s]+/;
    var match = str.match(regex);

    if (match && match.length > 0) {
        return match[0];
    } else {
        return "";
    }
}
