import { Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';

function isColorDark(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
}

// Função para renderizar a label da opção
const renderOptionLabel = (option) => option.name;

export default function AutoCompleteFetchedStyled({
  label,
  options: initialOptions,
  multiple,
  loading,
  values,
  onSelect,
  fetch,
  ...props
}) {
  const [options, setOptions] = React.useState(initialOptions);
  const [searchParam, setSearchParam] = React.useState('');
  const [selectedOptions, setSelectedOptions] = React.useState(values || []);

  // Atualizar as opções sempre que o valor de initialOptions mudar
  React.useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  // Sincronizar values com as opções selecionadas
  React.useEffect(() => {
    setSelectedOptions(values);
  }, [values]);

  // Função para combinar novas opções com as selecionadas
  const combineOptions = (fetchedOptions) => {
    const combined = [...selectedOptions, ...fetchedOptions];
    // Remover duplicatas, assumindo que a propriedade `name` é única
    const uniqueOptions = combined.filter(
      (option, index, self) =>
        index === self.findIndex((o) => o.name === option.name)
    );
    setOptions(uniqueOptions);
  };

  // Filtrar opções com base no searchParam
  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchParam.toLowerCase()) ||
    (option?.number && +option.number.includes(+searchParam))
  );

  return (
    <Autocomplete
      {...props}
      value={selectedOptions}
      multiple={multiple}
      size="small"
      limitTags={2}
      onChange={(event, newValue) => {
        setSelectedOptions(newValue);
        onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setSearchParam(newInputValue);
        if (newInputValue && fetch) {
          const result = fetch(newInputValue);
          // Verifica se o resultado do fetch é uma Promise
          if (result && typeof result.then === 'function') {
            result.then((fetchedOptions) => {
              combineOptions(fetchedOptions);
            });
          } else {
            console.warn('A função fetch não retornou uma Promise');
          }
        }
      }}
      clearOnBlur
      autoHighlight
      freeSolo
      id="multiple-limit-tags"
      options={filteredOptions}
      loading={loading}
      getOptionLabel={renderOptionLabel}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              size="small"
              style={option?.color
                ? {
                    backgroundColor: option.color,
                    borderRadius: '5px',
                    color: !isColorDark(option.color) ? '#FFFFFF' : '#000000'
                  }
                : { borderRadius: '5px' }}
              key={key}
              label={option.name}
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={e => setSearchParam(e.target.value)} // Atualiza o searchParam ao digitar
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <gress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      sx={{ width: '100%' }}
    />
  );
}
