import React from 'react';
import axios from 'axios';
import JSZip from 'jszip';


function getFormattedDateTime() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 pois os meses em JavaScript começam em 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}


const ZipDownloader = async (links) => {
    const zip = new JSZip();
    await Promise.all(
        links.map(async (link) => {
            const response = await axios.get((link), { responseType: 'arraybuffer' });
            const filename = (link).split('/').pop();
            zip.file(filename, response.data);
        })
    );

    // Gere o blob ZIP
    const content = await zip.generateAsync({ type: 'blob' });

    // Força o download do ZIP
    const blobURL = URL.createObjectURL(content);
    const tempLink = document.createElement('a');
    tempLink.href = blobURL;
    const filename = `${getFormattedDateTime()}.zip`;
    tempLink.setAttribute('download', filename);
    tempLink.click();

}

export default ZipDownloader;
