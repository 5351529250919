import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import {
	Grid,
	FormControl,
	InputAdornment,
	CircularProgress,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	TextField,
	Button,
	IconButton,
	InputLabel,
	Select,
	MenuItem,
	Box
} from "@mui/material";

import { MuiColorInput } from 'mui-color-input';
import { green } from "@mui/material/colors";
import { i18n } from "../../translate/i18n";
import ConfirmationMultiCompaniesModal from "../ConfirmationMultiCompaniesModal";
import api from "../../services/api";
import Colorize from '@mui/icons-material/Colorize';
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import ToastSuccess from "../../toast/success/toastSuccess";
import ToastError from "../../toast/error/toastError";

const useStyles = makeStyles()((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: '10px',
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
}));

const TagModal = ({ open, onClose, tagId }) => {
	const { classes } = useStyles();
	const greetingRef = useRef();
	const { user: loggedInUser } = useContext(AuthContext);
	const initialState = { companyId: loggedInUser.companyId, name: "", color: "", type: "contacts" };
	const [tag, setTag] = useState(initialState);
	const [errors, setErrors] = useState({});
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [loading, setLoading] = useState(false); // Estado para controle do carregamento

	const TagSchema = Yup.object().shape({
		name: Yup.string().min(2, "Muito Curto(a)!").max(400, "Muito Longo(a)!").required("Obrigatório"),
		color: Yup.string().required(),
	});

	useEffect(() => {
		const fetchUser = async () => {
			if (!tagId) return;
			try {
				setLoading(true); // Ativa o estado de carregamento
				const { data } = await api.get(`/tags/${tagId}`);
				setTag(prevState => ({ ...prevState, ...data }));
			} catch (err) {
				ToastError(err);
			} finally {
				setLoading(false); // Desativa o estado de carregamento
			}
		};
		fetchUser();
	}, [tagId, open]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setTag(prevState => ({ ...prevState, [name]: value }));
	};

	const handleColorChange = (color) => {
		setTag(prevState => ({ ...prevState, color }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			await TagSchema.validate(tag, { abortEarly: false });
			const TagsData = { ...tag };
			if (tagId) {
				await api.put(`/tags/${tagId}`, TagsData);
			} else {
				await api.post("/tags", TagsData);
			}
			ToastSuccess('Tag cadastrada com sucesso');
			handleClose();
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				const validationErrors = {};
				err.inner.forEach((error) => {
					validationErrors[error.path] = error.message;
				});
				setErrors(validationErrors);
			} else {
				ToastError(err);
			}
		}
		setIsSubmitting(false);
	};

	const handleClose = () => {
		onClose();
		setTag(initialState);
		setErrors({});
	};

	const handleChangeType = (e) => {
		setTag({ ...tag, type: e.target.value });
	};

	return (
		<div className={classes.root}>
			<ConfirmationMultiCompaniesModal
				title={i18n.t("messageOptionsMultiCompanies.confirmationModal.title")}
				value={tag}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleSubmit}
			>
				{i18n.t("messageOptionsMultiCompanies.confirmationModal.message")}
			</ConfirmationMultiCompaniesModal>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="form-dialog-title">
					{tagId ? `${i18n.t("tagsList.editTag")}` : `${i18n.t("tagsList.addTag")}`}
				</DialogTitle>
				{loading ? ( // Se estiver carregando, mostra o CircularProgress
					<Box className={classes.loadingContainer}>
						<CircularProgress />
					</Box>
				) : (
					<form onSubmit={handleSubmit}>
						<DialogContent dividers className={classes.teste}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										style={{ display: 'none' }}
										type="hidden"
										name="tagId"
										value={tagId}
									/>
									<TextField
										size="small"
										label={i18n.t("tagsList.name")}
										type="text"
										name="name"
										value={tag.name}
										onChange={handleChange}
										inputRef={greetingRef}
										error={Boolean(errors.name)}
										helperText={errors.name}
										required
										autoFocus
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<MuiColorInput
										format="hex"
										label={i18n.t("tagsList.color")}
										name="color"
										size="small"
										value={tag.color}
										onChange={handleColorChange}
										inputRef={greetingRef}
										error={Boolean(errors.color)}
										helperText={errors.color}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</Grid>

								<Grid item xs={12}>
									<FormControl fullWidth margin="dense" variant="outlined">
										<InputLabel>{i18n.t("tagsList.type")}</InputLabel>
										<Select
											name="type"
											size="small"
											value={tag.type}
											onChange={handleChangeType}
											label={i18n.t("tagsList.type")}
										>
											<MenuItem disabled value="tickets">Atendimentos</MenuItem>
											<MenuItem value="contacts">Contatos</MenuItem>
											{/*<MenuItem value="kanban">Kanban</MenuItem>*/}
											<MenuItem disabled value="schedules">Calendários e eventos</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color="secondary"
								disabled={isSubmitting}
								variant="outlined"
							>
								{i18n.t("tagsList.cancel")}
							</Button>
							<Button
								type="submit"
								color="primary"
								disabled={isSubmitting}
								variant="contained"
								className={classes.btnWrapper}
							>
								{tagId ? `${i18n.t("tagsList.edit")}` : `${i18n.t("tagsList.add")}`}
								{isSubmitting && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Button>
						</DialogActions>
					</form>
				)}
			</Dialog>
		</div>
	);
};

export default TagModal;
