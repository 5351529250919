import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

// MUI Material
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

// MUI Icons
import ArchiveIcon from '@mui/icons-material/Archive';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import VillaIcon from '@mui/icons-material/Villa';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

// Internal Imports
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";
import ReasonForContact from "../ReasonForContact";
import TransferTicketPendingModalCustom from "../TransferTicketPendingModalCustom";

import { ReactComponent as UniChatDark } from "../../assets/icons/UniChatDark.svg";
import { ReactComponent as UniChatLight } from "../../assets/icons/UniChatLight.svg";
import ToastSuccess from "../../toast/success/toastSuccess";
import SelectContractModal from "../SelectContractModal";
import UniGestorModal from "../UniGestorModal";

const useStyles = makeStyles()((theme) => {
	return {
		actionButtons: {
			marginTop: '-15px',
			marginRight: 6,
			flex: "none",
			alignSelf: "center",
			marginLeft: "auto",
			"& > *": {
				margin: theme.spacing(1),
			},
		},
		mobileReturn: {
			'@media (max-width: 1600px)': {
				display: 'none'
			},
		},
		mobileResolve: {
			'@media (max-width: 1000px)': {
				display: 'none'
			},
		},
	}
});

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));


export default function TicketActionButtons({ ticket, contact, gestor }) {

	const { classes } = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);

	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [closeTicketModalOpen, setCloseTicketModalOpen] = useState(false);
	const [uniGestorOpen, setUniGestorOpen] = useState(false);
	const [openContractModal, setOpenContractModal] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [anchorElUni, setAnchorElUni] = useState(null);
	const openUni = Boolean(anchorElUni);

	const handleClickUni = (event) => {
		if (contact && !contact.contract) return setOpenContractModal(true);
		setUniGestorOpen(true);
	};

	const handleCloseUni = () => {
		setAnchorElUni(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
	};

	const handleCloseTransferTicketModal = () => {
		setTransferTicketModalOpen(false);
	};

	const handleCloseFinishModal = () => {
		setCloseTicketModalOpen(false);
	};

	const handleOpenFinishModal = () => {
		//!ticket?.ticketUnideskId ?
		setCloseTicketModalOpen(true)
		//	: setTicketUnideskOpen(true);
	};

	const toastButtons = () => {

		ToastError("Esta função ainda esta desabilitada.")
	};

	const changeTab = () => {
		document.querySelector("#openTab")?.click();
		setTimeout(() => {
			document.querySelector("#openTickets")?.click();
		}, 1000);
	}

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
	};

	const changeTabArchive = () => setTimeout(() => document.querySelector("#clickArchive")?.click(), 500);

	const handleUpdateTicketStatus = async ({
		e,
		status = "",
		userId = null,
		accept = false,
		isPresence = false,
		isArchive = false,
	}) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status,
				userId,
				accept,
				isPresence,
				isArchive
			});
			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.uuid}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			ToastError(err);
		}
	};

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			ToastError(err);
		}
	};

	function getMenuItems(ticket, user) {
		const items = [];

		if (ticket.isReopen || user.profile === "admin") {
			items.push(
				<Tooltip arrow placement="left" title={i18n.t("messagesList.header.buttonsInfo.archive")}>
					<MenuItem onClick={e => {
						handleClose(e);
						handleUpdateTicketStatus({ e, status: "archived", userId: user?.id, accept: false, isPresence: false, isArchive: false });
					}} disableRipple>
						<ArchiveIcon />
						{i18n.t("messagesList.header.buttons.archive")}
					</MenuItem>
				</Tooltip>
			);
		}

		items.push(
			<Tooltip arrow placement="left" title={i18n.t("messagesList.header.buttonsInfo.transfer")}>
				<MenuItem onClick={e => {
					handleClose(e);
					handleOpenTransferModal(e);
				}} disableRipple>
					<ScreenShareIcon />
					{i18n.t("messagesList.header.buttons.transfer")}
				</MenuItem>
			</Tooltip>
		);

		items.push(<Divider sx={{ my: 0.5 }} />);

		if (ticket.status === "open" || ticket.status === "pending") {
			items.push(
				<Tooltip arrow placement="left" title={i18n.t("messagesList.header.buttonsInfo.markupResolved")}>
					<MenuItem onClick={e => {
						handleClose(e);
						handleUpdateTicketStatus({ e, status: "closed", userId: user?.id, accept: true, isPresence: false, isArchive: true })
					}} disableRipple>
						<DoneAllIcon />
						{i18n.t("messagesList.header.buttons.markupResolved")}
					</MenuItem>
				</Tooltip>
			);
		}

		if (ticket.status === "open") {
			items.push(
				<Tooltip arrow placement="left" title={i18n.t("messagesList.header.buttonsInfo.return")}>
					<MenuItem onClick={e => {
						handleClose(e);
						handleUpdateTicketStatus({ e, status: "pending", userId: null, accept: false, isPresence: false, isArchive: false })
					}} disableRipple>
						<KeyboardReturnIcon />
						{i18n.t("messagesList.header.buttons.return")}
					</MenuItem>
				</Tooltip>,
				<Tooltip arrow placement="left" title={ticket.isPresence ? i18n.t("messagesList.header.buttonsInfo.notpresence") : i18n.t("messagesList.header.buttonsInfo.presence")}>
					<MenuItem onClick={e => {
						handleClose(e);
						handleUpdateTicketStatus({ e, status: "open", userId: user?.id, accept: false, isPresence: !ticket.isPresence, isArchive: false });
					}} disableRipple>
						{ticket.isPresence ? <VillaIcon /> : <MyLocationIcon />}
						{ticket.isPresence ? i18n.t("messagesList.header.buttons.notpresence") : i18n.t("messagesList.header.buttons.presence")}
					</MenuItem>
				</Tooltip>
			);
			if (user.profile === "admin") {
				items.push(
					<Tooltip arrow placement="left" title={i18n.t("messagesList.header.buttonsInfo.delete")} >
						<MenuItem onClick={e => { handleClose(e); handleOpenConfirmationModal(e) }} disableRipple>
							<HighlightOffIcon />
							{i18n.t("messagesList.header.buttons.delete")}
						</MenuItem>
					</Tooltip>);
			}
		}

		return items;
	}

	function getMenuItemsUnitech(ticket, user) {
		const items = [];

		items.push(
			<MenuItem
				onClick={() => { toastButtons(); handleCloseUni(); }}
				disableRipple>
				<VpnKeyIcon />
				Gerar Código de Liberação
			</MenuItem>
		);


		items.push(
			<MenuItem
				onClick={() => { toastButtons(); handleCloseUni(); }}
				disableRipple>
				<ReceiptIcon />
				Gerar Boleto para pagamento
			</MenuItem>
		);
		items.push(
			<MenuItem
				onClick={() => { toastButtons(); handleCloseUni(); }}
				disableRipple>
				<QrCode2Icon />
				Gerar PIX QRCODE
			</MenuItem>
		);

		// items.push(<Divider sx={{ my: 0.5 }} />);

		return items;
	}

	function getButtonColor(status) {
		switch (status) {
			case 'pending': return "success";
			case 'closed': return "error";
			default: return "secondary";
		}
	}

	function getButtonLabel(status) {
		switch (status) {
			case "pending": return i18n.t("messagesList.header.buttons.accept");
			case "closed": return i18n.t("messagesList.header.buttons.reopen");
			case "open": return i18n.t("messagesList.header.buttons.resolve");
			default: return i18n.t("messagesList.header.buttons.removeArchiveMsg");
		}
	}

	function handleButtonClick(e) {
		const actions = {
			'pending': () => { handleUpdateTicketStatus({ e, status: "open", userId: user?.id, accept: true, isPresence: false, isArchive: false }); changeTab(); },
			'closed': () => { handleUpdateTicketStatus({ e, status: "open", userId: user?.id, accept: false, isPresence: false, isArchive: false }); changeTab(); },
			'open': handleOpenFinishModal,
			default: () => { handleUpdateTicketStatus({ e, status: "open", userId: user?.id, accept: false, isPresence: false, isArchive: false }); changeTabArchive(); }
		};
		(actions[ticket.status] || actions.default)();
	}

	const handleEditUserContract = async (contractNumber) => {
		try {
			await api.put(`/contacts/${contact.id}`, {
				...contact,
				contract: contractNumber
			});
			ToastSuccess("Número do Contrato adicionado, Tente novamente.");
		} catch (err) {
			ToastError(err);
		}
	};

	return (
		<div style={{ paddingRight: '1%' }}>
			<SelectContractModal
				open={openContractModal}
				onClose={setOpenContractModal}
				onConfirm={handleEditUserContract}
			/>
			<UniGestorModal
				open={uniGestorOpen}
				onClose={setUniGestorOpen}
				onConfirm={console.info}
				ticket={ticket}
				contact={contact}
				gestor={gestor}
			/>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id
					} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name
					}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<TransferTicketPendingModalCustom
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticket={ticket}
			/>
			<ReasonForContact
				open={closeTicketModalOpen}
				onClose={handleCloseFinishModal}
				ticketId={ticket?.id}
			/>
			{+user?.companyId === 2
				?
				<>
					<Tooltip arrow placement="left" title={'Funções do Gestor'}>
						<IconButton
							sx={{ padding: '0px', marginRight: '10px' }}
							variant="outlined"
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							loading={loading}
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClickUni}
						>
							{theme.palette.mode === "light" ?
								<UniChatLight style={{ width: '100%', width: '45px', height: '45px' }} />
								:
								<UniChatDark style={{ width: '100%', width: '45px', height: '45px' }} />
							}
						</IconButton>
					</Tooltip>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
						anchorEl={anchorElUni}
						open={openUni}
						onClose={handleCloseUni}
					>
						{getMenuItemsUnitech(ticket, user)}
					</StyledMenu>

				</>
				:
				null}
			<ButtonGroup size="small">
				<ButtonWithSpinner
					size="small"
					variant="outlined"
					color={getButtonColor(ticket.status)}
					loading={loading}
					onClick={handleButtonClick}
				>
					{getButtonLabel(ticket.status)}
				</ButtonWithSpinner>
				{ticket.status === "archived"
					? <ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="secondary"
						onClick={handleOpenFinishModal}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					: ticket.status !== 'closed' && (
						<ButtonWithSpinner
							color={getButtonColor(ticket.status)}
							size="small"
							variant="contained"
							loading={loading}
							aria-controls={open ? 'split-button-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							onClick={handleClick}
						>
							<KeyboardArrowDownIcon />
						</ButtonWithSpinner>
					)
				}
			</ButtonGroup>

			<StyledMenu
				id="demo-customized-menu"
				MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{getMenuItems(ticket, user)}
			</StyledMenu>
		</div>
	);
}
