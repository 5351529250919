import React, { useContext, useEffect, useReducer, useState } from "react";

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import ConfirmationModal from "../../components/ConfirmationModal";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagsModal from "../../components/TagsModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";

import TableEmptyRows from "../../components/TableEmptyRows";
import TableNoData from "../../components/TableNoData";
import socket from "../../hooks/useSocket";


const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newQueues = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((q) => q.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newQueues.push(tag);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((u) => u.id == tag.id);
    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;
    const tagIndex = state.findIndex((q) => q.id == tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Tags = () => {

  const { classes } = useStyles();

  const [tags, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const { themeStretch } = useSettings();
  const [searchParam, setSearchParam] = useState("");
  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [type, setType] = useState('contacts');
  const { user } = useContext(AuthContext);
  const { companyId } = user;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        dispatch({ type: "RESET" });
        const { data } = await api.get(`/tags`, { params: { type, searchParam } });
        dispatch({ type: "LOAD_TAGS", payload: data });
        setLoading(false);
      } catch (err) {
        ToastError(err);
        setLoading(false);
      }
    })();
  }, [type, searchParam]);

  useEffect(() => {
    socket.on(`tags-${companyId}`, (data) => {
      if ((data.action === "update" || data.action === "create")) {
        dispatch({ type: "UPDATE_TAGS", payload: data.tag });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TAG", payload: data.tagId });
      }
    });

    return () => {
      socket.off(`tags-${companyId}`);
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (tag) => {
    setSelectedQueue(tag);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      ToastSuccess(i18n.t("Tag deleted successfully!"));
    } catch (err) {
      ToastError(err);
    }
    setSelectedQueue(null);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            selectedQueue &&
            `${i18n.t("tags.confirmationModal.deleteTitle")} ${selectedQueue.name
            }?`
          }
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => handleDeleteQueue(selectedQueue.id)}
        >
          {i18n.t("tags.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <TagsModal
          open={queueModalOpen}
          onClose={handleCloseQueueModal}
          tagId={selectedQueue?.id}
          scheduleId={selectedQueue?.scheduleId}
        />
        <Page title={i18n.t("tags.title")}>
          <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading={i18n.t("tags.title")}
              action={
                <>
                  <Button
                    style={{ margin: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleOpenQueueModal}
                  >
                    {i18n.t("tags.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <Box component="div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
                <TextField
                  size="small"
                  type="search"
                  placeholder={i18n.t("tags.search")}
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ maxWidth: '200px', width: '100%' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="tipo-tag">Filtrar por tipo</InputLabel>
                    <Select
                      fullWidth
                      labelId="tipo-tag"
                      id="demo-tipo-tag"
                      value={type}
                      label="Filtrar por tipo"
                      onChange={handleChange}
                    >
                      <MenuItem disabled value="all">Todas</MenuItem>
                      <MenuItem disabled value="tickets">Atendimentos</MenuItem>
                      <MenuItem value="contacts">Contatos</MenuItem>
                      <MenuItem disabled value="schedules">Calendário e Eventos</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {i18n.t("tags.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("tags.table.color")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("tags.table.type")}
                        </TableCell>
                        <TableCell align="right">
                          {i18n.t("tags.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {tags.map((tag) => (
                          <TableRow key={tag.id}>
                            <TableCell align="left">{tag.name}</TableCell>
                            <TableCell align="center">
                              <div className={classes.customTableCell}>
                                <Tooltip title={tag.color}>
                                  <span
                                    style={{
                                      backgroundColor: tag.color,
                                      width: 20,
                                      height: 20,
                                      borderRadius: '10px',
                                      alignSelf: "center",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell align="center">{i18n.t(`tags.types.${tag.type}`)}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="small"
                                onClick={() => handleEditQueue(tag)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={() => {
                                  setSelectedQueue(tag);
                                  setConfirmModalOpen(true);
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableEmptyRows emptyRows={1} height={56} />
                        <TableNoData isNotFound={!!tags && tags.length == 0} />
                        {loading && <TableRowSkeleton columns={3} />}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default Tags;
