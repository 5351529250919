import QRCode from "qrcode.react";
import React, { useContext, useEffect, useState } from "react";

import { Dialog, DialogContent, Paper, Typography } from "@mui/material";
import UniChatGreen from "../../assets/icons/UniChatGreen.png";
import { AuthContext } from "../../context/Auth/AuthContext";
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const [qrCode, setQrCode] = useState("");
	const { user } = useContext(AuthContext);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				ToastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;


		socket.on(`whatsappSession-${user?.companyId}`, data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		});

		return () => {

		};
	}, [whatsAppId, user, onClose]);


	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 750,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const styleQr = {
		width: '230px',
		height: '230px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		borderRadius: '15px',
	}
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="lg"
			scroll="body"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogContent sx={{
				width: '760px',
				height: '325px'
			}} style={{ backgroundColor: "#fff" }}>
				<Paper elevation={0} style={{ ...style, backgroundColor: "#fff" }}>
					{qrCode ? (<>
						<div style={{ display: 'flex' }}>
							<div style={{ margin: '50px 0px 50px 25px' }}>
								<Typography variant="h6" sx={{ color: '#494949' }} gutterBottom>
									Para começar a usar o UniChat:
								</Typography>
								<Typography variant="subtitle2" sx={{ margin: '20px 0px', color: '#494949' }} gutterBottom>
									1 - Abra o whatsapp em seu celular.
								</Typography>
								<Typography variant="subtitle2" sx={{ margin: '20px 0px', color: '#494949' }} gutterBottom>
									2 - Toque em mais opçôes ou configurações e selecione Aparelhos conectados.
								</Typography>
								<Typography variant="subtitle2" sx={{ margin: '20px 0px', color: '#494949' }} gutterBottom>
									3 - Aponte seu celular para essa tela para captura o código.
								</Typography>
							</div>
							<div style={{ padding: '40px 35px 35px 35px' }}>
								<div style={{ ...styleQr }}>
									<div style={{
										backgroundColor: '#fff',
										borderRadius: '5px',

									}}>
										{/*<UniChatLight style={{ filter: 'brightness(0%) contrast(100%)', width: "50px", height: "50px" }} />*/}
										<img src={UniChatGreen} style={{ filter: 'brightness(0%) contrast(100%)', width: "25px", height: "25px" }} />
									</div>
								</div>
								<QRCode value={qrCode} size={230} />
							</div>
						</div>
					</>
					) : (
						<span>Waiting for QR Code</span>
					)}
				</Paper>
			</DialogContent>
		</Dialog >
	);
};

export default React.memo(QrcodeModal);