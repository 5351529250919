import React, { useContext, useEffect, useReducer, useState } from "react";


import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import { makeStyles } from 'tss-react/mui';
import { AuthContext } from "../../context/Auth/AuthContext";
import socket from "../../hooks/useSocket";
import useTickets from "../../hooks/useTickets";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import "./styleTicketList.css";

function hasDifferentId(tickets, ticketsList) {
	const hasIdNotInTicketsList = tickets.some(ticket => {
		return !ticketsList.some(existingTicket => existingTicket.id === ticket.id);
	});
	return hasIdNotInTicketsList;
}

const useStyles = makeStyles()((theme) => {
	return {
		ticketsListWrapper: {
			position: "relative",
			display: "flex",
			height: "100%",
			flexDirection: "column",
			overflow: "hidden",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},

		ticketsList: {
			flex: 1,
			overflowY: "scroll",
			...theme.scrollbarStyles,
			borderTop: "2px solid rgba(0, 0, 0, 0.12)",
		},

		ticketsListHeader: {
			zIndex: 2,
			borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},

		ticketsCount: {
			fontWeight: "normal",
			color: "rgb(104, 121, 146)",
			marginLeft: "8px",
			fontSize: "14px",
		},

		noTicketsText: {
			textAlign: "center",
			color: "rgb(104, 121, 146)",
			fontSize: "14px",
			lineHeight: "1.4",
		},

		noTicketsTitle: {
			textAlign: "center",
			fontSize: "16px",
			fontWeight: "600",
			margin: "0px",
		},

		noTicketsDiv: {
			display: "flex",
			height: "100px",
			margin: 40,
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
	}
});

const reducer = (
	state,
	action
) => {

	let statusTab = action?.status;
	let ticketsOrder = action?.ticketsOrder || "default";

	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0) {
					state.unshift(state.splice(ticketIndex, 1)[0]);
				}
			} else {
				state.push(ticket);
			}
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state[ticketIndex].unreadMessages = 0;
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {

		const ticket = action.payload;

		if (ticket.status !== statusTab) return [...state];

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			state.unshift(ticket);
		}

		return [...state];

	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;
		const tabStatus = action.status;

		const ticketIndex = state.findIndex(t => t.id === ticket.id);

		if (ticketIndex !== -1) {
			if (!!tabStatus && tabStatus !== ticket.status) {
				// Remove o ticket se o status da guia for especificado e for diferente do status do ticket
				state.splice(ticketIndex, 1);
			} else {
				// Atualiza o ticket na lista
				state[ticketIndex] = ticket;

				if (ticket.status === 'pending' && ticketsOrder === 'asc') {
					// Move o ticket para o topo se o status for 'pending' e a ordem for 'asc'
					state.unshift(state.splice(ticketIndex, 1)[0]);
				} else if (ticket.status === 'pending' && ticketsOrder === 'default') {
					// Move o ticket para o final se o status for 'pending' e a ordem for 'default' (DESC)
					state.push(state.splice(ticketIndex, 1)[0]);
				}
			}
		} else {
			if (ticket.status === 'pending' && ticketsOrder === 'asc') {
				// Adiciona o ticket no final se o status for 'pending' e a ordem for 'asc'
				state.push(ticket);
			} else if (!!tabStatus && tabStatus === ticket.status) {
				// Adiciona o ticket no topo se o status da guia for especificado e igual ao status do ticket
				state.unshift(ticket);
			}
		}

		return [...state];
	}


	if (action.type === "UPDATE_TICKET_CONTACT") {

		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);

		if (ticketIndex !== -1) {

			if (contact.status === 'pending' && ticketsOrder === 'default') {
				state[ticketIndex].contact = contact;
			} else {
				state[ticketIndex].contact = contact;
			}
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

let TicketsList = ({
	noQueue,
	companyId,
	status,
	viewTags,
	searchParam,
	showAll,
	selectedQueueIds,
	userTickets,
	reloadCount
}) => {
	const { classes } = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	let [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const { profile, queues } = user;
	const [configs, setConfigs] = useState([]);

	const { tickets, hasMore, loading } = useTickets({
		pageNumber,
		searchParam,
		status,
		userId: userTickets != 'all' ? userTickets : user?.id,
		showAll: showAll || userTickets == 'all',
		queueIds: JSON.stringify(selectedQueueIds),
	});

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [status, searchParam, dispatch, showAll, userTickets, selectedQueueIds]);

	useEffect(() => {
		const fetchTicket = async () => {
			try {
				const { data } = await api.get(`/company/${companyId}`);
				setConfigs(data);
			} catch (err) {
				console.error(err)
			}
		};
		fetchTicket();
	}, [status, searchParam, dispatch, showAll, selectedQueueIds]);


	useEffect(() => {

		if (!status && !searchParam) return;

		const queueIds = queues.map((q) => q.id);
		const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);
		const filteredTicketsSupervisor = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1 || !t.queueId);

		if (userTickets) {

			let filteredTicketsUser;
			if (status === 'pending') {
				filteredTicketsUser = (profile === "user" && !noQueue) ? tickets.filter(obj => selectedQueueIds.includes(obj.queueId)) : tickets;
			} else {
				filteredTicketsUser = showAll || userTickets == 'all' ? tickets : tickets.filter((t) => t?.userId != null && t?.userId == userTickets);
			};


			return dispatch({ type: "LOAD_TICKETS", payload: filteredTicketsUser, ticketsOrder: configs.ticketsOrder, status });
		}

		if (noQueue) {
			return dispatch({ type: "LOAD_TICKETS", payload: tickets, ticketsOrder: configs.ticketsOrder, status });
		} else {
			switch (profile) {
				case "user":
					return dispatch({ type: "LOAD_TICKETS", payload: filteredTickets, ticketsOrder: configs.ticketsOrder, status });
				case "supervisor":
					return dispatch({ type: "LOAD_TICKETS", payload: filteredTicketsSupervisor, ticketsOrder: configs.ticketsOrder, status });
				default:
					return dispatch({ type: "LOAD_TICKETS", payload: tickets, ticketsOrder: configs.ticketsOrder, status });
			}
		}

	}, [tickets, status, searchParam, userTickets, queues, profile]);

	useEffect(() => {

		if (!companyId) return;

		const userCompany = companyId;

		const shouldUpdateTicket = (ticket) =>
			(!ticket?.userId || (userTickets != "all" ? +userTickets == +ticket?.userId : +ticket?.userId == +user?.id) || (showAll || userTickets == 'all')) &&
			(!ticket?.queueId || selectedQueueIds?.indexOf(ticket?.queueId) > -1);

		const notBelongsToUserQueues = (ticket) =>
			ticket?.queueId && selectedQueueIds?.indexOf(ticket?.queueId) === -1;

		// Registrar eventos dinamicamente

		socket.on("connect", () => {
			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});


		socket.on(`ticket-${userCompany}`, (data) => {
			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
					ticketsOrder: data?.findCompany?.ticketsOrder || "default",
					status,
				});
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
					ticketsOrder: data?.findCompany?.ticketsOrder || "default",
					status,
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
				dispatch({
					type: "DELETE_TICKET",
					payload: data.ticket.id,
					ticketsOrder: data?.findCompany?.ticketsOrder || "default",
					status,
				});
			}

			if (data.action === "delete") {
				dispatch({
					type: "DELETE_TICKET",
					payload: data.ticketId,
					status,
				});
			}
		});


		socket.on(`appMessage-${userCompany}`, (data) => {
			const queueIds = queues.map((q) => q.id);
			if (
				profile === "user" &&
				(queueIds?.indexOf(data?.ticket?.queue?.id) === -1 ||
					data?.ticket?.queue === null)
			)
				return;
			if (
				data?.action === "create" &&
				shouldUpdateTicket(data?.ticket) &&
				companyId == data?.ticket?.whatsapp?.companyId
			) {
				if (
					userTickets && user?.id !== data?.ticket?.userId && ((!showAll || userTickets != 'all') && data?.ticket?.status != "pending")
				) {
					return;
				}
				if (
					userTickets && userTickets !== data?.ticket?.userId && ((!showAll || userTickets != 'all') && data?.ticket?.status != "pending")
				) {
					return;
				}
				let ticketAppMessage = { ...data.ticket };
				ticketAppMessage.lastMessage =
					data?.message?.body || ticketAppMessage?.lastMessage || "";
				ticketAppMessage.updatedAt =
					data?.message?.updatedAt ||
					ticketAppMessage?.updatedAt ||
					new Date().toISOString();
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: ticketAppMessage,
					ticketsOrder: data?.findCompany?.ticketsOrder || "default",
					status,
				});
			}
		});


		socket.on("contact", (data) => {
			if (data.action === "update") {
				let filteredContacts = data.contact;
				filteredContacts = {
					...filteredContacts,
					status: data.status,
					createdContact: true,
				};
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: filteredContacts,
					ticketsOrder: data?.findCompany?.ticketsOrder || "default",
					status,
				});
			}
		});

		// Remover eventos quando o componente for desmontado
		return () => {
			// Este arquivo em especifico, não pode perder seus ouvintes, eles precisam se manter ativos enquanto o usuario atende.

			// socket.off(`ticket-${userCompany}`);
			// socket.off("connect");
			// socket.off("contact");
			// socket.off(`appMessage-${userCompany}`);

		};
	}, [status, showAll, user, userTickets, selectedQueueIds, profile, queues]);


	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	// Filtrar ticket com base na exibição de filas 
	if (!noQueue) {
		ticketsList = ticketsList.filter(ticket => ticket.queueId !== null || ticket.userId === userTickets);
	}

	// Filtrar tickets com base no status, se fornecido
	if (status) {
		ticketsList = ticketsList.filter(ticket => ticket.status === status);
	}

	// Filtrar tickets com base nas filas selecionadas, se houver alguma
	if (selectedQueueIds.length > 0) {
		ticketsList = !!status ? ticketsList.filter(ticket => selectedQueueIds.includes(ticket.queueId) || ticket.queueId === null) : ticketsList;
	}


	// Filtrar tickets adicionais se o status for 'open' e o usuário não for 'all'
	if (status === 'open' && userTickets !== 'all') {
		ticketsList = ticketsList.filter(ticket => ticket.userId == userTickets);
	}

	useEffect(() => {
		if (!status) return;
		const conterTickets = ticketsList?.length || 0
		reloadCount(conterTickets)
	}, [ticketsList, status]);

	return (
		<div className={classes.ticketsListWrapper}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{ticketsList?.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) : (
						<>
							{ticketsList.map(ticket => (
								<TicketListItem showAll={(showAll || userTickets == 'all')} ticket={ticket} key={ticket.id} viewTags={viewTags} />
							))}
						</>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>
		</div>
	);
};

export default TicketsList;
