import React, { useState, useContext, useEffect } from "react";
// @mui
import { alpha, styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const AvatarPresence = styled(Badge)(({ theme, status }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: status === 1 ? '#44b700' : status === 2 ? '#ffc800' : '#ff4545',
        color: status === 1 ? '#44b700' : status === 2 ? '#ffc800' : '#ff4545',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export default AvatarPresence;