function FormatarTelefone(telefone) {
    let numeroLimpo = telefone.replace(/\D/g, '').substring(2);

    if (numeroLimpo.length < 10 || numeroLimpo.length > 11) return numeroLimpo;

    const codigoPais = '+55';
    const ddd = numeroLimpo.substr(0, 2);
    let primeiraParte = '';
    let segundaParte = '';

    if (numeroLimpo.length === 10) {
        primeiraParte = numeroLimpo.substr(2, 4);
        segundaParte = numeroLimpo.substr(numeroLimpo.length - 4);
    } else {
        primeiraParte = numeroLimpo.substr(2, 5);
        segundaParte = numeroLimpo.substr(numeroLimpo.length - 4);
    }

    if (numeroLimpo.length === 11 && ['9', '8', '7', '6'].includes(numeroLimpo[2])) {
        return `${codigoPais} (${ddd}) ${primeiraParte}-${segundaParte}`;
    } else {
        return `${codigoPais} (${ddd}) ${primeiraParte}-${segundaParte}`;
    }
}

export default FormatarTelefone;