import { useEffect, useRef } from 'react';
import socket from '../useSocket';

const KeepSocketAlive = ({ companyId, userId }) => {

  const pongTimeoutRef = useRef(null);

  useEffect(() => {
    const pingInterval = setInterval(() => {
      socket.emit('ping', { userId });
      pongTimeoutRef.current = setTimeout(() => {
        console.debug("[SOCKET]: Pong enviado para o backend")
      }, 60000);
    }, 60000);

    socket.on(`pong-${userId}`, () => {
      console.debug("[SOCKET]: Pong respondido pelo backend")
      clearTimeout(pongTimeoutRef.current);
    });

    socket.on('reconnect', () => {
      clearTimeout(pongTimeoutRef.current);
    });

    return () => {
      socket.off(`pong-${userId}`)
      clearInterval(pingInterval);
      clearTimeout(pongTimeoutRef.current);
    };
  }, [socket, userId]);

  return null;
};

export default KeepSocketAlive;
