import SendIcon from '@mui/icons-material/Send';
import { Box, CircularProgress, IconButton, List, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import api from "../../services/api";
import CustomListItem from '../CustomListItem';
import EmojiPicker from '../EmojiPicker';

const KanbanCardCommentsSection = ({ card, assigneds }) => {
    const [commentText, setCommentText] = useState('');
    const commentTextRef = useRef('');
    const [loading, setLoading] = useState(false);
    const [commentsArray, setCommentsArray] = useState([]);
    const listRef = useRef(null);

    useEffect(() => {
        setCommentsArray(card.comments);
    }, [card.comments]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [commentsArray]);

    const handleSendMessage = async () => {
        if (!commentTextRef.current || commentTextRef.current.length === 0) return;
        try {
            setLoading(true);
            const { data } = await api.post(`/kanban/comment/${card.id}`, {
                text: commentTextRef.current
            });
            setCommentText(''); // Limpa o estado visível
            commentTextRef.current = ''; // Limpa o valor temporário
            setCommentsArray(prev => [...prev, data]);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Atualiza o ref enquanto digita para evitar renderizações
    const handleInputChange = (e) => {
        commentTextRef.current = e.target.value;
        setCommentText(e.target.value); // Atualiza o valor visível no campo
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                bgcolor: (theme) => theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9',
                boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
            }}
        >
            <List
                ref={listRef}
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: 3,
                    paddingBottom: 1,
                    '&::-webkit-scrollbar': {
                        width: '6px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        bgcolor: (theme) => theme.palette.mode === 'dark' ? '#555' : '#c1c1c1',
                        borderRadius: 2
                    }
                }}
            >
                {commentsArray?.map((comment, index) => (
                    <CustomListItem key={index} comment={comment} divider={comment?.length === index} />
                ))}
            </List>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: 1,
                    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#2f3641' : '#ffffff',
                    padding: '5px 10px',
                    borderRadius: 1,
                    boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
                    position: 'relative',
                }}
            >
                <EmojiPicker
                    id="emojiPicker"
                    disabled={loading}
                    value={''}
                    pickerSx={{ marginLeft: '30px' }}
                    setValue={emoji => {
                        commentTextRef.current += emoji;
                        setCommentText(commentTextRef.current); // Atualiza o valor exibido
                    }}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    disabled={loading}
                    multiline
                    fullWidth
                    placeholder="Digite seu comentário..."
                    value={commentText}
                    onChange={handleInputChange}
                    maxRows={4}
                    sx={{
                        bgcolor: (theme) => theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9',
                        borderRadius: 1,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent'
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) => theme.palette.mode === 'dark' ? '#5e6c84' : '#c1c1c1'
                            },
                        }
                    }}
                />
                <IconButton
                    disabled={loading || !commentTextRef.current}
                    onClick={handleSendMessage}
                >
                    {loading ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        <SendIcon />
                    )}
                </IconButton>
            </Box>
        </Box>
    );
};

export default KanbanCardCommentsSection;
