import { Box } from '@mui/material';
import React, { useState } from 'react';
import KanbanCardCommentsSection from '../KanbanCardCommentsSection';
import KanbanCardDetailSection from '../KanbanCardDetailSection';
import KanbanCardTabs from '../KanbanCardTabs';
import KanbanCardTasksSection from '../KanbanCardTasksSection';

export default function KanbanDetails({ card, priorities, assigneds }) {
    const [tabView, setTabView] = useState(0);

    return (
        <Box sx={{
            display: 'flex',
            height: '100%',
            maxHeight: `calc(100% - ${tabView == 2 ? "70px" : '0px'} )`,
            flexDirection: 'column',
            gap: theme => theme.spacing(3),
            backgroundColor: theme => theme.palette.mode === 'dark' ? '#212b3661' : '#ffffff',
            //     borderRadius: theme => theme.shape.borderRadius,
            boxShadow: theme => theme.shadows[3],
        }}
        >
            <KanbanCardTabs value={tabView} onChange={setTabView} />
            {tabView === 0 && (
                <KanbanCardDetailSection card={card} priorities={priorities} assigneds={assigneds} />
            )}
            {tabView === 1 && (
                <Box sx={{ padding: (theme) => theme.spacing(3, 2.5, 5), gap: (theme) => theme.spacing(3) }}>
                    <KanbanCardTasksSection card={card} assigneds={assigneds} />
                </Box>
            )}
            {tabView === 2 && (
                <KanbanCardCommentsSection card={card} assigneds={assigneds} />
            )}
        </Box>
    );
}