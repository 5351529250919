import { Box, Grid, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";

const filter = createFilterOptions();

const SelectPropsScheduleMessagesModal = ({ modalOpen, onClose, onConfirm, initialContact = {} }) => {
	const [contactOptions, setContactOptions] = useState([]);
	const [whatsappOptions, setWhatsappOptions] = useState([]);
	const [loadingContacts, setLoadingContacts] = useState(false);
	const [loadingWhatsapps, setLoadingWhatsapps] = useState(false);
	const [searchContactParam, setSearchContactParam] = useState("");
	const [searchWhatsappParam, setSearchWhatsappParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (!modalOpen || searchContactParam.length < 3) {
			setLoadingContacts(false);
			return;
		}
		setLoadingContacts(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("listContacts", {
						params: { searchParam: searchContactParam },
					});
					setContactOptions(data.contacts);
				} catch (err) {
					ToastError(err);
				} finally {
					setLoadingContacts(false);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchContactParam, modalOpen]);

	useEffect(() => {
		const fetchWhatsapps = async () => {
			try {
				const { data } = await api.get('/whatsapp');
				setWhatsappOptions(data);
			} catch (err) {
				ToastError(err);
			} finally {
				setLoadingWhatsapps(false);
			}
		};

		if (modalOpen) {
			setLoadingWhatsapps(true);
			fetchWhatsapps();
		}
	}, [modalOpen]);

	const handleClose = (selected) => {
		if (selected) {
			onConfirm({ contact: selectedContact, whatsapp: selectedWhatsapp });
		} else {
			onClose();
		}
		setSearchContactParam("");
		setSearchWhatsappParam("");
		setSelectedContact(null);
		setSelectedWhatsapp(null);
	};

	const handleSelectContact = (event, newValue) => {
		setSelectedContact(newValue);
	};

	const handleSelectWhatsapp = (event, newValue) => {
		setSelectedWhatsapp(newValue);
	};

	const renderContactAutocomplete = () => (
		<Grid xs={12} item>
			<Autocomplete
				options={contactOptions}
				loading={loadingContacts}
				onChange={handleSelectContact}
				filterOptions={(options, { inputValue }) => {
					const filtered = options.filter(option => {
						const nameMatches = option.name.toLowerCase().includes(inputValue.toLowerCase());
						const numberMatches = option.number && option.number.toString().includes(inputValue);
						return nameMatches || numberMatches;
					});
					return filtered;
				}}
				clearOnBlur
				autoHighlight
				getOptionLabel={(option) => `${option.name} - ${option.number || ''}`}
				renderOption={(props, option) => (
					<li {...props}>
						<Box>
							<Typography>{option.name}</Typography>
							<Typography variant="caption" display="block" gutterBottom>
								{option?.number ? option.number.length > 13 ? "Grupo do Whatsapp" : option.number : "Sem Número"}
							</Typography>
						</Box>
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={"Pesquise e selecione o contato"}
						variant="outlined"
						onChange={e => setSearchContactParam(e.target.value)}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingContacts ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</Grid>
	);

	const renderWhatsappAutocomplete = () => (
		<Grid xs={12} item>
			<Autocomplete
				options={whatsappOptions}
				loading={loadingWhatsapps}
				onChange={handleSelectWhatsapp}
				filterOptions={(options, { inputValue }) => {
					const filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
					return filtered;
				}}
				clearOnBlur
				autoHighlight
				getOptionLabel={(option) => option.name}
				renderOption={(props, option) => {
					// Determine the color of the status dot
					const isConnected = option.status === "CONNECTED";
					const statusColor = isConnected ? "green" : "red";

					return (
						<li {...props}>
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
								<Box
									sx={{
										width: 12,
										height: 12,
										borderRadius: "50%",
										backgroundColor: statusColor,
										marginRight: '10px',
									}}
								/>
								<Typography>{option.name}</Typography>
							</Box>
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Selecione a conexão"
						variant="outlined"
						onChange={e => setSearchWhatsappParam(e.target.value)}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingWhatsapps ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</Grid>
	);

	return (
		<Dialog
			open={modalOpen}
			onClose={handleClose}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "400px",
					},
				},
			}}>
			<DialogTitle id="form-dialog-title">Agendamento de mensagem</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					{renderContactAutocomplete()}
					{renderWhatsappAutocomplete()}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary" variant="outlined" disabled={loadingContacts || loadingWhatsapps}>
					{i18n.t("newTicketModal.buttons.cancel")}
				</Button>
				<ButtonWithSpinner
					variant="contained"
					type="button"
					disabled={!selectedContact || !selectedWhatsapp || selectedWhatsapp.status !== "CONNECTED"}
					onClick={() => { handleClose(true) }}
					color="primary"
					loading={loadingContacts || loadingWhatsapps}
				>
					{i18n.t("newTicketModal.buttons.ok")}
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default SelectPropsScheduleMessagesModal;
