import { TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask: '000.000.000-00',
                    maxLength: 11,
                },
                {
                    mask: '00.000.000/0000-00',
                    maxLength: 18,
                },
            ]}
            lazy={false}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextCEPMaskCustom = React.forwardRef(function TextCEPMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask: '00000-000',
                    maxLength: 9,
                },
            ]}
            lazy={false}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

// Novo componente para máscara de RG
const TextRgMaskCustom = React.forwardRef(function TextRgMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask: '00.000.000-0',
                    maxLength: 12,
                },
            ]}
            lazy={false}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const CpfCnpjMask = (props) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextMaskCustom,
            }}
        />
    );
};

const CEPMask = (props) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextCEPMaskCustom,
            }}
        />
    );
};

const RgMask = (props) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextRgMaskCustom,
            }}
        />
    );
};

export { CpfCnpjMask, CEPMask, RgMask };
