import React from 'react';
import { createRoot } from 'react-dom/client';

import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'simplebar/src/simplebar.css';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

// contexts
import App from './App';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';

const rootElement = document.getElementById('root');

if (rootElement) {
	createRoot(rootElement).render(
		<BrowserRouter>
			<HelmetProvider>
				<SettingsProvider>
					<CollapseDrawerProvider>
						<App />
					</CollapseDrawerProvider>
				</SettingsProvider>
			</HelmetProvider>
		</BrowserRouter>
	);
} else {
	console.error('Elemento root não encontrado. Certifique-se de que há um elemento com o id "root" no seu HTML.');
}
