import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import Checkbox from '@mui/material/Checkbox';
import { parseISO, setHours, setMinutes } from "date-fns";
import DropZoneUpload from "../DropZoneUpload";

const CustomDialogTitle = styled(DialogTitle)({
	color: "#fff",
	textAlign: "center",
});

const CustomDialogActions = styled(DialogActions)({
	justifyContent: "center",
	padding: "16px 24px",
});

const CustomButton = styled(Button)({
	color: "#fff",
});

const days = [
	{ label: "Domingo", key: "sunday" },
	{ label: "Segunda", key: "monday" },
	{ label: "Terça", key: "tuesday" },
	{ label: "Quarta", key: "wednesday" },
	{ label: "Quinta", key: "thursday" },
	{ label: "Sexta", key: "friday" },
	{ label: "Sábado", key: "saturday" },
];

const ScheduleMessageModal = ({ inMessage, scheduleMessageId = null, ticketId = null, contactId = null, whatsappId = null, open, onClose, onConfirm }) => {
	const [sendAt, setSendAt] = useState(new Date());
	const [stopAt, setStopAt] = useState(sendAt || new Date());
	const [time, setTime] = useState(new Date());
	const [message, setMessage] = useState("");
	const [recurrence, setRecurrence] = useState('ONCE');
	const [numberOfRecurrence, setNumberOfRecurrence] = useState(1);
	const [finishOn, setFinishOn] = useState('never');
	const [useRecurrence, setUseRecurrence] = useState(false);
	const [contact, setContact] = useState(null);
	const [sendDays, setSendDays] = useState({ sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false });
	const [status, setStatus] = useState('await');
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);


	const handleClose = () => {
		setSendAt(new Date());
		setStopAt(new Date());
		setTime(new Date());
		setMessage("");
		setRecurrence('ONCE');
		setNumberOfRecurrence(1);
		setFinishOn('never');
		setUseRecurrence(false);
		setContact(null);
		setSendDays({ sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false });
		setStatus('await');
		setLoading(false);
		setFiles([]);
		onClose(false);
	};


	const handleDrop = useCallback(
		(acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);

			setFiles([...files, ...newFiles]);
		},
		[files]
	);

	const handleRemoveFile = (inputFile) => {
		const filtered = files.filter((file) => file !== inputFile);
		setFiles(filtered);
	};


	const getContact = async (contactId) => {
		if (contactId) {
			setLoading(true); // Iniciar loading
			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				setContact(data);
			} catch (error) {
				console.error("Error fetching contact:", error);
				ToastError("Erro ao buscar contato.");
			} finally {
				setLoading(false); // Encerrar loading
			}
		}
	};

	useEffect(() => {
		getContact(contactId);
	}, [contactId]);

	useEffect(() => {
		if (scheduleMessageId && open) {
			(async () => {
				setLoading(true); // Iniciar loading
				try {
					const { data } = await api.get(`/scheduleMessage/${scheduleMessageId}`);

					setSendAt(parseISO(data.sendAt));

					if (data.stopAt) {
						setStopAt(parseISO(data.stopAt));
					}

					if (data.time) {
						const [hours, minutes] = data.time.split(':');
						const currentDate = new Date();
						const updatedDate = setHours(setMinutes(currentDate, parseInt(minutes, 10)), parseInt(hours, 10));
						setTime(updatedDate);
					}

					if (data?.fileBlob && data?.mediaUrl) {
						setFiles([])
						const fileName = data.mediaUrl.split("/").pop();
						const fileBlob = data.fileBlob;

						const newFile = new File([fileBlob], fileName, { type: fileBlob.type });

						const newFileWithPreview = Object.assign(newFile, {
							preview: URL.createObjectURL(newFile),
						});

						setFiles([...files, newFileWithPreview]);
					}

					setStatus(data.status);
					setMessage(data.message);
					setRecurrence(data.recurrence);
					setNumberOfRecurrence(data.numberOfRecurrence);
					setFinishOn(data.finishOn);
					setUseRecurrence(data.finishOn !== 'ONCE');
					setSendDays(JSON.parse(data.sendDays));

					if (data.contact) {
						getContact(data.contact.id);
					}
				} catch (error) {
					console.error("Error fetching schedule message:", error);
					ToastError("Erro ao buscar mensagem agendada.");
				} finally {
					setLoading(false); // Encerrar loading
				}
			})();
		}
	}, [scheduleMessageId, open]);

	useEffect(() => {
		const currentDay = new Date().getDay();
		const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		setSendDays((prevDays) => ({
			...prevDays,
			[dayMap[currentDay]]: true,
		}));
	}, []);

	const handleChangeFinishOn = (event) => {
		setFinishOn(event.target.value);
	};

	const handleChangeRecurrence = (event) => {
		setRecurrence(event.target.value);
	};

	const handleDayChange = (day) => {
		setSendDays((prevState) => ({
			...prevState,
			[day]: !prevState[day],
		}));
	};

	const handleChangeUseRecurrence = (event) => {
		setUseRecurrence(event.target.checked);
	};

	useEffect(() => {
		if (open) {
			const now = new Date();
			setSendAt(now);
			setTime(now);
		}
	}, [open]);

	const handleConfirm = async () => {
		if (!sendAt || !time || !message) return ToastError("Preencha todos os campos");

		const formData = new FormData();

		if (files.length > 0) { formData.append("medias", files[0]); };
		if (finishOn === 'date') { formData.append("stopAt", stopAt.toISOString()); };
		if (ticketId) { formData.append("ticketId", ticketId); };
		if (whatsappId) { formData.append("connectionId", whatsappId); };
		if (contactId) { formData.append("contactId", contactId); };
		if (useRecurrence) { formData.append("recurrence", recurrence); formData.append("numberOfRecurrence", numberOfRecurrence); };

		formData.append("finishOn", finishOn);
		formData.append("body", message || file?.name);
		formData.append("sendAt", sendAt.toISOString());
		formData.append("time", time.toTimeString().split(' ')[0]);
		formData.append("sendDays", JSON.stringify(sendDays));

		setLoading(true); // Iniciar loading
		try {
			const endpoint = scheduleMessageId ? `/scheduleMessages/${scheduleMessageId}` : `/scheduleMessages`;
			const method = scheduleMessageId ? api.put : api.post;
			const data = await method(endpoint, formData);
			onConfirm(data);
			handleClose();
		} catch (err) {
			console.error("Error scheduling message:", err);
			ToastError("Erro ao agendar mensagem.");
		} finally {
			setLoading(false); // Encerrar loading
		}
	};

	return (
		<Dialog open={open} onClose={() => handleClose()} aria-labelledby="schedules-dialog">
			<CustomDialogTitle id="schedules-dialog">{contact?.name ? `Agendamento para ${contact.name}` : `Agendar Mensagem`}</CustomDialogTitle>
			<DialogContent>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
					<Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, paddingTop: '10px' }}>
						<DatePicker
							disabled={status != 'await' || inMessage}
							label={recurrence ? "Data inicial" : "Data"}
							value={sendAt}
							onChange={(newValue) => setSendAt(newValue)}
							format="dd/MM/yyyy"
							minDate={new Date()}
							slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
						/>
						<TimePicker
							disabled={status != 'await' || inMessage}
							label="Horário"
							value={time}
							onChange={(newValue) => setTime(newValue)}
							ampm={false}
							slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
						/>
					</Box>
				</LocalizationProvider>

				<Box>
					<Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, paddingTop: '10px' }}>
						<Box sx={{ display: 'flex', width: '100%', alignItems: 'center', padding: '6px 0px' }}>
							<Checkbox
								disabled={status != 'await' || inMessage}
								checked={useRecurrence}
								onChange={handleChangeUseRecurrence}
								icon={<CheckBoxOutlineBlankIcon />}
								checkedIcon={<RepeatOnIcon />}
							/>
							<Typography>Customizar recorrência do calendário</Typography>
						</Box>
					</Box>
				</Box>
				{useRecurrence && (
					<>
						<Divider>Configurações de recorrência de envio</Divider>
						<Box
							sx={{
								display: 'flex',
								gap: 2,
								flexDirection: { xs: 'column', sm: 'row' },
								paddingTop: '10px',
							}}
						>
							<Box sx={{ flex: 1 }}>
								<Typography variant="body2">Repetir:</Typography>
								<Box sx={{ width: { xs: '100%', sm: '100%' }, display: 'flex' }}>
									<FormControl sx={{ width: '100%', margin: '0px' }} size="small">
										<Select
											disabled={status != 'await' || inMessage}
											labelId="recurrence-select"
											id="recurrence-select"
											size="small"
											sx={{ borderRadius: '10px' }}
											value={recurrence}
											onChange={handleChangeRecurrence}
										>
											<MenuItem value={'ONCE'}>Não repetir</MenuItem>
											<MenuItem value={'WEEKLY'}>Dias da semana</MenuItem>
											<MenuItem value={'MONTHLY'}>Mensalmente</MenuItem>
											<MenuItem value={'ANNUALLY'}>Anualmente</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Box>
							<Box sx={{ flex: 1 }}>
								<Typography variant="body2" >Finalizar após:</Typography>
								<Box sx={{ width: { xs: '100%', sm: '100%' }, display: 'flex' }}>
									<FormControl sx={{ width: finishOn === 'recurrence' ? '60%' : finishOn === 'date' ? '70%' : '100%', margin: '0px' }} size="small">
										<Select
											disabled={recurrence === 'ONCE' || status != 'await'}
											labelId="frequency-select"
											id="frequency-select"
											size="small"
											sx={{ borderRadius: finishOn !== 'never' ? '10px 0px 0px 10px' : '10px' }}
											value={finishOn}
											onChange={handleChangeFinishOn}
										>
											<MenuItem value={'never'}>Nunca</MenuItem>
											<MenuItem value={'date'}>Na data</MenuItem>
											<MenuItem value={'recurrence'}>Recorrências</MenuItem>
										</Select>
									</FormControl>
									{finishOn === 'date' && (
										<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
											<DatePicker
												value={stopAt}
												onChange={(newValue) => setStopAt(newValue)}
												format="dd/MM/yyyy"
												minDate={sendAt || new Date()}
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '0px 10px 10px 0px'
													},
													'& .MuiInputBase-root': {
														borderRadius: '0px 10px 10px 0px'
													}
												}}

												slotProps={{ textField: { size: 'small', className: "myDatePicker", fullWidth: true, margin: '0px' } }}
											/>
										</LocalizationProvider>
									)}
									{finishOn === 'recurrence' && (
										<Box sx={{ display: 'flex', width: '40%' }}>
											<TextField
												type="number"
												size="small"
												sx={{ margin: '0px', borderRadius: '0px 10px 10px 0px' }}
												InputProps={{
													inputProps: { min: 0, max: 10 },
													sx: { borderRadius: '0px 10px 10px 0px' },
												}}
												value={numberOfRecurrence}
												onChange={e => setNumberOfRecurrence(e.target.value)}
												margin="normal"
												fullWidth
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, paddingTop: '10px' }}>
							<Box sx={{ flex: 1 }}>
								{(recurrence === "DAILY" || recurrence === "WEEKLY") && (
									<>
										<Typography variant="body2">Repetir nos dias:</Typography>
										<Box sx={{ width: { xs: '100%', sm: '100%' }, display: 'flex' }}>
											<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												{days.map((day) => (
													<Tooltip key={day.key} title={day.label}>
														<Checkbox
															disabled={status != 'await'}
															sx={{ padding: '2px' }}
															icon={<Chip label={day.label.charAt(0)} size="small" variant="outlined" />}
															checkedIcon={<Chip label={day.label.charAt(0)} size="small" color="success" />}
															checked={sendDays[day.key]}
															onChange={() => handleDayChange(day.key)}
														/>
													</Tooltip>
												))}
											</Box>
										</Box>
									</>
								)}
							</Box>
						</Box>
					</>
				)}
				<TextField
					label="Descrição"
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					fullWidth
					margin="dense"
					multiline
					rows={4}
					size="small"
				/>
				<DropZoneUpload
					multiple
					files={files}
					onDrop={handleDrop}
					onRemove={handleRemoveFile}
				/>
			</DialogContent>
			<CustomDialogActions>
				<Button onClick={() => handleClose()} color="error" variant="outlined" size="small" disabled={loading}>
					Cancelar
				</Button>
				<Button onClick={handleConfirm} color="primary" variant="contained" size="small" disabled={loading}>
					{loading ? "Carregando..." : "Confirmar"}
				</Button>
			</CustomDialogActions>
		</Dialog>
	);
};

export default ScheduleMessageModal;
