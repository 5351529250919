import { useState, useEffect } from 'react';
import ToastError from '../../toast/error/toastError';
import api from '../../services/api';
import useIsTabFocused from '../useIsTabFocused'; // Importe o novo hook

const useTickets = ({
	searchParam,
	pageNumber,
	status,
	userId,
	date,
	updatedAt,
	showAll,
	queueIds,
	withUnreadMessages,
}) => {


	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [totalTickets, setTotalTickets] = useState(0);

	useEffect(() => {
		const fetchTickets = async () => {
			try {
				setLoading(true);
				const { data } = await api.get('/tickets', {
					params: {
						searchParam,
						pageNumber,
						status,
						userId,
						date,
						updatedAt,
						showAll,
						queueIds,
						withUnreadMessages,
					},
				});
				setTotalTickets(data.count)
				setTickets(data.tickets);
				setHasMore(data.hasMore);
			} catch (err) {
				ToastError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchTickets();
		const intervalId = setInterval(() => {
			fetchTickets();
		}, 60000);

		return () => clearInterval(intervalId);
	}, [
		searchParam,
		pageNumber,
		status,
		userId,
		date,
		updatedAt,
		showAll,
		queueIds,
		withUnreadMessages
	]);

	return { tickets, loading, hasMore, totalTickets };
};

export default useTickets;