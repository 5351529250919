import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { green } from "@mui/material/colors";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import ConfirmationModal from "../ConfirmationModal";

import { i18n } from "../../translate/i18n";

import { Typography } from "@mui/material";
import api from "../../services/api";

import { AuthContext } from "../../context/Auth/AuthContext";
import ToastError from "../../toast/error/toastError";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},

	}
});

const ReasonForContact = ({ open, onClose, ticketId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationTypeIsGroup, setConfirmationTypeIsGroup] = useState(false);
	const { user: loggedInUser } = useContext(AuthContext);
	const { classes } = useStyles();

	const handleClose = () => {
		onClose();
	};

	const handleCloseTicket = async () => {
		setIsLoading(true)
		try {
			await api.put(`/tickets/${ticketId}`, {
				status: 'closed',
				userId: loggedInUser.id,
				reason: '',
				wasResolved: 1,
				force: false
			});
			history.push("/tickets");
		} catch (err) {
			const errorMsg = err.response?.data?.error;
			console.error(errorMsg)
			if (errorMsg == "ERR_SENDING_WAPP_MSG_GROUP_FORBIDDEN" || errorMsg == "ERR_SENDING_WAPP_MSG_USER_FORBIDDEN") {
				setConfirmationTypeIsGroup(`${errorMsg === "ERR_SENDING_WAPP_MSG_GROUP_FORBIDDEN" ? true : false} `)
				setTimeout(() => setConfirmationOpen(true), 100);
			}
			ToastError(err);
		}
		setIsLoading(false)
		handleClose();
	};

	const handleCloseTicketForce = async () => {
		setIsLoading(true)
		try {
			await api.put(`/tickets/${ticketId}`, {
				status: 'closed',
				userId: loggedInUser.id,
				reason: '',
				wasResolved: 1,
				force: true
			});
			history.push("/tickets");
		} catch (err) {
			const errorMsg = err.response?.data?.error;
			console.error(errorMsg)
			ToastError(err);
		}
		setIsLoading(false)
		handleClose();
	};

	return (
		<div className={classes.root}>
			<ConfirmationModal
				title={`${confirmationTypeIsGroup ? i18n.t(`ticketOptionsMenu.confirmationModalForce.titleGroup`) : i18n.t(`ticketOptionsMenu.confirmationModalForce.titleUser`)} `}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleCloseTicketForce}
				force={true}
			>
				{i18n.t("ticketOptionsMenu.confirmationModalForce.message")}
			</ConfirmationModal>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle>
					{i18n.t("userType.translate.finishTicket")}
				</DialogTitle>

				<DialogContent>
					<Typography style={{ marginTop: 16 }}>
						Deseja finalizar o atendimento?
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={isLoading}
						variant="outlined"
					>
						{i18n.t("queueModal.buttons.cancel")}
					</Button>
					<Button
						type="submit"
						color="primary"
						disabled={isLoading}
						variant="contained"
						className={classes.btnWrapper}
						onClick={handleCloseTicket}
					>
						{i18n.t("userType.translate.finish")}
						{isLoading && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ReasonForContact;
