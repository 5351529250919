import React from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import tinycolor from 'tinycolor2';
import isColorDark from '../../utils/isColorDark';

// Função que intensifica cores suaves para uma versão mais forte
const intensifyColor = (color) => {
    const colorObj = tinycolor(color);

    // Verifica se a cor é suave, considerando um limite de 65% de luminosidade
    if (colorObj.getLuminance() > 0.65) {
        // Escurece a cor em 30%
        return colorObj.darken(30).toHexString();
    }

    // Caso a cor já seja forte, mantemos a original
    return color;
};

// Função que gera um número aleatório entre 1 e 5
const getRandomCount = () => Math.floor(Math.random() * 3) + 1;

function lightenToPastel(hex, amount = 0.7) {
    // Remove o "#" se estiver presente
    hex = hex.replace('#', '');

    // Divide a cor em componentes R, G e B
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calcula a nova cor interpolando cada componente com branco (255)
    const newR = Math.round(r + (255 - r) * amount);
    const newG = Math.round(g + (255 - g) * amount);
    const newB = Math.round(b + (255 - b) * amount);

    // Retorna a nova cor em hexadecimal
    return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
}

// Lista de nomes para os labels dos Chips
const tagNames = [
    'Alta Prioridade',
    'Média Prioridade',
    'Baixa Prioridade',
    'Aguardando Atendimento',
    'Em Atendimento',
    'Concluído',
    'Urgente',
    'Requer Feedback',
    'Pendente',
    'Resolvido',
    'Em Análise',
    'Para Revisão',
    'Aguardando Aprovação',
    'Cancelado',
    'Agendado',
    'Novo Chamado',
    'Em Andamento',
    'Solicitação Pendiente',
    'Confirmado',
    'Desconsiderado'
];

const generateRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};


const RandomChips = ({ tags }) => {
    // Gera um número aleatório de Chips
    const chipCount = getRandomCount();

    // Renderiza os Chips
    return (
        <Box display="flex" flexWrap="wrap" gap={0.5}> {/* Usa gap para espaçamento de 2px */}
            {Array.from({ length: chipCount }).map((_, index) => {
                // Escolhe um label e uma cor aleatória
                const label = tagNames[Math.floor(Math.random() * tagNames.length)];
                const color = generateRandomHexColor()
                return (
                    <>
                        {tags.size == "normal" ? (
                            <Chip
                                key={index}
                                label={label}
                                size="small"
                                sx={{
                                    borderRadius: '10px',
                                    height: '22px',
                                    color: (theme) => theme.palette.getContrastText(color),
                                    backgroundColor: color
                                }}
                            />
                        ) : tags.size == "small" ? (
                            <Tooltip title={label} key={index}>
                                <Box
                                    key={index}
                                    label={label}
                                    sx={{
                                        borderRadius: '10px',
                                        padding: '5px 20px',
                                        color: (theme) => theme.palette.getContrastText(color),
                                        backgroundColor: color
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <Chip
                                key={index}
                                label={label}
                                sx={{
                                    borderRadius: '10px',
                                    color: (theme) => theme.palette.getContrastText(color),
                                    backgroundColor: color
                                }}
                            />
                        )}
                    </>
                );
            })}
        </Box>
    );
};

export default RandomChips;
