import { Container, Typography } from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import React from "react";
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as UniChatDark } from "../../assets/icons/UniChatDark.svg";

import icon from "../../assets/icons/disconnect.ico";

import socket from "../../hooks/useSocket";

import "./loading.css";

const useStyles = makeStyles()((theme) => {
    return {
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
        },
    }
});

const SessionError = () => {

    const { classes } = useStyles();

    const favicon = document.querySelector("link[rel='icon']") || document.createElement('link');
    favicon.href = icon;
    document.head.appendChild(favicon);

    const array = ["Multiplas", "janelas", "abertas"];
    let index = 0;

    function changeTitle() {
        document.title = array[index];
        index = (index + 1) % array.length;
    }

    setInterval(changeTitle, 900);

    socket.disconnect();

    return (
        <>
            <Backdrop className={classes.backdrop} open={true}>
                <div id="app-preloader">
                    <div className="preloader-logo" style={{
                        width: '45%',
                        height: '45%'
                    }}>
                        <UniChatDark style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div className="preloader-title"></div>
                    <div>
                        <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '50px' }}>
                            <Typography variant="h4" gutterBottom>
                                Você foi desconectado
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Detectamos que o UniChat está aberto em outra janela. Para evitar consumo excessivo de memória, esta sessão foi desconectada.
                            </Typography>
                        </Container>
                    </div>
                </div>
            </Backdrop>
        </>

    );
};

export default SessionError;
