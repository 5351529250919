import { Box, Checkbox, Chip, Divider, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { i18n } from '../../translate/i18n';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";

const QueueScheduleComponent = ({ values, onChangeValue }) => {

    return (
        <Grid container spacing={1}>
            {Object.entries(values).map(([day, { start, end, interval, intervalStart, intervalEnd }]) => (
                <React.Fragment key={day}>
                    <Box sx={{ width: '100%' }}>
                        <Divider>
                            <Chip
                                color="primary"
                                label={`${i18n.t(`queueModal.form.schedules.${day}`)}`}
                                sx={{ borderRadius: '2px' }}
                                size="small"
                            />
                        </Divider>
                    </Box>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 2.825 : 5.65}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                    <TimePicker
                                        label="Horário inicial*"
                                        ampm={false}
                                        value={start}
                                        onChange={(newValue) => {
                                            onChangeValue(prevSchedule => ({
                                                ...prevSchedule,
                                                [day]: {
                                                    ...prevSchedule[day],
                                                    start: newValue // Atualiza o horário inicial do dia atual
                                                }
                                            }));
                                        }}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {!interval && <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={0.70}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '7px',
                                    justifyContent: 'center'
                                }}>
                                    <Tooltip title={`${!interval ? 'Habilitar' : 'Desabilitar'} intervalo`}>
                                        <Checkbox
                                            checked={interval}
                                            icon={<AccessTimeIcon />}
                                            checkedIcon={<WatchLaterIcon />}
                                            onChange={(e) => {
                                                onChangeValue(prevSchedule => ({
                                                    ...prevSchedule,
                                                    [day]: {
                                                        ...prevSchedule[day],
                                                        interval: !interval
                                                    }
                                                }));
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            </Grid>}
                            <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 2.825 : 5.65}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                    <TimePicker
                                        label="Horário final*"
                                        ampm={false}
                                        value={end}
                                        onChange={(newValue) => {
                                            onChangeValue(prevSchedule => ({
                                                ...prevSchedule,
                                                [day]: {
                                                    ...prevSchedule[day],
                                                    end: newValue // Atualiza o horário final do dia atual
                                                }
                                            }));
                                        }}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {interval && <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={0.70}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '7px',
                                    justifyContent: 'center'
                                }}>
                                    <Tooltip title={`${!interval ? 'Habilitar' : 'Desabilitar'} intervalo`}>
                                        <Checkbox
                                            checked={interval}
                                            icon={<AccessTimeIcon />}
                                            checkedIcon={<WatchLaterIcon />}
                                            onChange={(e) => {
                                                onChangeValue(prevSchedule => ({
                                                    ...prevSchedule,
                                                    [day]: {
                                                        ...prevSchedule[day],
                                                        interval: !interval
                                                    }
                                                }));
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            </Grid>}
                            {interval && <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 2.825 : 6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                    <TimePicker
                                        label="Inicio Intervalo*"
                                        ampm={false}
                                        value={intervalStart}
                                        onChange={(newValue) => {
                                            onChangeValue(prevSchedule => ({
                                                ...prevSchedule,
                                                [day]: {
                                                    ...prevSchedule[day],
                                                    intervalStart: newValue // Atualiza o horário final do dia atual
                                                }
                                            }));
                                        }}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                                    />
                                </LocalizationProvider>
                            </Grid>}
                            {interval && <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 2.825 : 6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                    <TimePicker
                                        label="Fim Intervalo*"
                                        ampm={false}
                                        value={intervalEnd}
                                        onChange={(newValue) => {
                                            onChangeValue(prevSchedule => ({
                                                ...prevSchedule,
                                                [day]: {
                                                    ...prevSchedule[day],
                                                    intervalEnd: newValue // Atualiza o horário final do dia atual
                                                }
                                            }));
                                        }}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                                    />
                                </LocalizationProvider>
                            </Grid>}
                        </Grid>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    )

};


export default QueueScheduleComponent;