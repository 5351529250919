import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Backdrop,
    Box,
    CircularProgress,
    Container,
    Grid
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import Page from '../../components/Page';
import { AuthContext } from '../../context/Auth/AuthContext';
import MonitorSound from "../../helpers/MonitorSound";
import useSettings from '../../hooks/useSettings';
import socket from '../../hooks/useSocket';
import { BookingWidgetSummary } from '../../sections/@dashboard/general/booking';
import { UserCard } from '../../sections/@dashboard/user/cards';
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from '../../translate/i18n';

const IconWrapperStyle = styled('div')(({ theme }) => ({
	width: 40,
	height: 40,
	display: 'flex',
	borderRadius: '50%',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.primary.main,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

const useStyles = makeStyles()((theme) => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginTop: '50px',
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		borderRadius: '15px',
		height: 445,
	},
}));

function uuidv4() {
	return (
		[1e7] +
		-1e3 +
		-4e3 +
		-8e3 +
		-1e11
	).replace(/[018]/g, (c) =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (c / 4)).toString(16)
	);
}

const Monitor = () => {
	const { classes } = useStyles();
	const { user } = useContext(AuthContext);
	const { themeStretch } = useSettings();
	const [tickets, setTickets] = useState([]);
	const [hash, setHash] = useState(uuidv4());
	const [usersWorking, setUsersWorking] = useState([]);
	const [oldUsersWorking, setOldUsersWorking] = useState([]);
	const [loading, setLoading] = useState(true);
	const [ticketsByStatus, setTicketsByStatus] = useState({
		pending: 0,
		open: 0,
		closed: 0,
	});

	function padTo2Digits(num) {
		return String(num).padStart(2, '0');
	}

	function verificarHorario(horario, horarioInicial, horarioFinal) {
		const dataBase = '2023-04-04T';
		const hora = new Date(`${dataBase}${horario}:00`);
		const horaInicial = new Date(`${dataBase}${horarioInicial}:00`);
		const horaFinal = new Date(`${dataBase}${horarioFinal}:00`);

		return hora >= horaInicial && hora <= horaFinal;
	}


	useEffect(() => {
		let pending = 0;
		let open = 0;
		let closed = 0;
		tickets.forEach((ticket) => {
			switch (ticket.status) {
				case 'pending':
					pending += 1;
					break;
				case 'open':
					open += 1;
					break;
				case 'closed':
					closed += 1;
					break;
				default:
					break;
			}
		});

		setTicketsByStatus({ pending, open, closed });
	}, [tickets]);

	const getPeriods = async () => {
		const now = new Date();
		const horas = now.getHours();
		const minutos = now.getMinutes();

		try {
			const { data } = await api.get('/settings-company');
			const findPeriodOfWork = data?.findPeriodOfWork;

			if (findPeriodOfWork) {
				const periods = ['morning', 'afternoon', 'night'];

				const periodsArray = periods.filter((period) => {
					const { start, end } = JSON.parse(findPeriodOfWork[period]);
					return verificarHorario(
						`${padTo2Digits(horas)}:${padTo2Digits(minutos)}`,
						start,
						end
					);
				});
				return periodsArray;
			} else {
				return ['morning', 'afternoon', 'night'];
			}
		} catch (error) {
			// Lida com erros da chamada à API, se necessário
			console.error('Erro ao obter configurações da empresa:', error);
			return ['morning', 'afternoon', 'night'];
		}
	};


	const reloadListing = async (socket) => {
		const periods = await getPeriods();
		socket.emit('reloadMonitor', {
			period: periods,
			companyId: user.companyId,
			hash: hash,
		});
	};

	const getTickets = async () => {
		try {
			const { data } = await api.get('/tickets-dashboard', {
				params: {
					dateStart: new Date().toISOString(),
					dateEnd: new Date().toISOString(),
				},
			});
			setLoading(false);
			setTickets(data.tickets);
		} catch (err) {
			console.info(err);
			setLoading(false);
			ToastError(err);
		}
	};

	useEffect(() => {
		const oldObj = JSON.stringify(
			oldUsersWorking.filter((u) => u.totalTickets === 0)
		);
		const newObj = JSON.stringify(
			usersWorking.filter((u) => u.totalTickets === 0)
		);
		if (oldObj !== newObj && newObj.length > oldObj.length) {
			MonitorSound();
		}
		setOldUsersWorking(usersWorking);
	}, [usersWorking]);

	useEffect(() => {

		const handleUpdateMonitor = async (data) => {
			console.info({ updateMonitor: data });
			setUsersWorking(data);
		};

		
		socket.on(`updateMonitor_${hash}`, handleUpdateMonitor);

		const fetchData = async () => {
			setLoading(true);
			await getTickets();
			reloadListing(socket);
			setLoading(false);
		};

		// Inicia a chamada inicial
		fetchData();

		// Configura intervalo de 30 segundos para buscar atualizações
		const intervalId = setInterval(fetchData, 30000);

		return () => {
			clearInterval(intervalId);
			socket.off(`updateMonitor_${hash}`, handleUpdateMonitor);
		};
	}, [user]);

	const { pending, open, closed } = ticketsByStatus;

	return (
		<div>
			<Page title="Monitor">
				<Backdrop
					sx={{
						backgroundColor: '#fff0',
						color: '#fff',
						zIndex: (theme) => theme.zIndex.drawer,
					}}
					open={loading}
				>
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<CircularProgress color="inherit" />
						</div>
						<p>Caregando informações...</p>
					</div>
				</Backdrop>
				<Container
					style={{ marginTop: '-55px' }}
					maxWidth={!themeStretch ? false : 'xl'}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={3}>
							<BookingWidgetSummary
								title={i18n.t('dashboard.tickets.ticketsPending')}
								total={pending}
								icon={
									<HourglassBottomIcon
										style={{ width: '90px', height: '90px' }}
										sx={{ color: (theme) => theme.palette.primary.main }}
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<BookingWidgetSummary
								title={i18n.t('dashboard.tickets.ticketsOpen')}
								total={open}
								icon={
									<RecordVoiceOverIcon
										style={{ width: '90px', height: '90px' }}
										sx={{ color: (theme) => theme.palette.primary.main }}
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<BookingWidgetSummary
								title={i18n.t('dashboard.tickets.ticketsClosed')}
								total={closed}
								icon={
									<CheckCircleOutlineIcon
										style={{ width: '90px', height: '90px' }}
										sx={{ color: (theme) => theme.palette.primary.main }}
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<BookingWidgetSummary
								title={i18n.t('dashboard.tickets.ticketsTotal')}
								total={Number(closed) + Number(pending) + Number(open)}
								icon={
									<TimelineIcon
										style={{ width: '90px', height: '90px' }}
										sx={{ color: (theme) => theme.palette.primary.main }}
									/>
								}
							/>
						</Grid>
					</Grid>
					<Box
						sx={{
							marginTop: '30px',
							display: 'grid',
							gap: 2,
							gridTemplateColumns: {
								xs: 'repeat(2, 1fr)',
								sm: 'repeat(3, 1fr)',
								md: 'repeat(6, 1fr)',
								lg: 'repeat(8, 1fr)',
								xl: 'repeat(9, 1fr)',
							},
						}}
					>
						{usersWorking.map((user) => (
							<UserCard key={user.id} user={user} />
						))}
					</Box>
				</Container>
			</Page>
		</div>
	);
};

export default Monitor;
