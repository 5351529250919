import AttachFileIcon from '@mui/icons-material/AttachFile';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FacebookIcon from '@mui/icons-material/Facebook';
import ForumIcon from '@mui/icons-material/Forum';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import InstagramIcon from '@mui/icons-material/Instagram';
import MicIcon from '@mui/icons-material/Mic';
import ContactIcon from '@mui/icons-material/Person';
import TelegramIcon from '@mui/icons-material/Telegram';
import VideocamIcon from '@mui/icons-material/Videocam';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
	Avatar,
	Badge,
	Box,
	Button,
	Chip,
	Divider,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Tooltip,
	Typography
} from '@mui/material';
import { format, isSameDay, parseISO } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import isColorDark from '../../utils/isColorDark';

import { green } from "@mui/material/colors";
import BadgeStatus from '../../components/BadgeStatus';
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import RatingStars from "../RatingStars";
import './style.css';

const useStyles = makeStyles()((theme) => {
	return {
		ticket: {
			position: "relative",
		},
		pendingTicket: {
			cursor: "unset",
		},
		noTicketsDiv: {
			display: "flex",
			height: "100px",
			margin: 40,
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
		noTicketsText: {
			textAlign: "center",
			color: "rgb(104, 121, 146)",
			fontSize: "14px",
			lineHeight: "1.4",
		},
		noTicketsTitle: {
			textAlign: "center",
			fontSize: "16px",
			fontWeight: "600",
			margin: "0px",
		},
		contactNameWrapper: {
			display: "flex",
			justifyContent: "space-between",
		},
		lastMessageTime: {
			justifySelf: "flex-end",
		},
		closedBadge: {
			alignSelf: "center",
			justifySelf: "flex-end",
			marginRight: 32,
			marginLeft: "auto",
		},
		contactLastMessage: {
			paddingRight: 20,
		},
		newMessagesCount: {
			alignSelf: "center",
			marginRight: 8,
			marginLeft: "auto",
		},
		badgeStyle: {
			color: "white",
			backgroundColor: green[500],
		},
		acceptButton: {
			position: "absolute",
			left: "50%",
		},
		ticketQueueColor: {
			flex: "none",
			width: "10px",
			borderRadius: '0px 10px 10px 0px',
			height: "100%",
			position: "absolute",
			top: "0%",
			left: "0%",
		}
	}
});

const TicketListItem = ({ ticket, showAll, viewTags }) => {
	const { classes } = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [ticketTime, setTicketTime] = useState('');
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	const padTo2Digits = (num) => String(num).padStart(2, '0');



	useEffect(() => {
		const renderTimeoutTickets = () => {
			if (ticket.status !== 'pending') return;
			const createdAt = new Date(ticket.createdAt);
			const now = new Date();
			const diferencaEmMilissegundos = Math.abs(now - createdAt);
			const diferencaEmHoras = padTo2Digits(Math.floor(diferencaEmMilissegundos / 1000 / 60 / 60));
			const diferencaEmMinutos = padTo2Digits(Math.floor((diferencaEmMilissegundos / 1000 / 60) % 60));
			const diferencaEmSegundos = padTo2Digits(Math.floor((diferencaEmMilissegundos / 1000) % 60));
			const returnTime = `${diferencaEmHoras}:${diferencaEmMinutos}:${diferencaEmSegundos}`;
			setTicketTime({ returnTime, diferencaEmMilissegundos });
			checkUrl(window.location.pathname);
		};

		const myInterval = setInterval(renderTimeoutTickets, 1000);

		function checkUrl(url) {
			if (!url.includes('/tickets')) clearInterval(myInterval);
		}
	}, [ticket]);

	const handleAcepptTicket = async (id) => {
		setLoading(true);
		try {
			localStorage.removeItem("ticket_accept")

			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
				accept: true
			});
			localStorage.setItem("ticket_accept", id);
			document.querySelector("#openTickets")?.click();
		} catch (err) {
			setLoading(false);
			ToastError(err);
		}

		if (isMounted.current) {
			setLoading(false);
		}

		history.push(`/tickets/${ticket.uuid}`);
	};

	const handleSelectTicket = (uuid) => {
		history.push(`/tickets/${uuid}`);
	};

	const renderChannel = (channel, isPresence) => {
		const iconStyles = { color: '', borderRadius: '5px' };
		if (isPresence) return <><GpsFixedIcon style={{ color: '#fe0000' }} /><Box className="wave"></Box></>;
		switch (channel) {
			case 'whatsapp':
				iconStyles.color = '#0dc242';
				return <WhatsAppIcon style={iconStyles} />;
			case 'telegram':
				iconStyles.color = '#3390ec';
				return <TelegramIcon style={iconStyles} />;
			case 'instagram':
				iconStyles.color = '#fe0079';
				return <InstagramIcon style={iconStyles} />;
			case 'messenger':
				iconStyles.color = '#0573e7';
				return <FacebookIcon style={iconStyles} />;
			case 'webchat':
				iconStyles.color = '#0573e7';
				return <ForumIcon style={iconStyles} />;
			default:
				return null;
		}
	};

	const verifyTypeOfMessage = (message) => {
		if (message.includes('MEDIA_VALUE_')) {
			const renderType = message.split('[[')[1].split(']]')[0];

			const typeMap = {
				video: { icon: <VideocamIcon fontSize="small" />, translation: i18n.t("userType.translate.video") },
				image: { icon: <CameraAltIcon fontSize="small" />, translation: i18n.t("userType.translate.photo") },
				audio: { icon: <MicIcon fontSize="small" />, translation: i18n.t("userType.translate.audio") },
				application: { icon: <AttachFileIcon fontSize="small" />, translation: i18n.t("userType.translate.file") }
			};

			const { icon, translation } = typeMap[renderType] || {};

			return (
				<Box style={{ display: 'flex', alignItems: 'center' }}>
					{icon}
					<p style={{ marginLeft: 5 }}>{translation}</p>
				</Box>
			);
		}

		if (message.includes('VCARD')) {
			const array = message?.split("\n");
			let contact = "";

			array?.forEach(item => {
				const values = item?.split(":");
				if (values[0] === "FN") {
					contact = values[1];
				}
			});

			return (
				<Box style={{ display: 'flex', alignItems: 'center' }}>
					<ContactIcon fontSize="small" />
					<p style={{ marginLeft: 5 }}>{contact}</p>
				</Box>
			);
		}

		return message;
	};

	const firstWordInCapsLock = (text) => {
		return text ? text[0].toUpperCase() + text.substring(1) : "";
	};

	return (
		<>
			<MenuItem
				key={ticket.id}
				style={{
					padding: showAll && ticket.status === 'open' && ticket?.user?.name
						? '10px 5px 30px 20px'
						: '0px 0px 0px 15px',
				}}
				selected={ticketId && ticketId === ticket.uuid}
				onClick={(e) => { handleSelectTicket(ticket.uuid) }}
			>
				<Tooltip arrow placement="right" title={ticket.queue?.name || 'Sem setor'}>
					<Box
						component="span"
						style={{ backgroundColor: ticket.queue?.color || '#7C7C7C' }}
						className={classes.ticketQueueColor}
					/>
				</Tooltip>
				<ListItemAvatar sx={{ position: 'relative' }}>
					<Badge
						overlap="circular"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						badgeContent={
							<Tooltip title={ticket?.isPresence ? 'Atendimento presencial' : firstWordInCapsLock(ticket.channel)} placement="right">
								{renderChannel(ticket.channel, ticket?.isPresence)}
							</Tooltip>
						}
					>
						<Avatar src={ticket?.contact?.profilePicUrl} />
					</Badge>

					<BadgeStatus sx={{ position: 'absolute', right: 1, bottom: 1 }} />
				</ListItemAvatar>
				<Box
					style={{
						position: 'absolute',
						marginTop: '75px',
						marginLeft: '-7px',
					}}
				>
					{showAll && ticket.status === 'open' && ticket?.user?.name && (
						<Tooltip placement="right" title="Usuário atendendo">
							<Chip
								label={ticket?.user?.name}
								sx={{
									borderRadius: '5px',
									height: '15px',
									'& .MuiChip-label': {
										paddingLeft: '5px',
										paddingRight: '5px',
									},
								}}
								color="primary"
								size="small"
							/>
						</Tooltip>
					)}
				</Box>
				<ListItemText
					primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
					secondaryTypographyProps={{ typography: 'caption' }}
					primary={
						<Box
							component="span"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket.contact.name}
							</Typography>
							{ticket.lastMessage && (
								<Typography
									style={{ marginLeft: 5 }}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
										<>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
									) : (
										<>{format(parseISO(ticket.updatedAt), 'dd/MM/yyyy')}</>
									)}
								</Typography>
							)}
						</Box>
					}
					secondary={
						<Box component="span" className={classes.contactNameWrapper}>
							<Typography
								component="span"
								noWrap
								variant="body2"
								color="textSecondary"
								title={ticket.lastMessage || ticket?.contact?.name}
							>
								{ticket.lastMessage ? (
									<span
										style={{
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											width: '250px',
										}}
									>
										{/*
											Removido por problema de filtro de formatação
											<MarkdownWrapper>
												{verifyTypeOfMessage(ticket.lastMessage)}
											</MarkdownWrapper>
										*/}
										{verifyTypeOfMessage(ticket.lastMessage)}
									</span>
								) : (
									<br />
								)}
							</Typography>
							<Box
								component="span"
								style={{
									position: 'absolute',
									right: ticket.status === 'pending' ? '20%' : '5%',
									bottom: '0%',
								}}
							>
								<Box component="span" style={{ display: 'flex', alignItems: 'center' }}>
									<Badge
										id="counterMessages"
										style={{
											margin: ticket.status === 'pending' ? '-40px 50px 0px 10px' : '0px 8px 15px 0px',
										}}
										className={classes.newMessagesCount}
										badgeContent={ticket.unreadMessages}
										classes={{
											badge: classes.badgeStyle,
										}}
									/>
								</Box>
							</Box>
						</Box>
					}
				/>
				{ticket.status === 'pending' && (
					<>
						<Divider orientation="vertical" style={{ margin: '0px 5px' }} flexItem />
						<Box>
							<Button
								sx={{
									borderRadius: '5px 5px 0px 0px',
									minWidth: '85px',
									padding: '2px 10px',
								}}
								variant="contained"
								size="small"
								onClick={(e) => handleAcepptTicket(ticket.id)}
								disabled={loading}
							>
								{i18n.t('ticketsList.buttons.accept')}
							</Button>
							<Box>
								<Chip
									onClick={(e) => handleAcepptTicket(ticket.id)}
									disabled={loading}
									sx={{
										borderRadius: '0px 0px 5px 5px !important',
										border: (theme) => `2px solid ${theme.palette.primary.main}`,
										height: '15px',
										'& .MuiChip-label': {
											paddingLeft: '5px',
											paddingRight: '5px',
											minWidth: '40px',
										},
									}}
									className={
										ticketTime.diferencaEmMilissegundos > 3600000
											? 'timeoutdanger colorChip'
											: ticketTime.diferencaEmMilissegundos > 1800000
												? 'timeoutinfo colorChip'
												: 'timeoutok colorChip'
									}
									label={ticketTime.returnTime}
									size="small"
								/>
							</Box>
						</Box>
					</>
				)}
				{ticket.status === 'closed' && (
					<Box
						component="span"
						style={{
							position: 'absolute',
							display: 'flex',
							width: ' 85%',
							marginTop: '-15px',
							justifyContent: 'flex-end',
						}}
					>
						<Box style={{ marginRight: '20px' }}>
							{ticket?.rating ? (
								<RatingStars
									style={{ marginRight: '40px' }}
									value={ticket.rating}
									size="small"
								/>
							) : (
								<Chip
									variant="outlined"
									style={{ marginRight: '40px' }}
									label={i18n.t('userType.translate.closed')}
									color="primary"
									size="small"
								/>
							)}
						</Box>
					</Box>
				)}
			</MenuItem>
			{ticket?.contact?.tags?.length > 0 && viewTags && (
				<Box style={{ padding: '0px 10px' }}>
					<>
						{ticket?.contact?.tags &&
							typeof ticket?.contact?.tags !== 'string' &&
							ticket?.contact?.tags.map((tag) => (
								<Chip
									label={tag.name}
									size="small"
									sx={{
										borderRadius: '3px',
										height: '15px',
										margin: '0px 3px',
										backgroundColor: tag.color,
										color: isColorDark(tag.color) ? '#FFFFFF' : '#000000',
										'& .MuiChip-deleteIcon': {
											color: isColorDark(tag.color) ? '#FFFFFF' : '#000000',
										}
									}}
								/>
							))}
					</>
				</Box>
			)}
			<Divider
				style={{
					listStyleType: 'none',
					marginTop: '0px',
					marginBottom: '0px',
				}}
				variant="inset"
				component="li"
			/>
		</>

	);
};

export default TicketListItem;
