import { Avatar, Box, Chip, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../services/api';
import KanbanInput from '../KanbanInput';
import KanbanPriorityAutoComplete from '../KanbanPriorityAutoComplete';
import KanbanPriorityModal from '../KanbanPriorityModal';
import KanbanUsersAutocomplete from '../KanbanUsersAutoComplete';
import socket from "../../hooks/useSocket";

const KanbanCardDetailSection = ({ card, priorities, assigneds }) => {
    const [priorityValue, setPriorityValue] = useState(card?.kanbanPriorityLevel ? [card.kanbanPriorityLevel] : []);
    const { title, creator, attachments, assigned } = card;
    const [selectedsUsers, setSelectedsUsers] = useState([]);
    const [users, setUsers] = useState(assigneds);


    // Ações para renomear titulo
    const [renamingTitle, setRenamingTitle] = useState(false);
    const [titleCard, setTitleCard] = useState(title);
    const titleRef = useRef(null); // Referência para o elemento do título


    // Ações para renomear descrição
    const [renamingDescription, setRenamingDescription] = useState(false);
    const [descriptionCard, setDescriptionCard] = useState(card?.description || '');
    const descriptionRef = useRef(null); // Referência para o elemento da descrição

    const [openPriorityModal, setOpenPriorityModal] = useState(false);

    const [loading, setLoading] = useState({
        title: false,
        description: false,
        priority: false,
        tags: false,
        users: false
    });

    const updatePriorityValue = (values, setFunction) => {
        if (!values || values.length === 0 || (values.length === 1 && values[0] === undefined)) {
            setFunction([]);
        } else {
            setFunction([values[values.length - 1]]);
        }
    };


    useEffect(() => {
        if (card?.kanbanPriorityLevel) {
            setPriorityValue([card.kanbanPriorityLevel]);
        }
    }, [card]);

    useEffect(() => {
        if (priorityValue?.length > 0 && card?.kanbanPriorityId === priorityValue[0].id) return;
        if (priorityValue?.length === 0 && !card?.kanbanPriorityId) return;
        (async () => {
            try {
                setLoading((prev) => ({ ...prev, priority: true }));
                await api.put(`/kanban/card/${card.id}`, { kanbanPriorityId: priorityValue[0]?.id || null })
            } catch (err) {
                console.error(err)
            } finally {
                setLoading((prev) => ({ ...prev, priority: false }));
            }
        })()
    }, [priorityValue]);

    useEffect(() => {
        setSelectedsUsers(assigned);
    }, [assigned]);

    const handleNewListOfAssigned = async (assignedsUsers) => {
        const oldAssigneds = selectedsUsers;
        setLoading((prev) => ({ ...prev, users: true }));
        try {
            setSelectedsUsers(assignedsUsers); // Atualiza os usuários selecionados
            const assignedIds = Array.isArray(assignedsUsers) && assignedsUsers.length > 0
                ? assignedsUsers.map(a => a.id) // Mapeia os IDs dos usuários
                : null;
            await api.put(`/kanban/assigned/${card.id}`, { assigned: assignedIds });
        } catch (err) {
            console.error(err);
            setSelectedsUsers(oldAssigneds); // Restaura os usuários selecionados em caso de erro
        } finally {
            setLoading((prev) => ({ ...prev, users: false }));
        }
    };

    const handleSaveRenameTitle = async (value) => {
        setTitleCard(value);
        setRenamingTitle(false);
        setLoading((prev) => ({ ...prev, title: true }));
        try {
            const { data } = await api.put(`/kanban/card/${card.id}`, { title: value });
            setTitleCard(data.title);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading((prev) => ({ ...prev, title: false }));
        }
        // Você pode fazer uma chamada API aqui para salvar o novo título se necessário
    };

    const handleSaveRenameDescription = async (value) => {
        setDescriptionCard(value);
        setRenamingDescription(false);
        setLoading((prev) => ({ ...prev, description: true }));
        try {
            const { data } = await api.put(`/kanban/card/${card.id}`, { description: value });
            setDescriptionCard(data.description);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading((prev) => ({ ...prev, description: false }));
        }
        // Você pode fazer uma chamada API aqui para salvar o novo título se necessário
    };


    // Efeito para detectar cliques fora do título e da descrição
    /*
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (titleRef.current && !titleRef.current.contains(event.target) && renamingTitle) {
                setRenamingTitle(false);
            }
            if (descriptionRef.current && !descriptionRef.current.contains(event.target) && descriptionRef) {
                setRenamingDescription(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [renamingTitle, renamingDescription]);
    */

    const handleCreatePriority = () => {
        setOpenPriorityModal(true)
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '24px',
                height: '100%',
                bgcolor: (theme) => theme.palette.mode === 'dark' ? '#1b212c' : '#ffffff',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <Box
                ref={titleRef} // Atribuindo a referência ao Box do título
                sx={{
                    maxWidth: '100%',
                    ...(renamingTitle ? {
                        width: '100%',
                    } : {
                        cursor: 'pointer',
                        transition: 'border 0.3s ease',
                        padding: '6px 6px 6px 6px',
                        ...(!loading?.title && {
                            '&:hover': {
                                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                borderRadius: '10px',
                            }
                        })
                    }),
                }}
                onClick={(e) => {
                    if (loading?.title) return;
                    e.stopPropagation();
                    if (renamingTitle) return;
                    setRenamingTitle(true);
                }}
            >
                {renamingTitle ? (
                    <KanbanInput label="Titulo" limit={1000} fontSize={'1.3rem'} value={titleCard} onSave={handleSaveRenameTitle} />
                ) : (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography
                            sx={{
                                margin: '0px',
                                color: (theme) => theme.palette.mode === 'dark' ?
                                    (loading?.title ? '#2f3641' : '#ffffff') :
                                    (loading?.title ? '#b2b2b2' : '#000000')
                            }}
                            variant="h5"
                            fontWeight="bold"
                            whiteSpace="pre-wrap"
                            gutterBottom
                        >
                            {titleCard}
                        </Typography>
                        {loading?.title && (
                            <Box>
                                <CircularProgress size={24} color="primary" />
                            </Box>
                        )}
                    </Box>
                )}
            </Box>

            <Box mt={2}>
                <Label>Autoria</Label>
                <StyledChip
                    avatar={<Avatar alt={creator.name} src={creator.media} />}
                    label={creator.name}
                />
            </Box>

            <Box mt={2}>
                <KanbanPriorityAutoComplete
                    labels={priorities}
                    placeholder="Prioridade"
                    search={"Prioridade do Card"}
                    setValue={(values) => updatePriorityValue(values, setPriorityValue)}
                    value={priorityValue}
                    loading={loading.priority}
                    handleCreate={handleCreatePriority}
                />
            </Box>
            <Box mt={2}>
                <KanbanUsersAutocomplete
                    labels={users}
                    placeholder="Atribuidos"
                    search={"Busque para atribuir"}
                    setValue={(values) => handleNewListOfAssigned(values)}
                    value={selectedsUsers}
                    loading={loading.users}
                />
            </Box>

            <Box
                mt={2}
                ref={descriptionRef}
                sx={{
                    maxWidth: '100%',
                    ...(renamingDescription ? {
                        width: '100%',
                    } : {
                        cursor: 'pointer',
                        transition: 'border 0.3s ease',
                        padding: '6px 6px 6px 6px',
                        ...(!loading?.description && {
                            '&:hover': {
                                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                borderRadius: '10px',
                            }
                        })
                    }),
                }}
                onClick={(e) => {
                    if (loading?.description) return;
                    e.stopPropagation();
                    if (renamingDescription) return;
                    setRenamingDescription(true);
                }}
            >
                <Label>Descrição</Label>
                {renamingDescription ? (
                    <KanbanInput label="Descrição" limit={3000} fontSize={'1rem'} multiline value={descriptionCard} onSave={handleSaveRenameDescription} />
                ) : (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography
                            sx={{
                                margin: '0px',
                                color: (theme) => theme.palette.mode === 'dark' ?
                                    (loading?.description ? '#2f3641' : '#ffffff') :
                                    (loading?.description ? '#b2b2b2' : '#000000')
                            }}
                            variant="body1"
                            fontWeight="bold"
                            whiteSpace="pre-wrap"
                            gutterBottom
                        >
                            {descriptionCard}
                        </Typography>
                        {loading?.description && (
                            <Box>
                                <CircularProgress size={24} color="primary" />
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <KanbanPriorityModal
                open={openPriorityModal}
                onClose={setOpenPriorityModal}
                kanbanId={card?.kanbanId}
            />
        </Box >

    );
};

export default KanbanCardDetailSection;

const Label = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    fontSize: '0.875rem',
    fontWeight: 500,
    marginRight: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
}));
