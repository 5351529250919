import ArticleIcon from '@mui/icons-material/Article';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DownloadingIcon from '@mui/icons-material/Downloading';
import EditIcon from '@mui/icons-material/Edit';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TimerIcon from '@mui/icons-material/Timer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
	Avatar,
	Box,
	Button,
	Chip,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Stack,
	Typography
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import formatBrazilianNumber from '../../utils/formatBrazilianNumber';
import { fDate4 } from '../../utils/formatTime';
import DialogBlur from "../DialogBlur";

const formatTime = (time) => {
	return time ? time.substring(0, 5) : "00:00";
};

const renderEventDate = (startAt, endAt) => {
	if (!startAt) return 'Data não disponível';
	const startDate = fDate4(startAt);
	const endDate = endAt ? ` até ${fDate4(endAt)}` : '';
	return `${startDate}${endDate}`;
};


const days = [
	{ label: "Domingo", key: "sunday" },
	{ label: "Segunda", key: "monday" },
	{ label: "Terça", key: "tuesday" },
	{ label: "Quarta", key: "wednesday" },
	{ label: "Quinta", key: "thursday" },
	{ label: "Sexta", key: "friday" },
	{ label: "Sábado", key: "saturday" },
];

const renderWeekDays = (daysObj) => {
	const daysArray = JSON.parse(daysObj);
	const selectedDays = days.filter(day => daysArray[day.key]);
	if (selectedDays.length === days.length) {
		return "Todos os dias";
	}
	return selectedDays.map(day => day.label).join(', ');
};

const eventType = {
	ONCE: 'Apenas uma vez',
	WEEKLY: 'Semanal',
	MONTHLY: 'Mensal',
	ANNUALLY: 'Anual',
}

const handleDownload = (fileUrl) => {
	window.open(fileUrl, '_blank');
};

const CalendarEventViewPreview = ({ open, onClose, onEdit, eventId }) => {

	const [loading, setLoading] = useState(false);
	const [event, setEvent] = useState(null);

	useEffect(() => {
		if (open) {
			(async () => {
				try {
					setLoading(true);
					const { data } = await api.get(`/calendar/${eventId}`);
					setEvent(data);
				} catch (error) {
					console.error(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [eventId]);

	if (!event) return null;

	const handleClose = () => {
		setEvent(null);
		onClose(false);
	};

	return (
		<DialogBlur
			open={open}
			aria-labelledby="event-notification-dialog"
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: event?.contacts?.length > 0 ? "1300px" : "700px",
					},
				},
			}}
			fullWidth
		>
			<DialogTitle
				id="event-notification-dialog"
				sx={{ backgroundColor: "#00ab55", color: "#fff", padding: '5px' }}
			>
				<Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} sx={{ width: '100%' }}>
					<Stack direction="row" alignItems="center" gap={1} sx={{ marginLeft: '15px' }}>
						<NotificationsActiveIcon />
						Evento de calendário
					</Stack>
					<IconButton
						disabled={loading}
						onClick={() => onEdit(event?.id)}
						color="inherit"
						aria-label="editar evento"
					>
						<EditIcon />
					</IconButton>
				</Stack>
			</DialogTitle>

			{loading ?
				(
					<Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} sx={{ width: '100%' }}>
						<Box sx={{
							display: 'flex',
							padding: '200px',
							width: "100%",
							alignItems: 'center',
							justifyContent: 'center'
						}}>
							<CircularProgress />
						</Box>
					</Stack>
				)
				:
				(
					<DialogContent dividers>

						<Grid container spacing={2}>
							{/* Coluna da esquerda */}
							<Grid item xs={12} sm={event?.contacts?.length > 0 ? 6 : 12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Divider>
											<Chip
												sx={{ borderRadius: '7px' }}
												icon={<TimerIcon />}
												label="Detalhes do evento"
												color="primary"
												size="small"
											/>
										</Divider>
										<Box sx={{ padding: '0px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
											<Stack direction="row" alignItems="center" gap={1}>
												<Typography variant="body1" color="textPrimary" fontWeight="bold">
													Título:
												</Typography>
												<Typography variant="body1" color="textSecondary">
													{event?.title || "Título não disponível"}
												</Typography>
											</Stack>
										</Box>
										<Box sx={{ padding: '0px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
											<Stack direction="row" alignItems="center" gap={1}>
												<Typography variant="body1" color="textPrimary" fontWeight="bold">
													Data:
												</Typography>
												<Typography variant="body1" color="textSecondary">
													{renderEventDate(event?.startAt, event?.endAt)}
												</Typography>
											</Stack>
										</Box>
										<Box sx={{ padding: '0px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
											<Stack direction="row" alignItems="center" gap={1}>
												<Typography variant="body1" color="textPrimary" fontWeight="bold">
													Horário:
												</Typography>
												<Typography variant="body1" color="textSecondary">
													{formatTime(event?.startTime) === formatTime(event?.endTime)
														? `${formatTime(event?.startTime)}`
														: `${formatTime(event?.startTime)} até ${formatTime(event?.endTime)}`}
												</Typography>
											</Stack>
										</Box>
										{event.days && (
											<Box sx={{ padding: '0px 20px', display: 'flex', flexDirection: 'column', gap: 1 }}>
												<Stack direction="row" alignItems="center" gap={1}>
													<Typography variant="body1" color="textPrimary" fontWeight="bold">
														Dias:
													</Typography>
													<Typography variant="body1" color="textSecondary">
														{renderWeekDays(event.days)}
													</Typography>
												</Stack>
											</Box>
										)}
										<Box sx={{ padding: '0px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
											<Stack direction="row" alignItems="center" gap={1}>
												<Typography variant="body1" color="textPrimary" fontWeight="bold">
													Tipo de envio:
												</Typography>
												<Typography variant="body1" color="textSecondary">
													{eventType[event?.recurrence]}
												</Typography>
											</Stack>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Divider>
											<Chip
												sx={{ borderRadius: '7px' }}
												icon={<PeopleAltIcon />}
												label="Usuários vinculados"
												color="primary"
												size="small"
											/>
										</Divider>
										<Box sx={{ padding: '5px 20px', marginTop: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
											<Stack direction="row" alignItems="center" gap={1}>
												{event?.users?.length > 0 && event.users.map(user => (
													<Chip
														key={user.id}
														sx={{ borderRadius: '7px' }}
														avatar={
															user.media ? (
																<Avatar alt={user.name} src={user.media} />
															) : (
																<Avatar>{user.name.charAt(0).toUpperCase()}</Avatar>
															)
														}
														label={user.name}
														color="primary"
														variant="outlined"
													/>
												))}
											</Stack>
										</Box>
									</Grid>
									{event?.description && (
										<Grid item xs={12}>
											<>
												<Divider>
													<Chip
														sx={{ borderRadius: '7px' }}
														icon={<FormatAlignLeftIcon />}
														label="Descrição do evento"
														color="primary"
														size="small"
													/>
												</Divider>

												<Box sx={{
													marginTop: 2,
													marginBottom: 2,
													maxHeight: 300,
													overflowY: 'auto',
													padding: 2,
													backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#00000029' : '#e5e8eb78',
													borderRadius: '5px'
												}}>
													<Typography variant="body2" color={(theme) => theme.palette.mode === 'dark' ? 'textPrimary' : "textSecondary"} sx={{ whiteSpace: 'pre-line' }}>
														{event?.description || "Sem descrição disponível."}
													</Typography>
												</Box>

											</>
										</Grid>
									)}
								</Grid>
							</Grid>
							{event?.contacts?.length > 0 && (
								<Grid item xs={12} sm={6}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Divider>
												<Chip
													sx={{ borderRadius: '7px' }}
													icon={<WhatsAppIcon />}
													label="Contatos vinculados"
													color="primary"
													size="small"
												/>
											</Divider>
											<Box sx={{ padding: '5px 20px', marginTop: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
												<Stack direction="row" alignItems="center" gap={1}>
													{event?.contacts?.length > 0 && event.contacts.map(contact => (
														<Chip
															key={contact.id}
															sx={{
																borderRadius: '7px',
																height: 'auto',
																'& .MuiChip-label': {
																	display: 'block',
																	whiteSpace: 'normal',
																},
															}}
															avatar={
																contact.profilePicUrl ? (
																	<Avatar sx={{ width: '30px', height: '30px' }} alt={contact.name} src={contact.profilePicUrl} />
																) : (
																	<Avatar sx={{ width: '30px', height: '30px' }}>{contact.name.charAt(0).toUpperCase()}</Avatar>
																)
															}
															label={
																<Box sx={{
																	display: 'flex',
																	flexDirection: 'column',
																	alignItems: 'flex-start'
																}}>
																	<Typography
																		variant="p"
																		sx={{ marginBottom: '-5px' }}
																	>
																		{contact.name}
																	</Typography>
																	<Typography
																		variant="p"
																	>
																		{formatBrazilianNumber(contact.number)}
																	</Typography>
																</Box>
															}
															color="primary"
															variant="outlined"
														/>
													))}
												</Stack>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Divider>
												<Chip
													sx={{ borderRadius: '7px' }}
													icon={<ForwardToInboxIcon />}
													label="Ações de contatos"
													color="primary"
													size="small"
												/>
											</Divider>
											<Box sx={{ padding: '5px 20px', marginTop: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1, flexWrap: 'wrap' }}>
												<Stack direction="row" alignItems="center" gap={1}>
													{event?.sendMessage ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="primary" />} <Typography>Enviar mensagem</Typography>
												</Stack>
												<Stack direction="row" alignItems="center" gap={1}>
													{event?.createOrSendToTicket ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="primary" />}	<Typography>Salvar ou criar ticket com a mensagem</Typography>
												</Stack>
											</Box>
										</Grid>
										{event?.message && (
											<Grid item xs={12}>
												<>
													<Divider>
														<Chip
															sx={{ borderRadius: '7px' }}
															icon={<ArticleIcon />}
															label="Mensagem"
															color="primary"
															size="small"
														/>
													</Divider>
													<Box sx={{
														marginTop: 2,
														marginBottom: 2,
														maxHeight: 300,
														overflowY: 'auto',
														padding: 2,
														backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#00000029' : '#e5e8eb78',
														borderRadius: '5px'
													}}>
														<Typography variant="body2" color={(theme) => theme.palette.mode === 'dark' ? 'textPrimary' : "textSecondary"} sx={{ whiteSpace: 'pre-line' }}>
															{event?.message || "Sem mensagem disponível."}
														</Typography>

													</Box>

												</>
											</Grid>
										)}
										{event?.messageMedia && (
											<Grid item xs={12}>
												<Chip
													sx={{
														width: '100%',
														margin: '8px 12px',
														borderRadius: '7px',
														padding: '8px 16px',
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
														'.MuiChip-deleteIcon': {
															color: '#00ab55',
															order: 2,
														},
														'.MuiChip-icon': {
															order: 0,
														},
														'.MuiChip-label': {
															flexGrow: 1,
															textAlign: 'left',
														},
													}}
													icon={<ArticleIcon />}
													variant="outlined"
													label={event.messageMedia.split('/').pop()}
													color="primary"
													onDelete={() => handleDownload(event.messageMedia)}
													deleteIcon={<DownloadingIcon />}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
							)}

						</Grid>
					</DialogContent>
				)

			}
			<DialogActions sx={{ justifyContent: "flex-end", padding: 2 }}>
				<Button
					variant="contained"
					color="primary"
					disabled={loading}
					onClick={() => handleClose()}
				>
					Fechar
				</Button>
			</DialogActions>
		</DialogBlur>
	);
};

export default CalendarEventViewPreview;
