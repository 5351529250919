import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import { useTheme } from '@mui/material/styles';
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import * as Yup from "yup";
import avatarPng from "../../assets/Profile-Avatar.png";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import QueueSelect from "../QueueSelect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};


function getStyles(name, periodWork, theme) {
	return {
		fontWeight: periodWork.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}


const useStyles = makeStyles()((theme) => {
	return {

		root: {
			display: "flex",
			flexWrap: "wrap",
		},

		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},

		formControl: {
			margin: "8px 0px 0px 0px",
			minWidth: 155,
		},

		formControlAdminSuper: {
			width: "100%"
		}

	}
});

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	email: Yup.string().email("Email Invalido").required("Obrigatório"),
});

const UserSchemaPassword = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	oldPassword: Yup.string().min(5, "Muito Curto(a)!").max(50, "Muito Longo(a)!")
		.required('Required'),
	password: Yup.string().min(5, "Muito Curto(a)!").max(50, "Muito Longo(a)!")
		.required('Required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], "As senhas não são iguais!")
		.required('Required'),
	email: Yup.string().email("Email Invalido").required("Obrigatório"),
});

const UserModal = ({ open, onClose, userId }) => {
	const { classes } = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);


	const theme = useTheme();
	const [periodWork, setPeriodWork] = useState([]);

	const handleChangePeriodWork = (event) => {
		const {
			target: { value },
		} = event;
		setPeriodWork(
			typeof value === 'string' ? value.split(',') : value,
		);
	};


	const initialState = {
		companyId: loggedInUser.companyId,
		name: "",
		email: "",
		password: "",
		profile: "user",
		oldPassword: "",
		confirmPassword: "",
		media: "",
		unideskId: "",
		position: "",
		noQueue: false
	};

	const [user, setUser] = useState(initialState);
	const [companies, setCompanies] = useState([]);
	const [userImage, setUserImage] = useState([]);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [isUnidesk, setIsUnidesk] = useState(false)
	const [checked, setChecked] = useState(false);
	const [noQueue, setNoQueue] = useState(true);
	const handleChange = (event) => { setChecked(event.target.checked) }

	useEffect(() => {
		const fetchUser = async () => {
			setChecked(false)
			setUserImage("");
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);

				if (data?.period) {
					setPeriodWork(JSON.parse(data.period))
				}
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				setUserImage(data.media);
				setNoQueue(data.noQueue);
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
			} catch (err) {
				ToastError(err);
			}
		};
		const fetchCompanies = async () => {
			if (!loggedInUser?.id) return;
			try {
				const { data } = await api.get("/company");
				setCompanies(data);
			} catch (err) {
				ToastError(err);
			}
		}
		fetchCompanies();
		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {

		const userData = new FormData();
		userData.append("name", values.name);
		userData.append("email", values.email);
		userData.append("password", values.password);
		userData.append("oldPassword", values.oldPassword);
		userData.append("id", values.id);
		userData.append("profile", values.profile);
		userData.append("position", values.position);
		userData.append("period", JSON.stringify(periodWork));
		userData.append("tokenVersion", values.tokenVersion);
		userData.append("queues", JSON.stringify(values.queues));
		userData.append("companyId", values.companyId);
		if (values?.unideskId) { userData.append("unideskId", values.unideskId) }
		if (userImage?.length > 0 && userImage[0] != 'h') { userData.append("media", userImage[0]) }
		userData.append("queueIds", JSON.stringify(selectedQueueIds));
		userData.append("noQueue", noQueue);

		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			ToastSuccess(i18n.t("userModal.success"));
			handleClose();
			setChecked(false);
		} catch (err) {
			ToastError(err);
		}

	};

	function checkImg() {
		let imagem = ""
		if (userImage) {
			if (userImage?.length && userImage[0]?.name) {
				imagem = URL.createObjectURL(userImage[0]);
			} else {
				imagem = userImage
			}
		} else { imagem = avatarPng; }
		return imagem;
	}

	const newArray = (periods) => {
		let periodsArray = [];
		periods.forEach(period => {
			if (period === 'morning') {
				periodsArray.push('Manhã')
			}
			if (period === 'afternoon') {
				periodsArray.push('Tarde')
			}
			if (period === 'night') {
				periodsArray.push('Noite')
			}
		});
		return periodsArray;
	}

	const encodeImageFileAsURL = (e) => {
		if (!e.target.files) {
			return;
		}
		let selectedMedias = Array.from(e.target.files);
		setUserImage(selectedMedias);
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<DialogTitle id="form-dialog-title">
						{userId
							? `${i18n.t("userModal.title.edit")}`
							: `${i18n.t("userModal.title.add")}`}
					</DialogTitle>
				</div>
				<Formik
					initialValues={user}
					enableReinitialize="true"
					validationSchema={checked ? UserSchemaPassword : UserSchema}
					onSubmit={(values, actions) => {
						const array = {
							"name": values.name,
							"email": values.email,
							"password": values.password,
							"oldPassword": values?.oldPassword,
							"profile": values.profile,
							"id": values.id,
							"tokenVersion": values.tokenVersion,
							"position": values.position,
							"queues": values.queues,
							"companyId": values.companyId,
							"queueIds": values.queueIds,
							"unideskId": values.unideskId,
							"noQueue": noQueue
						}
						setTimeout(() => {
							handleSaveUser(array);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div>
									<div style={{ marginBottom: '25px' }}>
										<div>
											<div className={classes.multFieldLine}>
												<img style={{
													borderRadius: '100px',
													display: 'block',
													marginLeft: 'auto',
													marginRight: 'auto',
													width: '40%'
												}} alt="user" src={checkImg()} />
											</div>
										</div>
										<div style={{
											marginTop: '10px',
											alignItems: 'center',
											display: 'flex',
											justifyContent: 'center'
										}}>
											<Button variant="contained" component="label">
												{i18n.t("userType.translate.sendPhoto")}
												<input hidden onChange={encodeImageFileAsURL} id="userImage" accept="image/*" type="file" />
											</Button>
										</div>
									</div>
									<div>
										<div className={classes.multFieldLine}>
											<Field
												style={{ display: 'none' }}
												as={TextField}
												type="hidden"
												name="media"
											/>
											<Field
												as={TextField}
												label={i18n.t("userModal.form.name")}
												autoFocus
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
											<Field
												as={TextField}
												label={i18n.t("userModal.form.email")}
												name="email"
												error={touched.email && Boolean(errors.email)}
												helperText={touched.email && errors.email}
												variant="outlined"
												margin="dense"
												fullWidth
											/>

										</div>
										{userId && loggedInUser.profile != 'admin' && <><FormControlLabel
											value="end"
											control={<Checkbox
												checked={checked}
												onChange={e => {
													handleChange(e);
													if (checked) {
														values.password = ""
														values.oldPassword = ""
														values.confirmPassword = ""
													}
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>}
											label={i18n.t("userModal.form.changePassword")}
											labelPlacement="end"
										/>
										</>}
										{checked && userId && loggedInUser.profile != 'admin' && <>
											<Field
												as={TextField}
												label={i18n.t("userModal.form.oldPassword")}
												type="password"
												name="oldPassword"
												id="oldPassword"
												error={touched.oldPassword && Boolean(errors.oldPassword)}
												helperText={touched.oldPassword && errors.oldPassword}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
											<div className={classes.multFieldLine}>
												<Field
													as={TextField}
													label={i18n.t("userModal.form.newPassword")}
													type="password"
													name="password"
													id="newPassword"
													error={touched.password && Boolean(errors.password)}
													helperText={touched.password && errors.password}
													variant="outlined"
													margin="dense"
													fullWidth
												/>
												<Field
													as={TextField}
													label={i18n.t("userModal.form.confirmPassword")}
													type="password"
													name="confirmPassword"
													id="confirmPassword"
													error={touched.confirmPassword && Boolean(errors.confirmPassword)}
													helperText={touched.confirmPassword && errors.confirmPassword}
													variant="outlined"
													margin="dense"
													fullWidth
												/>
											</div>
										</>
										}
										{userId === null &&
											<Field
												as={TextField}
												label={i18n.t("userModal.form.password")}
												type="password"
												name="password"
												error={touched.password && Boolean(errors.password)}
												helperText={touched.password && errors.password}
												variant="outlined"
												margin="dense"
												fullWidth
											/>}
										{loggedInUser.profile === 'admin' &&
											<Field
												as={TextField}
												label={i18n.t("userModal.form.password")}
												type="password"
												name="password"
												error={touched.password && Boolean(errors.password)}
												helperText={touched.password && errors.password}
												variant="outlined"
												margin="dense"
												fullWidth
											/>}
										<div className={classes.multFieldLine}>
											{loggedInUser.profile !== 'adminSuper' &&
												<>
													<FormControl
														fullWidth
														variant="outlined"
														className={classes.formControl}
														margin="dense"
													>
														<Can
															role={loggedInUser.profile}
															perform="user-modal:editProfile"
															yes={() => (
																<>
																	<InputLabel id="profile-selection-input-label">
																		{i18n.t("userModal.form.profile")}
																	</InputLabel>
																	<Field
																		as={Select}
																		label={i18n.t("userModal.form.profile")}
																		name="profile"
																		labelId="profile-selection-label"
																		id="profile-selection"
																		required
																	>
																		<MenuItem value="admin">{i18n.t("userType.translate.admin")}</MenuItem>
																		<MenuItem value="supervisor">{i18n.t("userType.translate.supervisor")}</MenuItem>
																		<MenuItem value="user">{i18n.t("userType.translate.user")}</MenuItem>
																	</Field>
																</>
															)}
														/>
													</FormControl>
												</>
											}
										</div>
										<div className={classes.multFieldLine}>
											{loggedInUser.profile !== 'adminSuper' &&
												<Field
													as={TextField}
													label="Cargo na empresa *Opcional"
													type="text"
													name="position"
													variant="outlined"
													margin="dense"
													fullWidth
												/>
											}
										</div>
										<div className={classes.multFieldLine}>
											{loggedInUser.profile !== 'adminSuper' &&
												<>
													<FormControl
														fullWidth
														variant="outlined"
														className={classes.formControl}
														margin="dense"
													>
														<Can
															role={loggedInUser.profile}
															perform="user-modal:editProfile"
															yes={() => (
																<>
																	<InputLabel id="demo-multiple-chip-label">Periodos</InputLabel>
																	<Select
																		labelId="demo-multiple-chip-label"
																		id="demo-multiple-chip"
																		multiple
																		value={periodWork}
																		onChange={handleChangePeriodWork}
																		input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
																		renderValue={(selected) => (
																			<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
																				{newArray(selected).map((value) => (
																					<Chip key={value} label={value} />
																				))}
																			</Box>
																		)}
																		MenuProps={MenuProps}
																	>
																		<MenuItem
																			key={'morning'}
																			value={'morning'}
																			style={getStyles('morning', periodWork, theme)}
																		>
																			Manhã
																		</MenuItem>
																		<MenuItem
																			key={'afternoon'}
																			value={'afternoon'}
																			style={getStyles('afternoon', periodWork, theme)}
																		>
																			Tarde
																		</MenuItem>
																		<MenuItem
																			key={'night'}
																			value={'night'}
																			style={getStyles('night', periodWork, theme)}
																		>
																			Noite
																		</MenuItem>
																	</Select>
																</>
															)}
														/>
													</FormControl>
												</>
											}
										</div>
										{isUnidesk && <div>
											<Field
												as={TextField}
												label="Id Unidesk"
												name="unideskId"
												error={touched.unideskId && Boolean(errors.unideskId)}
												helperText={touched.unideskId && errors.unideskId}
												variant="outlined"
												margin="dense"
											/>
										</div>}
										{loggedInUser.profile !== 'adminSuper' && <div>
											<FormControl
												variant="outlined"
												margin="dense"
												className={classes.formControlAdminSuper}
											>
												<Can
													role={loggedInUser.profile}
													perform="user-modal:editProfileCompany"
													yes={() => (
														<>
															<InputLabel id="company-selection-input-label">
																{i18n.t("userType.translate.company")}
															</InputLabel>
															<Field
																variant="outlined"
																as={Select}
																labelId="company-selection-label"
																id="company-selection"
																name="companyId">
																<MenuItem value=""><em>{i18n.t("empty")}</em></MenuItem>
																{companies?.map(queue => (
																	<MenuItem key={queue.id} value={queue.id}>
																		{queue.name}
																	</MenuItem>
																))}
															</Field>
														</>
													)}
												/>
											</FormControl>
										</div>
										}
									</div>
								</div>
								{loggedInUser.profile !== 'adminSuper' && <Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>}
								{loggedInUser.profile !== 'adminSuper' && <FormControlLabel
									control={
										<Checkbox
											checked={noQueue}
											onChange={() => setNoQueue(!noQueue)}
										/>
									}
									label="Exibir atendimentos sem setor"
								/>}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div >
	);
};

export default UserModal;
