import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Logo from "../../assets/icons/UniChatGreen.png";

export default function AudioPlayerStyled({ alt, src }) {
    const theme = useTheme();
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState("0:00");
    const [duration, setDuration] = useState("0:00");


    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', updateCurrentTime);
            audioRef.current.addEventListener('loadedmetadata', updateDuration);
            audioRef.current.addEventListener('ended', resetAudio);

            return () => {
                if (audioRef.current) {
                    audioRef.current.pause();           // Parar o áudio
                    audioRef.current.currentTime = 0;   // Resetar o tempo do áudio

                    // Remover event listeners
                    audioRef.current.removeEventListener('timeupdate', updateCurrentTime);
                    audioRef.current.removeEventListener('loadedmetadata', updateDuration);
                    audioRef.current.removeEventListener('ended', resetAudio);
                }
                setIsPlaying(false);   // Atualizar o estado para "não reproduzindo"
            };
        }
    }, []);

    const resetAudio = () => {
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time - minutes * 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const updateCurrentTime = () => {
        if (audioRef.current) {
            setCurrentTime(formatTime(audioRef.current.currentTime));
        }
    };


    const updateDuration = () => {
        setDuration(formatTime(audioRef.current.duration));
    };

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <Card sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component="div" variant="h5">
                        {alt}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="div">
                        {currentTime} / {duration}
                    </Typography>
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                    <IconButton aria-label="play/pause" onClick={togglePlayPause}>
                        {isPlaying ? (
                            <PauseIcon sx={{ height: 38, width: 38 }} />
                        ) : (
                            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                        )}
                    </IconButton>
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={Logo}
                alt={alt}
            />
            <audio
                ref={audioRef}
                src={src}
            ></audio>
        </Card>
    );
}
