import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import React, { forwardRef, useState, useRef, useEffect, useContext } from "react";
// @mui
import { Box } from '@mui/material';
import { AuthContext } from "../context/Auth/AuthContext";
import { HEADER, NAVBAR } from '../config';


// ----------------------------------------------------------------------
const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const [notifications, setNotifications] = useState([]);
  const { user } = useContext(AuthContext);
  const { companyId, profile, queues } = user;
  var editLayout = document.getElementById('layout')

  return <>
    <Helmet>
      <title>{`${title} | UniChat`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
