import { Draggable } from '@hello-pangea/dnd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, AvatarGroup, Box, Chip, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import ImageLightBox from '../../components/ImageLightBox';
import ChecklistCount from '../ChecklistCount';
import CustomListItemText from '../CustomListItemText';
import Iconify from '../Iconify';
import KanbanCardDrawer from '../KanbanCardDrawer';
import RandomChips from '../RandomChips';
import socket from '../../hooks/useSocket';
import api from '../../services/api';

function KanbanCard({ index, card, filters, assigneds, priorities }) {
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [numberComments, setNumberComments] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { id, title, comments = [], attachments = [], kanbanPriorityLevel, description, creator, assigned, tasks } = card;

    useEffect(() => {
        socket.on("kanban-comments-number", (data) => {
            if (data?.cardId === cardProps.id) {
                setNumberComments(data.commentCount);
            }
        });
    }, [card.id]);


    useEffect(() => {
        setNumberComments(comments?.length || 0)
    }, [comments])

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setDropdownOpen(true);
    };

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setDropdownOpen(false);
    };

    const archiveCard = async (id) => {
        try {
            await api.delete(`/kanban/card/${id}`, {
                params: { action: 'archive' },
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Draggable draggableId={id} index={index}>
                {(provided, snapshot) => (
                    <Container
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        $isDragging={snapshot.isDragging}
                        $themeMode={theme.palette.mode}
                    >
                        <Box onClick={() => setDrawerOpen(true)}>
                            {attachments.length > 0 && (
                                <ImageLightBox src={attachments[0]} alt={attachments[0]} />
                            )}
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box>
                                    {kanbanPriorityLevel && (
                                        <Chip
                                            label={kanbanPriorityLevel.title}
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                color: (theme) => theme.palette.getContrastText(kanbanPriorityLevel.color),
                                                backgroundColor: kanbanPriorityLevel.color,
                                                borderRadius: '5px',
                                                height: '22px',
                                                marginRight: '5px',
                                            }}
                                        />
                                    )}
                                    {creator && (
                                        <StyledChip
                                            sx={{ height: '22px' }}
                                            sixe="small"
                                            avatar={<Avatar alt={creator.name} src={creator.media} />}
                                            label={creator.name}
                                        />
                                    )}
                                </Box>
                                <IconButton aria-label="delete" onClick={handleMenuOpen}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={dropdownOpen}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            archiveCard(id);
                                            handleMenuClose();
                                        }}
                                        sx={{ color: 'warning.main', display: 'flex', alignItems: 'center' }}
                                    >
                                        <Iconify icon="solar:archive-down-bold" />
                                        <Box sx={{ ml: 1 }}>Arquivar Tarefa</Box>
                                    </MenuItem>
                                </Menu>
                            </Box>


                            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
                                <CustomListItemText title={title} description={description} />
                            </Box>

                            {filters?.showTags && (
                                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                                    <RandomChips tags={{ size: 'small' }} />
                                </Box>)
                            }
                            {tasks?.length > 0 && (
                                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                                    <ChecklistCount tasks={tasks} />
                                </Box>
                            )}
                            <Divider sx={{ marginTop: '10px' }} />
                            <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center" color={theme.palette.text.secondary}>
                                    <Iconify icon="uim:comment-dots" width={16} height={16} />
                                    <Typography variant="caption" ml={0.5}>{numberComments}</Typography>
                                    {/*
                                      <Iconify icon="iconamoon:attachment-bold" width={16} height={16} sx={{ ml: 1 }} />
                                      <Typography variant="caption" ml={0.5}>{attachments.length}</Typography>
                                    */}
                                </Box>

                                {assigned?.length > 0 && (
                                    <AvatarGroup max={4}
                                        sx={{
                                            '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
                                        }}>
                                        {assigned.map((user) => (
                                            <Avatar key={user.name} src={user.media} />
                                        ))}
                                    </AvatarGroup>
                                )}
                            </Box>
                            {/*
                            <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center" color={theme.palette.text.secondary}>
                                    <Iconify icon="fluent-mdl2:date-time-mirrored" width={16} height={16} />
                                    <Typography variant="caption" ml={0.5}>{comments.length}</Typography>
                                </Box>
                            </Box>
                            */}
                        </Box>
                    </Container>
                )}
            </Draggable>
            <KanbanCardDrawer
                priorities={priorities}
                assigneds={assigneds}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                cardId={card.id}
            />
        </>
    );
}

export default memo(KanbanCard);

const Container = styled(Box)(({ theme, $isDragging, $themeMode }) => ({
    width: 320,
    borderRadius: 12,
    padding: '5px 12px 12px 12px',
    marginBottom: 10,
    fontWeight: 400,
    fontSize: 12,
    backgroundColor: $themeMode === 'light'
        ? $isDragging
            ? 'rgba(255, 255, 255, 0.48)'
            : 'rgb(255, 255, 255)'
        : $isDragging
            ? 'rgba(22, 28, 36, 0.48)'
            : 'rgb(22, 28, 36)',
    backdropFilter: $isDragging ? 'blur(6px)' : 'none',
    '&:hover': {
        boxShadow: $themeMode === 'light'
            ? 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px'
            : 'rgba(0, 0, 0, 0.16) 0px 20px 40px -4px',
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '5px',
    fontSize: '0.875rem',
    fontWeight: 500,
    backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1b212c' : '#ffffff',
    '& .MuiChip-avatar': {
        width: 18,
        height: 18,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));
