import React, { useRef, useEffect, useState } from 'react';
import { ListItem, ListItemButton, Typography, ListItemText, Box } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const RenderQuickAnswer = ({ quickAnswer, key, onSelect, ...props }) => {

    const divRef = useRef(null);

    const [divWidth, setDivWidth] = useState(100);

    useEffect(() => {
        const handleResize = entries => {
            for (let entry of entries) {
                setDivWidth(entry.contentRect.width);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                resizeObserver.unobserve(divRef.current);
            }
        };
    }, []);


    const [openTooltip, setOpenTooltip] = useState(false);
    let hoverTimer = null;

    const handleMouseEnter = () => {
        hoverTimer = setTimeout(() => {
            setOpenTooltip(true);
        }, 1000); // 1 segundo
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimer);
        setOpenTooltip(false);
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#edeff1',
            color: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#edeff1',
            maxWidth: `${divWidth - 350}px`,
            fontSize: theme.typography.pxToRem(12),
            border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? '#005c4b' : '#edeff1'}`,
        },
    }));

    return (
        <ListItem ref={divRef} key={key} component="div" disablePadding {...props}>
            <HtmlTooltip
                open={openTooltip}
                title={
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            {quickAnswer.shortcut}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                display: 'inline-block',
                                width: '100%',
                                whiteSpace: 'break-spaces',
                                overflow: 'hidden !important',
                                textOverflow: 'ellipsis',
                            }}
                            color="inherit">
                            {quickAnswer.message}
                        </Typography>
                    </React.Fragment>
                }
                placement="top-start"
            >
                <ListItemButton
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => onSelect(quickAnswer.message)}>
                    <ListItemText
                        primary={quickAnswer.shortcut}
                        secondary={
                            <Box
                                component="div"
                                width="100%"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                sx={{ maxWidth: `${divWidth - 50}px` }}
                            >
                                {quickAnswer.message}
                            </Box>
                        }
                    />
                </ListItemButton>
            </HtmlTooltip>
        </ListItem>
    );
}

export default RenderQuickAnswer;