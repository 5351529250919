import React, { useEffect, useState } from "react";

import InfoIcon from '@mui/icons-material/Info';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {
	Box,
	CardActionArea,
	CircularProgress,
	FormControl,
	MenuItem,
	TextField
} from "@mui/material";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from "@mui/material/Container";
import Menu from '@mui/material/Menu';
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";

// Images
import ButtonsImg from "../../assets/images/Buttons.png";
import DefaultImg from "../../assets/images/Default.png";
import ListImg from "../../assets/images/List.png";

import socket from "../../hooks/useSocket";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";


const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(4),
			width: '100%'
		},
		paper: {
			margin: 5,
			justifyContent: 'space-between',
			padding: theme.spacing(2),
			display: "flex",
			alignItems: "center",
		},
		paperPeriod: {
			margin: 5,
			justifyContent: 'space-between',
			padding: theme.spacing(2),
			alignItems: "center",
		},
		settingOption: {
			marginLeft: "auto",
		},
		margin: {
			margin: theme.spacing(1),
		},
		progress: {
			marginLeft: '40px',
			padding: '1px',
			marginTop: '7px'
		},
		box: {
			padding: '0px 15%'
		}
	}
});

const periodOfWorkInitial = {
	morning: {
		start: '00:00',
		end: '00:00'
	},
	afternoon: {
		start: '00:00',
		end: '00:00'
	},
	night: {
		start: '00:00',
		end: '00:00'
	},
}


const Settings = () => {
	const { classes } = useStyles();
	const { themeStretch } = useSettings();
	const [settings, setSettings] = useState([]);
	const [timeTickets, setTimeTickets] = useState("");
	const [loading, setLoading] = useState(true);
	const [periodOfWork, setPeriodOfWork] = useState(periodOfWorkInitial);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const [messageRating, setMessageRating] = useState("");

	function Default() {
		return (
			<Card sx={{ maxWidth: 600, width: "300px" }}>
				<CardActionArea>
					<CardMedia
						component="img"
						height="150"
						width="300"
						image={DefaultImg}
						alt="Default"
					/>
				</CardActionArea>
			</Card>
		);
	}

	function List() {
		return (
			<Card sx={{ maxWidth: 700, width: "500px" }}>
				<CardActionArea>
					<CardMedia
						component="img"
						height="200"
						width="490"
						image={ListImg}
						alt="List"
					/>
				</CardActionArea>
			</Card>
		);
	}

	function Buttons() {
		return (
			<Card sx={{ maxWidth: 600, width: "300px" }}>
				<CardActionArea>
					<CardMedia
						component="img"
						height="150"
						width="300"
						image={ButtonsImg}
						alt="Buttons"
					/>

				</CardActionArea>
			</Card>
		);
	}


	useEffect(() => {
		const fetchSession = async () => {
			setLoading(true)
			try {
				const { data } = await api.get("/settings-company");
				if (data?.findPeriodOfWork) {
					const periodOfWorkInitial = {
						morning: JSON.parse(data.findPeriodOfWork.morning),
						afternoon: JSON.parse(data.findPeriodOfWork.afternoon),
						night: JSON.parse(data.findPeriodOfWork.night),
					}
					setPeriodOfWork(periodOfWorkInitial);
				}
				setMessageRating(data?.messageRating)
				setSettings(data);
				setLoading(false)
			} catch (err) {
				ToastError(err);
			}
			setLoading(false)
		};
		fetchSession();
	}, []);

	useEffect(() => {



		socket.on("settings", data => {
			if (data.action === "update") {
				setLoading(true)
				setSettings(data.setting);
				setTimeout(() => {
					setLoading(false)
				}, 100);
			}
		});
		return () => {
			socket.off("settings");
		};
	}, []);


	const handleChangeTicketTime = async e => {

		const selectedValue = timeTickets;
		const settingKey = 'ticketTime';

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			ToastSuccess(i18n.t("settings.success"));

		} catch (err) {
			ToastError(err);
		}
	};


	const handleChangeMessageRating = async e => {

		const selectedValue = messageRating;
		const settingKey = 'messageRating';

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			ToastSuccess(i18n.t("settings.success"));

		} catch (err) {
			ToastError(err);
		}
	};

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			const { data } = await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			ToastSuccess(i18n.t("settings.success"));
			if (settingKey == "serviceRating") {
				setMessageRating(data?.messageRating)
			}

		} catch (err) {
			ToastError(err);
		}
	};


	const selectOptionInContent = async (type) => {
		const value = { target: { value: type, name: "typeMessage" } }
		await handleChangeSetting(value)
		handleClose();
	}

	const changeTimeTickets = (e) => {
		setTimeTickets(e.target.value);
	}

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} placement="bottom-start" classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			fontSize: theme.typography.pxToRem(12),
			maxWidth: 600,
		},
	}));

	const renderMessageRating = (e) => setMessageRating(e.target.value);

	const handleChangePeriodOfWork = async e => {
		const selectedValue = periodOfWork;
		const settingKey = 'periodOfWork';
		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			ToastSuccess(i18n.t("settings.success"));
		} catch (err) {
			ToastError(err);
		}
	}

	return (
		<div className={classes.root}>
			<Page className={classes.root} title={i18n.t("settings.title")}>
				<Container maxWidth={false}>
					<HeaderBreadcrumbs
						heading={i18n.t("settings.title")}
					/>
					<div className={classes.box}>
						<Paper className={classes.paper} style={{ display: 'none' }}>
							<Typography variant="body1">
								{i18n.t("settings.settings.userCreation.name")}
							</Typography>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress} >
											<CircularProgress />
										</div> : <>
											<Select
												name="userCreation"
												value={settings?.userCreation}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.userCreation.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.userCreation.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.typeQueue.name")}
							</Typography>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<div>
												<Button
													aria-controls={open ? 'basic-menu' : undefined}
													aria-haspopup="true"
													aria-expanded={open ? 'true' : undefined}
													onClick={handleClick}
												>
													{`${i18n.t(`settings.settings.typeQueue.name`)}  (${i18n.t(`settings.settings.typeQueue.options.${settings?.typeMessage}`)})`}
												</Button>
												<Menu
													name="typeQueue"
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													MenuListProps={{
														'aria-labelledby': 'basic-button',
													}}
												>
													<div style={{ display: 'flex' }}>
														<HtmlTooltip
															title={
																<React.Fragment>
																	<Default />
																</React.Fragment>
															}
														>
															<MenuItem onClick={e => selectOptionInContent('default')} value={true}>{i18n.t(`settings.settings.typeQueue.options.default`)}</MenuItem>
														</HtmlTooltip>
														<HtmlTooltip
															title={
																<React.Fragment>
																	<List />
																</React.Fragment>
															}
														>
															<MenuItem onClick={e => selectOptionInContent('list')} value={false}>{i18n.t(`settings.settings.typeQueue.options.list`)}</MenuItem>
														</HtmlTooltip>
														<HtmlTooltip
															title={
																<React.Fragment>
																	<Buttons />
																</React.Fragment>
															}
														>
															<MenuItem disabled onClick={e => selectOptionInContent('buttons')} value={false}>{i18n.t(`settings.settings.typeQueue.options.buttons`)}</MenuItem>
														</HtmlTooltip>
													</div>
												</Menu>
											</div>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.answerInGroups.name")}
							</Typography>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="answerInGroups"
												value={settings?.answerInGroups}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.answerInGroups.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.answerInGroups.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								Usar atendimento presencial
							</Typography>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="usePresence"
												value={settings?.usePresence}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.answerInGroups.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.answerInGroups.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paper}>
							<Typography variant="body1">
								Exibir cargo do atendente na mensagem
							</Typography>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="usePosition"
												value={settings?.usePosition}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.answerInGroups.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.answerInGroups.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paper}>
							<div style={{ display: 'flex' }}>
								<Typography variant="body1" style={{ paddingRight: '5px' }}>
									Visualizar assinatura
								</Typography>
								<Tooltip title={'Ao habilitar, os usuários podem escolher enviar ou não seu nome assinado nas mensagens'}>
									<InfoIcon />
								</Tooltip>
							</div>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="viewSignature"
												value={settings?.viewSignature}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.answerInGroups.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.answerInGroups.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						{!settings?.viewSignature && <>
							<Paper className={classes.paper}>
								<div style={{ display: 'flex' }}>
									<Typography variant="body1" style={{ paddingRight: '5px' }}>
										Usar assinatura
									</Typography>
									<Tooltip title={'Ao habilitar, as assinaturas com nome do atendente serão exibidas nas mensagens.'}>
										<InfoIcon />
									</Tooltip>
								</div>
								<Box sx={{ minWidth: 130 }}>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <>
												<Select
													name="useSignature"
													value={settings?.useSignature}
													onChange={handleChangeSetting}
												>
													<MenuItem value={true}>{i18n.t("settings.settings.answerInGroups.options.enabled")}</MenuItem>
													<MenuItem value={false}>{i18n.t("settings.settings.answerInGroups.options.disabled")}</MenuItem>
												</Select>
											</>}
									</FormControl>
								</Box>
							</Paper>
						</>}

						<Paper className={classes.paper}>
							<Typography variant="body1">
								{i18n.t("settings.settings.ticketsOrder.name")}
							</Typography>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="ticketsOrder"
												value={settings?.ticketsOrder}
												onChange={handleChangeSetting}
											>
												<MenuItem value={'default'}>{i18n.t("settings.settings.ticketsOrder.options.default")}</MenuItem>
												<MenuItem value={'desc'}>{i18n.t("settings.settings.ticketsOrder.options.desc")}</MenuItem>
												<MenuItem value={'asc'}>{i18n.t("settings.settings.ticketsOrder.options.asc")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paper}>
							<div style={{ display: 'flex' }}>
								<Typography variant="body1" style={{ paddingRight: '5px' }}>
									{i18n.t("settings.settings.serviceRating.name")}
								</Typography>
								<Tooltip title={i18n.t("settings.settings.serviceRating.obs")}>
									<InfoIcon />
								</Tooltip>
							</div>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="serviceRating"
												value={settings?.serviceRating}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.serviceRating.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.serviceRating.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						{settings?.serviceRating && <Paper className={classes.paper}>
							<div style={{ width: '100%' }}>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button onClick={e => handleChangeMessageRating(e)}><SaveAsIcon /> &nbsp; Salvar</Button>
								</div>
								<div style={{ width: '100%' }}>
									<div style={{ display: 'flex' }}>
										<Typography variant="body1" style={{ paddingRight: '5px' }}>
											{i18n.t("settings.settings.messageRating.name")}
										</Typography>
										<Tooltip title={i18n.t("settings.settings.messageRating.obs")}>
											<InfoIcon />
										</Tooltip>
									</div>
									<Box>
										<FormControl fullWidth>
											{loading ?
												<div className={classes.progress}>
													<CircularProgress />
												</div> : <div style={{ marginTop: '15px' }}>
													<TextField
														maxRows={5}
														multiline
														value={messageRating}
														name="messageRating"
														label={i18n.t("settings.settings.messageRating.name")}
														type="text"
														onChange={e => renderMessageRating(e)}
														sx={{ width: '100%' }}
													/>
												</div>}
										</FormControl>
									</Box>
								</div>
							</div>
						</Paper>}
						<Paper className={classes.paper}>
							<div style={{ width: '100%' }}>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button onClick={e => handleChangeTicketTime(e)}><SaveAsIcon /> &nbsp; Salvar</Button>
								</div>
								<div style={{
									width: '100%', display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}>
									<div style={{ display: 'flex' }}>
										<Typography variant="body1" style={{ paddingRight: '5px' }}>
											{i18n.t("settings.settings.ticketTime.name")}
										</Typography>
										<Tooltip title={i18n.t("settings.settings.ticketTime.obs")}>
											<InfoIcon />
										</Tooltip>
									</div>
									<Box>
										<FormControl fullWidth>
											{loading ?
												<div className={classes.progress}>
													<CircularProgress />
												</div> : <div
													style={{ display: 'flex' }}>
													<TextField
														name="ticketTime"
														label={i18n.t("settings.settings.ticketTime.time")}
														type="time"
														onChange={changeTimeTickets}
														InputLabelProps={{
															shrink: true,
														}}
														defaultValue={settings?.ticketTime}
														inputProps={{
															step: 300,
														}}
														sx={{ width: 150 }}
													/>
												</div>}
										</FormControl>
									</Box>
								</div>
							</div>
						</Paper>
						<Paper className={classes.paper}>
							<div style={{ display: 'flex' }}>
								<Typography variant="body1" style={{ paddingRight: '5px' }}>
									{i18n.t("settings.settings.keywords.name")}
								</Typography>
								<Tooltip title={i18n.t("settings.settings.keywords.obs")}>
									<InfoIcon />
								</Tooltip>
							</div>
							<Box sx={{ minWidth: 130 }}>
								<FormControl fullWidth>
									{loading ?
										<div className={classes.progress}>
											<CircularProgress />
										</div> : <>
											<Select
												name="useKeywords"
												value={settings?.useKeywords}
												onChange={handleChangeSetting}
											>
												<MenuItem value={true}>{i18n.t("settings.settings.keywords.options.enabled")}</MenuItem>
												<MenuItem value={false}>{i18n.t("settings.settings.keywords.options.disabled")}</MenuItem>
											</Select>
										</>}
								</FormControl>
							</Box>
						</Paper>
						<Paper className={classes.paperPeriod}>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<div>
									<Typography variant="body1" style={{ paddingRight: '5px' }}>
										{i18n.t("settings.settings.periodTime.title")}
									</Typography>
								</div>
								<Button onClick={e => handleChangePeriodOfWork(e)}><SaveAsIcon /> &nbsp; Salvar</Button>
							</div>
							<Box sx={{ minWidth: 130, display: 'flex', marginTop: '50px' }}>
								<div>
									<Typography variant="body1" style={{ paddingLeft: '10px' }}>
										Matutino
									</Typography>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <div
												style={{ display: 'flex', padding: '10px' }}>
												<TextField
													name="ticketTime"
													label={i18n.t("settings.settings.periodTime.start")}
													type="time"
													onChange={e => {
														let obj = periodOfWork
														obj.morning.start = e.target.value
														setPeriodOfWork(obj)
													}}
													InputLabelProps={{ shrink: true }}
													defaultValue={periodOfWork?.morning?.start}
													inputProps={{ step: 300 }}
													sx={{ width: 150 }}
												/>
											</div>}
									</FormControl>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <div
												style={{ display: 'flex', padding: '10px' }}>
												<TextField
													name="ticketTime"
													label={i18n.t("settings.settings.periodTime.end")}
													type="time"
													onChange={e => {
														let obj = periodOfWork
														obj.morning.end = e.target.value
														setPeriodOfWork(obj)
													}}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue={periodOfWork?.morning?.end}
													inputProps={{
														step: 300,
													}}
													sx={{ width: 150 }}
												/>
											</div>}
									</FormControl>
								</div>
								<div>
									<Typography variant="body1" style={{ paddingLeft: '10px' }}>
										Vespertino
									</Typography>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <div
												style={{ display: 'flex', padding: '10px' }}>
												<TextField
													name="ticketTime"
													label={i18n.t("settings.settings.periodTime.start")}
													type="time"
													onChange={e => {
														let obj = periodOfWork
														obj.afternoon.start = e.target.value
														setPeriodOfWork(obj)
													}}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue={periodOfWork?.afternoon?.start}
													inputProps={{
														step: 300,
													}}
													sx={{ width: 150 }}
												/>
											</div>}
									</FormControl>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <div
												style={{ display: 'flex', padding: '10px' }}>
												<TextField
													name="ticketTime"
													label={i18n.t("settings.settings.periodTime.end")}
													type="time"
													onChange={e => {
														let obj = periodOfWork
														obj.afternoon.end = e.target.value
														setPeriodOfWork(obj)
													}}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue={periodOfWork?.afternoon?.end}
													inputProps={{
														step: 300,
													}}
													sx={{ width: 150 }}
												/>
											</div>}
									</FormControl>
								</div>
								<div>
									<Typography variant="body1" style={{ paddingLeft: '10px' }}>
										Noturno
									</Typography>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <div
												style={{ display: 'flex', padding: '10px' }}>
												<TextField
													name="ticketTime"
													label={i18n.t("settings.settings.periodTime.start")}
													type="time"
													onChange={e => {
														let obj = periodOfWork
														obj.night.start = e.target.value
														setPeriodOfWork(obj)
													}}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue={periodOfWork?.night?.start}
													inputProps={{
														step: 300,
													}}
													sx={{ width: 150 }}
												/>
											</div>}
									</FormControl>
									<FormControl fullWidth>
										{loading ?
											<div className={classes.progress}>
												<CircularProgress />
											</div> : <div
												style={{ display: 'flex', padding: '10px' }}>
												<TextField
													name="ticketTime"
													label={i18n.t("settings.settings.periodTime.end")}
													type="time"
													onChange={e => {
														let obj = periodOfWork
														obj.night.end = e.target.value
														setPeriodOfWork(obj)
													}}
													InputLabelProps={{
														shrink: true,
													}}
													defaultValue={periodOfWork?.night?.end}
													inputProps={{
														step: 300,
													}}
													sx={{ width: 150 }}
												/>
											</div>}
									</FormControl>
								</div>
							</Box>
						</Paper>
					</div>
				</Container>
			</Page>
		</div>
	);
};

export default Settings;
