import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { i18n } from "../../translate/i18n";
import LoadingFlow from "./loadingFlow";
import Reactflow from "./reactflow";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import "./styles.css";
import ToastError from "../../toast/error/toastError";

const MessagesReportModal = ({ chatbot, open, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('loading');
  const [chatbotFlow, setChatbotFlow] = useState(true);
  const [queues, setQueues] = useState([]);



  const CentralizeFlow = () => {
    let element = document.querySelector("#Teste > div.MuiBox-root.css-v2kfba > div > div.react-flow__panel.react-flow__controls.bottom.left > button.react-flow__controls-button.react-flow__controls-fitview")
    if (element) { element.click(); }
  }

  useEffect(() => {
    if (chatbot?.id && open) {
      setType('loading')
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        const fetchChatbot = async () => {
          try {
            const { data } = await api.get(`/chatbot/${chatbot.id}`);
            const queue = await api.get("/queue");
            setQueues(queue.data)
            setChatbotFlow(data)
            setLoading(false);
            setTimeout(() => CentralizeFlow(), 50);
          } catch (err) {
            console.error(err)
            ToastError(err);
          }
          setLoading(false);
        };
        fetchChatbot();
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [chatbot, open]);

  const saveChatbotFlow = async (values) => {
    setType('save')
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchChatbot = async () => {
        try {
          await api.put(`/chatbot/${chatbot.id}`, values);
          setLoading(false);
          onClose(false);
        } catch (err) {
          console.error(err)
          ToastError(err);
        }
        setLoading(false);
      };
      fetchChatbot();
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
  }




  return (
    <Dialog
      PaperProps={{
        style: {
          width: '100%',
          height: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          margin: '0px',
          borderRadius: '0px'
        },
      }}
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
      BackdropProps={{
        style: {
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0, 0, 30, 0.4)',
        },
      }}
    >
      {loading ?
        <>
          <LoadingFlow type={type} />
        </>
        :
        <>
          <DialogContent dividers>
            <Reactflow
              nodesFlow={chatbotFlow.nodes}
              edgesFlow={chatbotFlow.edges}
              onSave={e => saveChatbotFlow(e)}
              cancelEdit={e => onClose(false)}
              queues={queues}
            />
          </DialogContent>
        </>}
    </Dialog>
  );
};

export default MessagesReportModal;
