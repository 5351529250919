import React, { useContext, useEffect, useReducer, useState } from "react";

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControlLabel,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import ChatBotModal from "../../components/ChatbotModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QueueModal from "../../components/QueueModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import PreviewIcon from '@mui/icons-material/Preview';

import SmartToyIcon from '@mui/icons-material/SmartToy';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import socket from "../../hooks/useSocket";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import CreateOrEditKanbanModal from "../../components/CreateOrEditKanbanModal";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme) => {
    return {
        mainPaper: {
            flex: 1,
            padding: theme.spacing(1),
            overflowY: "scroll",
            ...theme.scrollbarStyles,
        },
        customTableCell: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    }
});

const reducer = (state, action) => {
    if (action.type === "LOAD_KANBANS") {
        const kanbans = action.payload;
        const newkanbans = [];

        kanbans.forEach((kanban) => {
            const kanbanIndex = state.findIndex((k) => k.id === kanban.id);
            if (kanbanIndex !== -1) {
                state[kanbanIndex] = kanban;
            } else {
                newkanbans.push(kanban);
            }
        });

        return [...state, ...newkanbans];
    }

    if (action.type === "UPDATE_KANBANS") {
        const kanban = action.payload;
        const kanbanIndex = state.findIndex((k) => k.id === kanban.id);

        if (kanbanIndex !== -1) {
            state[kanbanIndex] = kanban;
            return [...state];
        } else {
            return [kanban, ...state];
        }
    }

    if (action.type === "DELETE_KANBAN") {
        const kanbanId = action.payload;
        const kanbanIndex = state.findIndex((k) => k.id === kanbanId);
        if (kanbanIndex !== -1) {
            state.splice(kanbanIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Kanban = () => {
    const { classes } = useStyles();

    const history = useHistory();
    const [kanbans, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const [kanbanModalOpen, setKanbanModalOpen] = useState(false);
    const [selectedKanban, setSelectedKanban] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const { companyId } = user;

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await api.get("/kanban");
                dispatch({ type: "LOAD_KANBANS", payload: data });

                setLoading(false);
            } catch (err) {
                ToastError(err);
                setLoading(false);
            }
        })();
    }, []);


    useEffect(() => {

        socket.on("kanban", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_KANBANS", payload: data.kanban });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_KANBAN", payload: data.kanbanId });
            }
        });

        return () => {
            socket.off("kanban");
        };
    }, []);

    // --------------------------------------------------
    const handleOpenKanbanModal = () => {
        setKanbanModalOpen(true);
        setSelectedKanban(null);
    };

    const handleCloseKanbanModal = () => {
        setKanbanModalOpen(false);
        setSelectedKanban(null);
    };

    const handleEditKanban = (kanban) => {
        setSelectedKanban(kanban);
        setKanbanModalOpen(true);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedKanban(null);
    };

    const handleDeleteKanban = async (kanbanId) => {
        try {
            await api.delete(`/kanban/${kanbanId}`);
            ToastSuccess("Kanban deletado com sucesso");
        } catch (err) {
            ToastError(err);
        }
        setSelectedKanban(null);
    };

    return (
        <>
            <div style={{ marginTop: '65px' }}>
                <ConfirmationModal
                    title={
                        selectedKanban &&
                        `${i18n.t("kanban.confirmationModal.deleteTitle")} ${selectedKanban.title
                        }`
                    }
                    open={confirmModalOpen}
                    onClose={handleCloseConfirmationModal}
                    onConfirm={() => handleDeleteKanban(selectedKanban.id)}
                >
                    {i18n.t("kanban.confirmationModal.deleteMessage")}
                </ConfirmationModal>
                <CreateOrEditKanbanModal
                    kanbanId={selectedKanban?.id}
                    open={kanbanModalOpen}
                    onClose={setKanbanModalOpen}
                />
                <Page title={i18n.t("kanban.title")}>
                    <Container maxWidth={false}>
                        <HeaderBreadcrumbs
                            heading={i18n.t("kanban.title")}
                            action={
                                <>
                                    <Button
                                        style={{ margin: 2 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleOpenKanbanModal}
                                    >
                                        {i18n.t("kanban.buttons.add")}
                                    </Button>
                                </>
                            }
                        />
                        <Card>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 500 }} style={{ marginTop: '40px' }}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    {i18n.t("kanban.table.title")}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {i18n.t("kanban.table.description")}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {i18n.t("kanban.table.assigneds")}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {i18n.t("kanban.table.actions")}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <>
                                                {kanbans.map((kanban) => (
                                                    <TableRow key={kanban.id}>
                                                        <TableCell align="left">{kanban.title}</TableCell>
                                                        <TableCell align="center">{kanban.description}</TableCell>
                                                        <TableCell align="left">
                                                            <Box sx={{ display: 'flex', alignitems: 'center', justifyContent: 'center' }}>
                                                                {kanban?.assigneds?.length > 0 ? (
                                                                    <AvatarGroup max={4}
                                                                        sx={{
                                                                            '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
                                                                        }}>
                                                                        {kanban.assigneds.map((user) => (
                                                                            <Avatar key={user.name} src={user.media} />
                                                                        ))}
                                                                    </AvatarGroup>
                                                                ) : (
                                                                    <p>nenhum</p>
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="center">

                                                            <IconButton
                                                                size="small"
                                                                onClick={() => { history.push(`/kanban/board/${kanban.id}`) }}
                                                            >
                                                                <PreviewIcon />
                                                            </IconButton>

                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleEditKanban(kanban)}
                                                            >
                                                                <Edit />
                                                            </IconButton>

                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedKanban(kanban);
                                                                    setConfirmModalOpen(true);
                                                                }}
                                                            >
                                                                <DeleteOutline />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {loading && <TableRowSkeleton columns={4} />}
                                            </>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>
                        </Card>
                    </Container>
                </Page>
            </div>

        </>
    );
};

export default Kanban;
