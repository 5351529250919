import React, { useEffect, useReducer, useCallback, useState, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import { green, red, yellow } from "@mui/material/colors";
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	Card,
	TableContainer,
	TableHead,
	Paper,
	Container,
	Typography,
	CircularProgress,
} from "@mui/material";
import TokenIcon from '@mui/icons-material/Token';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';

import CheckCircle from '@mui/icons-material/CheckCircle';
import SignalCellularConnectedNoInternet2Bar from '@mui/icons-material/SignalCellularConnectedNoInternet2Bar';
import SignalCellularConnectedNoInternet0Bar from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellular4Bar from '@mui/icons-material/SignalCellular4Bar';
import CropFree from '@mui/icons-material/QrCode2';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";

import AvTimerIcon from '@mui/icons-material/AvTimer';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import useSettings from '../../hooks/useSettings';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Scrollbar from '../../components/Scrollbar';
import Page from '../../components/Page';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import ForumIcon from '@mui/icons-material/Forum';
import FacebookIcon from '@mui/icons-material/Facebook';
import ToastSuccess from "../../toast/success/toastSuccess";
import ToastError from "../../toast/error/toastError";

const useStyles = makeStyles()((theme) => {
	return {
		mainPaper: {
			flex: 1,
			padding: theme.spacing(1),
			overflowY: "scroll",
			...theme.scrollbarStyles,
		},
		customTableCell: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		tooltip: {
			backgroundColor: "#f5f5f9",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
			maxWidth: 450,
		},
		tooltipPopper: {
			textAlign: "center",
		},
		buttonProgress: {
			color: green[500],
		},
	}
});

const CustomToolTip = ({ title, content, children }) => {
	const { classes } = useStyles();

	return (
		<Tooltip
			arrow
			classes={{
				tooltip: classes.tooltip,
				popper: classes.tooltipPopper,
			}}
			title={
				<React.Fragment>
					<Typography gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography>{content}</Typography>}
				</React.Fragment>
			}
		>
			{children}
		</Tooltip>
	);
};

const Connections = () => {
	const { classes } = useStyles();
	const { themeStretch } = useSettings();
	const { user } = useContext(AuthContext);
	const { whatsApps, loading } = useContext(WhatsAppsContext);
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);

	const handleStartWhatsAppSession = async whatsAppId => {
		try {
			await api.post(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
			ToastError(err);
		}
	};

	const handleRequestNewQrCode = async whatsAppId => {
		try {
			await api.put(`/whatsappsession/${whatsAppId}`);
		} catch (err) {
			ToastError(err);
		}
	};

	const handleOpenWhatsAppModal = () => {
		setSelectedWhatsApp(null);
		setWhatsAppModalOpen(true);
	};

	const handleCloseWhatsAppModal = useCallback(() => {
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

	const handleOpenQrModal = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setQrModalOpen(true);
	};

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

	const handleEditWhatsApp = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setWhatsAppModalOpen(true);
	};


	const renderChannel = channel => {
		let channelComponent = ''

		let name = channel[0].toUpperCase() + channel.substring(1);

		switch (channel) {
			case 'whatsapp': channelComponent = <><div style={{ display: 'flex', justifyContent: 'center' }}><WhatsAppIcon style={{ color: '#0dc242' }} /><p style={{ marginLeft: 5 }}>{name}</p></div></>; break;
			case 'telegram': channelComponent = <><div style={{ display: 'flex', justifyContent: 'center' }}><TelegramIcon style={{ color: '#3390ec' }} /><p style={{ marginLeft: 5 }}>{name}</p></div></>; break;
			case 'instagram': channelComponent = <><div style={{ display: 'flex', justifyContent: 'center' }}><InstagramIcon style={{ color: '#fe0079' }} /><p style={{ marginLeft: 5 }}>{name}</p></div></>; break;
			case 'messenger': channelComponent = <><div style={{ display: 'flex', justifyContent: 'center' }}><FacebookIcon style={{ color: '#0573e7' }} /><p style={{ marginLeft: 5 }}>{name}</p></div></>; break;
			case 'webchat': channelComponent = <><div style={{ display: 'flex', justifyContent: 'center' }}><ForumIcon style={{ color: '#0573e7' }} /><p style={{ marginLeft: 5 }}>{name}</p></div></>; break;
		}


		return channelComponent;
	}


	const handleOpenConfirmationModal = (action, whatsAppId) => {
		if (action === "disconnect") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.disconnectTitle"),
				message: i18n.t("connections.confirmationModal.disconnectMessage"),
				whatsAppId: whatsAppId,
			});
		}

		if (action === "delete") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.deleteTitle"),
				message: i18n.t("connections.confirmationModal.deleteMessage"),
				whatsAppId: whatsAppId,
			});
		}
		setConfirmModalOpen(true);
	};

	const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disconnect") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
			} catch (err) {
				ToastError(err);
			}
		}

		if (confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
				ToastSuccess(i18n.t("connections.toasts.deleted"));
			} catch (err) {
				ToastError(err);
			}
		}

		setConfirmModalInfo(confirmationModalInitialState);
	};

	const renderActionButtons = whatsApp => {
		return (
			<>
				{whatsApp.status === "qrcode" && (
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleOpenQrModal(whatsApp)}
					>
						{i18n.t("connections.buttons.qrcode")}
					</Button>
				)}
				{whatsApp.status === "DISCONNECTED" && (
					<>
						<Button
							size="small"
							variant="contained"
							color="secondary"
							onClick={() => handleRequestNewQrCode(whatsApp.id)}
						>
							{i18n.t("connections.buttons.newQr")}
						</Button>
					</>
				)}
				{(whatsApp.status === "CONNECTED" ||
					whatsApp.status === "PAIRING" ||
					whatsApp.status === "TIMEOUT") && (
						<Button
							size="small"
							variant="outlined"
							color="secondary"
							onClick={() => {
								handleOpenConfirmationModal("disconnect", whatsApp.id);
							}}
						>
							{i18n.t("connections.buttons.disconnect")}
						</Button>
					)}
				{whatsApp.status === "OPENING" && (
					<Button
						size="small"
						variant="contained"
						color="primary"
					>
						Gerando &nbsp; <CircularProgress size={24} color="inherit" />
					</Button>
				)}
			</>
		);
	};

	const renderStatusToolTips = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{whatsApp.status === "DISCONNECTED" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					>
						<SignalCellularConnectedNoInternet0Bar color="secondary" />
					</CustomToolTip>
				)}
				{whatsApp.status === "OPENING" && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
				{whatsApp.status === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					>
						<CropFree />
					</CustomToolTip>
				)}
				{whatsApp.status === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellular4Bar style={{ color: green[500] }} />
					</CustomToolTip>
				)}
				{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					>
						<SignalCellularConnectedNoInternet2Bar color="secondary" />
					</CustomToolTip>
				)}
			</div>
		);
	};

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 400,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	function msToTime(milliseconds) {
		//Get hours from milliseconds
		var hours = milliseconds / (1000 * 60 * 60);
		var absoluteHours = Math.floor(hours);
		var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

		//Get remainder from hours and convert to minutes
		var minutes = (hours - absoluteHours) * 60;
		var absoluteMinutes = Math.floor(minutes);
		var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

		//Get remainder from minutes and convert to seconds
		var seconds = (minutes - absoluteMinutes) * 60;
		var absoluteSeconds = Math.floor(seconds);
		var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


		return h + ':' + m + ':' + s;
	}

	const copyToken = (divId) => {
		let token = document.getElementById(`${divId}`);
	  
		if (!token) {
		  console.error("Element not found");
		  return;
		}
	  
		// Cria um campo de entrada temporário
		const tempInput = document.createElement('input');
		tempInput.value = token.value;
		document.body.appendChild(tempInput);
		
		// Seleciona o texto no campo de entrada temporário
		tempInput.select();
		tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis
		
		// Executa o comando de cópia
		document.execCommand('copy');
		
		// Remove o campo de entrada temporário
		document.body.removeChild(tempInput);
	  
		ToastSuccess(`${i18n.t("connections.toasts.copied")}`);
	  };
	  

	return (
		<>
			<div style={{ marginTop: '65px' }}>
				<ConfirmationModal
					title={confirmModalInfo.title}
					open={confirmModalOpen}
					onClose={setConfirmModalOpen}
					onConfirm={handleSubmitConfirmationModal}
				>
					{confirmModalInfo.message}
				</ConfirmationModal>
				<QrcodeModal
					open={qrModalOpen}
					onClose={handleCloseQrModal}
					whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id} />
				<WhatsAppModal
					open={whatsAppModalOpen}
					onClose={handleCloseWhatsAppModal}
					whatsAppId={!qrModalOpen && selectedWhatsApp?.id} />
				<Page title={i18n.t("connections.title")}>
				<Container maxWidth={false}>
						<HeaderBreadcrumbs
							heading={i18n.t("connections.title")}
							action={<>
								<Can
									role={user.profile}
									perform="connections-page:addConnection"
									yes={() => (

										<Button
											variant="contained"
											color="primary"
											onClick={handleOpenWhatsAppModal}
										>
											{i18n.t("connections.buttons.add")}
										</Button>
									)} />


							</>} />
						<Card>
							<Scrollbar>
								<TableContainer sx={{ minWidth: 800 }} style={{ marginTop: '40px' }}>
									<Table >
										<TableHead>
											<TableRow>
												<TableCell align="center">
													{i18n.t("connections.table.channel")}
												</TableCell>
												<TableCell align="center">
													{i18n.t("connections.table.name")}
												</TableCell>
												<TableCell align="center">
													{i18n.t("connections.table.status")}
												</TableCell>
												<TableCell align="center">
													{i18n.t("connections.table.session")}
												</TableCell>
												<TableCell align="center">
													{i18n.t("connections.table.lastUpdate")}
												</TableCell>
												<TableCell align="center">
													{i18n.t("connections.table.autoClose")}
												</TableCell>
												<TableCell align="center">API Token</TableCell>
												<Can
													role={user.profile}
													perform="connections-page:editOrDeleteConnection"
													yes={() => (
														<TableCell align="center">
															{i18n.t("connections.table.actions")}
														</TableCell>
													)} />
											</TableRow>
										</TableHead>
										<TableBody>
											{loading ? (
												<TableRowSkeleton />
											) : (
												<>
													{whatsApps?.length > 0 &&
														whatsApps.map(whatsApp => (
															user.companyId == whatsApp.companyId && (
																<TableRow key={whatsApp.id}>
																	<TableCell align="center">{renderChannel(whatsApp.channel)}</TableCell>
																	<TableCell align="center">{whatsApp.name}</TableCell>
																	<TableCell align="center">
																		{renderStatusToolTips(whatsApp)}
																	</TableCell>
																	<TableCell align="center">
																		{renderActionButtons(whatsApp)}
																	</TableCell>
																	<TableCell align="center">
																		{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
																	</TableCell>
																	<TableCell align="center">
																		<div className={classes.customTableCell}>
																			{whatsApp.autoClose ? <>
																				<CheckCircle style={{ color: green[500] }} />
																				<HtmlTooltip
																					title={
																						<React.Fragment>
																							<List sx={{ width: '100%' }}>
																								<ListItem alignItems="flex-start">
																									<ListItemAvatar>
																										<Avatar>
																											<AvTimerIcon />
																										</Avatar>
																									</ListItemAvatar>
																									<ListItemText primary={i18n.t("connections.table.autoCloseTimeOut")}
																										secondary={
																											<React.Fragment>
																												{msToTime(whatsApp.autoCloseTime)}
																											</React.Fragment>
																										} />
																								</ListItem>
																								<ListItem>
																									<ListItemAvatar>
																										<Avatar>
																											<ForwardToInboxIcon />
																										</Avatar>
																									</ListItemAvatar>
																									<ListItemText primary={i18n.t("connections.table.autoCloseMessage")}
																										secondary={
																											<React.Fragment>
																												{whatsApp.autoCloseMessage}
																											</React.Fragment>
																										} />
																								</ListItem>
																							</List>
																						</React.Fragment>
																					}
																				>
																					<InfoIcon style={{ color: yellow[500] }} />
																				</HtmlTooltip>
																			</> : <>
																				<CancelIcon style={{ color: red[500] }} />
																			</>}
																		</div>
																	</TableCell>
																	<TableCell align="center">
																		<HtmlTooltip
																			title={
																				<React.Fragment>
																					<List sx={{ width: '100%' }}>
																						<ListItem alignItems="flex-start">
																							<ListItemText primary={'Token:'}
																								secondary={
																									<React.Fragment>
																										<div style={{ display: 'flex' }}>
																											<p style={{ maxWidth: 200 }}>{whatsApp.apiToken}</p>
																											<input type="text" hidden value={whatsApp.apiToken} id={`token_api_${whatsApp.id}`}></input>
																											<Button onClick={e => copyToken(`token_api_${whatsApp.id}`)}><ContentCopyIcon /></Button>
																										</div>
																									</React.Fragment>
																								} />
																						</ListItem>
																					</List>
																				</React.Fragment>
																			}
																		>
																			<TokenIcon />
																		</HtmlTooltip>
																	</TableCell>
																	<Can
																		role={user.profile}
																		perform="connections-page:editOrDeleteConnection"
																		yes={() => (
																			<TableCell align="center">
																				<IconButton
																					size="small"
																					onClick={() => handleEditWhatsApp(whatsApp)}
																				>
																					<Edit />
																				</IconButton>

																				<IconButton
																					size="small"
																					onClick={e => {
																						handleOpenConfirmationModal("delete", whatsApp.id);
																					}}
																				>
																					<DeleteOutline />
																				</IconButton>
																			</TableCell>
																		)} />
																</TableRow>
															)
														))}
												</>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Scrollbar>
						</Card>
					</Container>
				</Page >
			</div>

		</>
	);
};

export default Connections;