import React, { useEffect, useReducer, useState } from "react";


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Card,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material';
import CompanyModal from "../../../components/CompanyModal";
import CompanyUsersModal from "../../../components/CompanyUsersModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import TableRowSkeleton from "../../../components/TableRowSkeleton";
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";

import GroupIcon from '@mui/icons-material/Group';

import { makeStyles } from 'tss-react/mui';
import socket from "../../../hooks/useSocket";
import ToastError from "../../../toast/error/toastError";
import ToastSuccess from "../../../toast/success/toastSuccess";


const reducer = (state, action) => {

  if (action.type === "LOAD_COMPANIES") {
    const companies = action.payload;
    const newCompanys = [];

    companies.forEach((company) => {
      const companyIndex = state.findIndex((u) => u.id === company.id);
      if (companyIndex !== -1) {
        state[companyIndex] = company;
      } else {
        newCompanys.push(company);
      }
    });

    return [...state, ...newCompanys];
  }

  if (action.type === "UPDATE_COMPANIES") {
    const companies = action.payload;
    const companyIndex = state.findIndex((u) => u.id === companies.id);

    if (companyIndex !== -1) {
      state[companyIndex] = companies;
      return [...state];
    } else {
      return [companies, ...state];
    }
  }

  if (action.type === "DELETE_COMPANIES") {
    const companyId = action.payload;

    const companyIndex = state.findIndex((u) => u.id === companyId);
    if (companyIndex !== -1) {
      state.splice(companyIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const Company = () => {

  const { classes } = useStyles();

  const [company, setCompany] = useState({ nomeEmpresa: "", cnpj: "", maxWhatsapps: "", MaxUsers: "" });
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [companyUsersModalOpen, setCompanyUsersModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [deletingCompany, setDeletingCompany] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [companies, dispatch] = useReducer(reducer, []);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCompany = async () => {
        try {
          const { data } = await api.get("/company/", {
            params: { searchParam, pageNumber },
          });

          dispatch({ type: "LOAD_COMPANIES", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          ToastError(err);
        }
      };
      fetchCompany();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {


    socket.on("company", (data) => {

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_COMPANIES", payload: data.company });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_COMPANIES", payload: +data.companyId });
      }
    });

    return () => { };

  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;

  const filteredUsers = applySortFilter(companies, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && Boolean(filterName);

  //Abrir modal para cadastrar empresa
  const handleOpenCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyModalOpen(true);
  };

  //fechar modal empresa 
  const handleCloseCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyModalOpen(false);
  };

  const handleEditCompany = (id) => {
    setSelectedCompany(id)
    setCompanyModalOpen(true);
  };

  //Abrir modal para cadastrar empresa
  const handleOpenCompanyUsersModal = () => {
    setSelectedCompany(null);
    setCompanyUsersModalOpen(true);
  };

  //fechar modal empresa 
  const handleCloseCompanyUsersModal = () => {
    setSelectedCompany(null);
    setCompanyUsersModalOpen(false);
  };

  const handleEditCompanyUsers = (id) => {
    setSelectedCompany(id)
    setCompanyUsersModalOpen(true);
  };

  const handleDeleteCompany = async (companyId) => {

    try {
      if (!companyId) return;
      await api.delete(`/company/${companyId}`);
      ToastSuccess("Empresa Excluida com sucesso");
    } catch (err) {
      ToastError(err);
    }
    setDeletingCompany(null);
    setSearchParam("");
    setPageNumber(1);
  };
  return (
    <>
      <div>
        <ConfirmationModal
          title={
            deletingCompany &&
            `${i18n.t("companies.confirmations.delete")} ${deletingCompany.name}?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteCompany(deletingCompany.id)}
        >
          {i18n.t("companies.deleteInfo")}
        </ConfirmationModal>
        <CompanyUsersModal
          open={companyUsersModalOpen}
          onClose={handleCloseCompanyUsersModal}
          aria-labelledby="form-dialog-title"
          companyId={selectedCompany}
        />
        <CompanyModal
          open={companyModalOpen}
          onClose={handleCloseCompanyModal}
          aria-labelledby="form-dialog-title"
          companyId={selectedCompany}
        />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }} style={{ marginTop: '20px' }}>
              <div style={{ padding: '0px 30px 0px 30px' }}>
                <HeaderBreadcrumbs
                  heading={i18n.t("companies.pages.companies")}
                  action={
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenCompanyModal}
                      >
                        {i18n.t("companies.buttons.add")}
                      </Button>
                    </>
                  }
                />
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {i18n.t("companies.table.name")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("companies.table.cnpj")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("companies.table.connections")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("companies.table.users")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("companies.table.status")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("companies.table.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, cnpj, maxWhatsapps, maxUsers, latestUpdatedAt, isActive, isTrial } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="center">{name}</TableCell>
                        <TableCell align="center">{cnpj}</TableCell>
                        <TableCell align="center">{maxWhatsapps}</TableCell>
                        <TableCell align="center">{maxUsers}</TableCell>
                        <TableCell align="center">
                          {isTrial ? (
                            <Tooltip title="Teste" arrow>
                              <Chip label="Teste" color="primary" sx={{ borderRadius: '5px' }} size="small" />
                            </Tooltip>
                          ) : isActive ? (
                            latestUpdatedAt ? (
                              // Verifica se já passaram mais de 30 dias desde a última conexão
                              Math.floor((new Date() - new Date(latestUpdatedAt)) / (1000 * 60 * 60 * 24)) > 30 ? (
                                <Tooltip title={`Ultima conexão à ${Math.floor((new Date() - new Date(latestUpdatedAt)) / (1000 * 60 * 60 * 24))} dias`} arrow>
                                  <Chip label="Inativa" color="secondary" sx={{ borderRadius: '5px' }} size="small" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Empresa ativa" arrow>
                                  <Chip label="Ativa" color="primary" sx={{ borderRadius: '5px' }} size="small" />
                                </Tooltip>
                              )
                            ) : (
                              <Tooltip title="Empresa ativa" arrow>
                                <Chip label="Ativa" color="primary" sx={{ borderRadius: '5px' }} size="small" />
                              </Tooltip>
                            )
                          ) : (
                            <>
                              <Tooltip title="Empresa bloqueada pelo sistema" arrow>
                                <Chip label="Bloqueada" color="error" sx={{ borderRadius: '5px' }} size="small" />
                              </Tooltip>
                            </>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Editar usuarios da empresa">
                            <IconButton
                              size="small"
                              onClick={() => handleEditCompanyUsers(id)}
                            >
                              <GroupIcon />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            size="small"
                            onClick={() => handleEditCompany(id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setConfirmModalOpen(true);
                              setDeletingCompany(row);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {loading && <TableRowSkeleton columns={5} />}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination style={{ marginTop: 15 }}
            labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
            labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
            rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
            component="div"
            count={companies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>

    </>

  )
}

export default Company;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

