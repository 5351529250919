function formatBrazilianNumber(number) {
    if (number) {
        const numStr = number.toString();

        const localNumber = numStr.substring(2);
        if (localNumber.length === 10) {
            return `+55 (${localNumber.substring(0, 2)}) ${localNumber.substring(2, 6)}-${localNumber.substring(6)}`;
        } else if (localNumber.length === 11) {
            return `+55 (${localNumber.substring(0, 2)}) ${localNumber.substring(2, 7)}-${localNumber.substring(7)}`;
        } else {
            return 'Grupo do Whatsapp'
        }
    } else {
        return 'Grupo do Whatsapp'
    }
};


export default formatBrazilianNumber;