// UserSession.js
const UserSession = {
    generateUUID: function() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    getUUID: function() {
        const deviceUUID = localStorage.getItem("deviceUUID");
        if (deviceUUID) {
            return deviceUUID;
        } else {
            const generatedUUID = this.generateUUID();
            localStorage.setItem("deviceUUID", generatedUUID);
            return generatedUUID;
        }
    }
};

export default UserSession;
